import React, { FC, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import NcImage from '../shared/NcImage/NcImage';
import Prices from './Prices';
import { FaStar, FaStarHalf, FaToggleOff, FaToggleOn } from 'react-icons/fa';
import { BiMessageSquareEdit, BiTrash } from 'react-icons/bi';
import { useAuthHeader, useAuthUser } from 'react-auth-kit';
import axios from 'axios';
import { MdContentCopy } from 'react-icons/md';
import { backendURL } from '../constants/environmental';
// import LikeButton from './LikeButton';

export interface FusionCardProps {
  className?: string;
  isLiked?: boolean;
  title?: string;
  subtitle?: string;
  numberSold?: number;
  saleStarts?: Date;
  saleEnds?: Date;
  description?: string;
  price?: string;
  buttonText?: string;
  onClick?: () => void;
  key: string;
  saleTimer?: string;
  rating?: number;
  data?: any;
  removeFromAuthorPage: any;
  duplicateTool?: any;
  togglePublish?: any;
}

const FusionCard: FC<FusionCardProps> = ({
  className = '',
  rating,
  data,
  removeFromAuthorPage,
  duplicateTool,
  togglePublish,
  // isLiked,
  // price,
  // saleTimer,
  // saleEnds,
  // saleStarts,
  // numberSold = 0,
}) => {
  const authHeader = useAuthHeader()();

  const [borderColor, setBorderColor] = useState('border-neutral-300');
  const user = useAuthUser();
  const [showCard, setShowCard] = useState(true); // state to control card visibility

  const [shadowColor, setShadowColor] = useState('shadow-blue');

  useEffect(() => {
    if (user()?.id !== data.authorId?._id) {
      if (!data.isPublished) {
        // console.log('not published ');
        setBorderColor('border-red-200');
        setShadowColor('shadow-red');
        // setShowCard(false);
      }
    } else {
      if (!data.isPublished) {
        // console.log('data not published');
        setBorderColor('border-red-200');
        setShadowColor('shadow-red');
      }
    }
    if (data.isPublished) {
      // console.log('data is published');
      setBorderColor('border-blue-400');
      setShadowColor('shadow-blue');
    }
    //    console.log('data ', data);
  }, [user(), data]);

  // console.log(skill);
  // console.log(saleStarts);

  // const dateStr = saleStarts?.toString();
  // const formattedDate = format(new Date(dateStr!), 'MMMM d, yyyy h:mm a');
  // console.log(formattedDate); // May 11, 2023 6:48 PM

  const fullStars = Math.floor(rating!);
  const halfStar = rating! - fullStars >= 0.5 ? 1 : 0;
  const emptyStars = Math.ceil(5 - rating!);

  // const navigate = useNavigate();

  const handleDelete = async (blockId: string) => {
    // take blockId as a parameter
    const confirmation = window.confirm(
      'Are you sure you want to delete this tool?'
    );
    if (confirmation) {
      try {
        const deleteResult = await axios.delete(
          `${backendURL}/blocks/${blockId}`, // Include blockId in the URL
          {
            headers: {
              Authorization: authHeader,
            },
          }
        );
        removeFromAuthorPage();
        console.log('Deleted block');
      } catch (error) {
        console.error('Error Deleting Block', error); // Log the error
      }
    }
  };

  return showCard ? (
    <div
      className={`nc-CardNFT relative flex flex-col group !border-0 [ nc-box-has-hover nc-dark-box-bg-has-hover ] ${className} ${shadowColor} rounded-3xl`}
      data-nc-id='CardNFT'
    >
      <div
        className={`relative flex-shrink-0 border-2 ${borderColor} rounded-t-3xl `}
      >
        <div>
          <Link to={`/${data.isPublished ? 'fusion' : 'fuser'}/${data._id}`}>
            <NcImage
              src={`${data.coverPhoto}`}
              containerClassName='flex aspect-w-11 aspect-h-12 w-full h-0 rounded-t-3xl overflow-hidden z-0 object-cover'
              className='object-cover w-full h-full group-hover:scale-[1.03] transition-transform duration-300 ease-in-out will-change-transform object-cover'
            />
          </Link>
        </div>

        {/* We should only see this button if we are the owner of the card or if we purchased permissions to use it */}
        {/* {user()?.username === data.authorId?.username && (
          <span className=''>
            <BiMessageSquareEdit
              onClick={() => {
                navigate(`/fuser/${data._id}`);
              }}
              className='absolute top-3 left-3 !w-7 !h-7 text-white cursor-pointer hover:scale-110 hover:text-blue-400'
            />
          </span>
        )}
        
        <LikeButton
          currentObjectsId={'1'}
          currentUserId={'2'}
          liked={isLiked}
          className='absolute top-3 right-3 z-10 !h-9'
          contentType='user'
        /> */}
        <div className='absolute top-3 inset-x-3 flex'></div>
      </div>

      <div className='p-4 py-5 space-y-3 grow flex flex-col justify-between'>
        {fullStars > 0 && (
          <div className='flex justify-between w-24'>
            {Array.from({ length: fullStars }).map((_, index) => (
              <FaStar
                key={index}
                className='text-yellow-400 fill-current w-3 h-3 text-xs hover:text-gray-400'
              />
            ))}
            {halfStar > 0 && (
              <FaStarHalf className='text-yellow-400 fill-current' />
            )}
            {Array.from({ length: emptyStars }).map((_, index) => (
              <FaStar
                key={index + fullStars + halfStar}
                className='text-gray-300 fill-none w-3 h-3 text-xs hover:text-yellow-800'
              />
            ))}
            {/* <span className="text-neutral-700 dark:text-neutral-400  text-xs">
            {Number(numberSold) + 1} sold
          </span> */}
          </div>
        )}

        {
          <h2 className={`text-lg font-medium align-top`}>
            {data?.name || data?.title}
          </h2>
        }

        {/* <div className='w-2d4 w-full border-b border-neutral-100 dark:border-neutral-700 rounded-3xl'></div> */}

        <div className='flex justify-between items-end '>
          <Prices
            labelTextClassName='bg-white dark:bg-neutral-900 dark:group-hover:bg-neutral-800 group-hover:bg-neutral-50'
            price={
              data?.price?.toString() ? (data?.price * 10).toString() : 'FREE'
            }
          />

          {(user()?.username === data.authorId?.username ||
            user()?.loggedin === 'false') && (
            <div className='flex gap-2'>
              {!data.isPublished ? (
                <FaToggleOff
                  onClick={() => {
                    togglePublish(data._id);
                  }}
                  className='text-black cursor-pointer hover:scale-110 hover:text-blue-400'
                />
              ) : (
                <FaToggleOn
                  onClick={() => {
                    togglePublish(data._id);
                  }}
                  className='text-black cursor-pointer hover:scale-110 hover:text-blue-400'
                />
              )}

              {duplicateTool && (
                <button onClick={duplicateTool}>
                  <MdContentCopy className='text-black cursor-pointer hover:scale-110 hover:text-blue-400' />
                </button>
              )}

              <BiTrash
                onClick={() => {
                  handleDelete(data._id);
                }}
                className='text-black cursor-pointer hover:scale-110 hover:text-blue-400'
              />
            </div>
          )}

          {/* <div className="flex items-center text-sm text-neutral-500 dark:text-neutral-400">
            <ClockIcon className="w-4 h-4" />
            <span className="ml-1 mt-0.5">

              <img className="w-5 h-5 rounded-full" alt={data?.title} src="https://via.placeholder.com/150" />
            </span>
          </div> */}
        </div>
      </div>

      {/* <Link to={`/skill_detail/${}`} className="absolute inset-0"></Link> */}
    </div>
  ) : null;
};

export default FusionCard;
