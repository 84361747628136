import React, {
  FC,
  useState,
  useEffect,
  useRef,
  ReactNode,
  useLayoutEffect,
} from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import HeaderFilterSearchPage from '../components/HeaderFilterSearchPage';
import Input from '../shared/Input/Input';
import ButtonCircle from '../shared/Button/ButtonCircle';
import { SearchResult } from '../../TypeScript/Interfaces';
import { useNavigate, useLocation } from 'react-router-dom';
import '../styles/scrollBars.css';
import '../styles/Glow.css';
import ButtonPrimary from '../shared/Button/ButtonPrimary';
import { ChevronDownIcon, UserAddIcon } from '@heroicons/react/outline';
import PageSearchContext from '../context/PageSearchContext';
import FusionCard from '../components/FusionCard3';
import { Disclosure, Transition } from '@headlessui/react';
import { useAuthHeader, useAuthUser } from 'react-auth-kit';
import { FaArrowRight } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import PageSignUp from './PageSignUp/PageSignUp';
import VerifyEmail from './PageLogin/VerifyEmail';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import { backendURL } from '../constants/environmental';
import { buildCategoryTree } from '../utils/tree';
// import SurveyDialog from '../components/SurveyDialog';

export interface PageSearchProps {
  className?: string;
  mode?: 'full' | 'preview';
}
interface FilterContextType {
  filters: any;
  setFilters: React.Dispatch<React.SetStateAction<any>>;
}

export const FilterContext = React.createContext<FilterContextType | undefined>(
  undefined
);

let searchAbortController: any;

const PageSearch: FC<PageSearchProps> = ({ className = '', mode = 'full' }) => {
  const authHeader = useAuthHeader()();

  const [filters, setFilters] = useState({});
  const [lpCopy, setLPCopy] = useState<ReactNode | null>(null);
  const handleFilterChange = (newFilters: any) => {
    setFilters(newFilters);
  };

  useEffect(() => {
    //console.log(filters);
  }, [filters]);

  // const [fusions, setFusions] = useState<any>([]);
  const [categories, setCategories] = useState([]);
  const [topMessage, setTopMessage] = useState(<></>);
  // const [key, setKey] = useState(0);

  useEffect(() => {
    getData(); // Call your data fetching function on page load
  }, []);

  const getData = async () => {
    try {
      /*

    console.log("getting blocks",backendURL);
      const blocksResponse = await axios.get(
        `${backendURL}/blocks/getPublishedBlocks`,
      );
      setFusions(blocksResponse.data);
      console.log("blocks data",blocksResponse.data);

      */
      const categoriesResponse = await axios.get(
        `${backendURL}/categories/all`
      );
      // console.log(categoriesResponse.data);
      setCategories(categoriesResponse.data);
    } catch (error) {
      console.error('Error fetching data', error);
    }
  };

  const categoryTree = buildCategoryTree(categories);

  const [searchTerm, setSearchTerm] = useState<string>(
    (window.location.search.includes('q=') &&
      new URLSearchParams(window.location.search).get('q')) ||
      ''
  );

  // const queryParameters = decodeURIComponent(window.location.search.slice(1)); //remove '?'

  // const queryParameterKeyValues = queryParameters
  //   ? Object.fromEntries(
  //       queryParameters.split('&').map(keyValue => keyValue.split('='))
  //     )
  //   : {};

  // const keyValuesToQueryParameters = (keyValues: any) =>
  //   Object.entries(keyValues)
  //     .map(([key, value]) => `${key}=${value}`)
  //     .join('&');

  // const putQueryParameters = (key: string, value: string) =>
  //   encodeURIComponent(
  //     keyValuesToQueryParameters({
  //       ...queryParameterKeyValues,
  //       [key]: value,
  //     })
  //   );

  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const debounceTimeout = useRef<NodeJS.Timeout | number | null>(null);
  const navigate = useNavigate();
  // const [category, setCategory] = useState<string>('All SkillFusion');

  const [searchInProgress, setSearchInProgress] = useState(true);

  const [blockSortingMethod, setBlockSortingMethod] = useState<string>(
    'recent-api-usage-descending'
  );

  const [freePaid, setFreePaid] = useState<string[]>([]);

  const [searchMetadata, setSearchMetadata] = useState();
  const [saveSearchMetadataTimeoutId, setSaveSearchMetadataTimeoutId] =
    useState<any>();

  const saveSearchMetadata = (metadata: any) => {
    // console.log('Saving search metadata:', metadata);
    // axios.post(`${backendURL}/site/search/metadata`, metadata, {
    //   headers: {
    //     Authorization: authHeader,
    //   },
    // });
  };

  // sign up stuff
  const [showVerification, setShowVerification] = useState(false);
  const [verificationDetails, setVerificationDetails] = useState({
    tempID: '',
    verifyEmailMessage: '',
  });
  const [showModal, setShowModal] = useState(false);
  const [showSignup, setShowSignup] = useState(false);

  const closeModal = () => {
    setShowModal(false);
    setShowSignup(false);
    //setShowPayPage(false);
    //setShowVerification(false);
  };
  const signUpModal = () => {
    setShowModal(true);
    setShowSignup(true);
  };
  const handleVerification = (email: string, tempID: string) => {
    setVerificationDetails({
      tempID,
      verifyEmailMessage: `A 6-digit code has been sent to your email ${email}, please enter the code here to verify your email.`,
    });
    setShowVerification(true);
    setShowSignup(false);
    console.log();
  };

  const HandleEmailVerification = () => {
    setShowVerification(false);
    // todo - check if paid tool, add more modals if it is
    setShowModal(false);
    //setButtonText('Purchase Tool');
    //const user = useAuthUser();
    userId = user()?.id ?? '';
    console.log('userId = ' + userId);
    // above doesn't work so refreshing the page to get it to load the token
    //forceUpdate();
    console.log('after forceUpdate call');
    setTopMessage(
      <p>
        Thank you, registration was successful! Click on a tool for more info:
      </p>
    );
  };

  // const forceUpdate = () => {
  //   console.log('forceUpdate called');
  //   setKey(prevKey => prevKey + 1);
  //   window.location.reload();
  // };
  // console.log(searchMetadata);

  // useEffect(() => {
  //   if (!searchMetadata) return;
  //   const timeoutId = setTimeout(saveSearchMetadata, 3000);
  //   return () => clearTimeout(timeoutId);
  // }, [searchMetadata]);

  const [emptyToolSearches, setEmptyToolSearches] = useState<string[]>([]);

  const searchEntities = async (
    searchTerm: string,
    filters: any
  ): Promise<SearchResult[] | string> => {
    try {
      // console.log('searching for', searchTerm);
      if (searchAbortController) searchAbortController.abort();
      searchAbortController = new AbortController();
      const signal = searchAbortController.signal;

      // console.log('filters:', filters);
      setSearchInProgress(true);

      const searchForCategory = axios.get(
        `${backendURL}/site/search/categories`,
        { params: { searchTerm: searchTerm, ...filters }, signal }
      );
      const searchForBlock = axios.get(`${backendURL}/site/search/blocks`, {
        params: {
          searchTerm: searchTerm,
          ...filters,
          sortingMethod: blockSortingMethod,
          freePaid,
          category:
            selectedCategoryId === 'all' ? undefined : selectedCategoryId,
        },
        signal,
      });

      const [categoryResponse, blockResponse] = await Promise.all([
        searchForCategory,
        searchForBlock,
      ]);

      // console.log('blockResponse.data', blockResponse.data.blocks);
      const categories = categoryResponse.data.results.map((category: any) => ({
        ...category,
        username: category.catName || 'default',
        _type: 'category',
      }));

      const blocks = blockResponse.data.blocks.map((block: any) => ({
        ...block,
        title: block.name,
        price: block.price,
        downloads: block.numberOfTransactions,
        views:
          block.viewCount !== null && block.viewCount !== undefined
            ? block.viewCount
            : 0 || '0',
        _type: 'block',
      }));

      if (blocks.length === 0) {
        const { searchQuery } = blockResponse.data.metadata;
        // save the empty tool search if it isnt a previous empty tool search (possibly with extra text added on)
        if (
          emptyToolSearches.every(search => !searchQuery.startsWith(search))
        ) {
          setEmptyToolSearches(emptyToolSearches => [
            ...emptyToolSearches,
            searchQuery,
          ]);
          axios
            .put(
              `${backendURL}/site/search/empty`,
              { searchQuery },
              {
                headers: {
                  Authorization: authHeader,
                },
              }
            )
            .then(() => console.log('Empty tool search saved'))
            .catch(() => console.log('Error saving empty tool search'));
        }
      }

      setSearchMetadata(blockResponse.data.metadata);

      if (saveSearchMetadataTimeoutId) {
        clearTimeout(saveSearchMetadataTimeoutId);
      }

      if (
        blockResponse.data.metadata.searchQuery ||
        blockResponse.data.metadata.category
      ) {
        setSaveSearchMetadataTimeoutId(
          setTimeout(
            () => saveSearchMetadata(blockResponse.data.metadata),
            3000
          )
        );
      }

      // console.log('sorted blocks:', sortedBlocks);
      // console.log("search results \n" , "\n category: " , categories, "\n blocks : " , blocks);
      setSearchInProgress(false);

      return [...categories, ...blocks];
    } catch (err) {
      if (err.code === 'ERR_CANCELED') {
        // console.log('search canceled');
        return 'canceled';
      }
      setSearchInProgress(false);

      console.log('Error searching: ', err);
      return [];
    }
  };

  const [selectedCategoryId, setSelectedCategoryId] = useState<any>(
    new URLSearchParams(window.location.search).get('c') || 'all'
  );

  const [showCategoriesMenu, setShowCategoriesMenu] = useState(true);

  const performSearch = async () => {
    const searchResults = await searchEntities(searchTerm, filters);
    // console.log('filters: ' + filters);
    //const c_check = new URLSearchParams(window.location.search).get('c');
    //console.log("c = "+ c_check  + " search=" + searchResults[0]);

    // searchResults return a string if canceled
    if (typeof searchResults !== 'string') {
      setSearchResults(orderPaid(searchResults));
    }
    debounceTimeout.current = null;
  };

  useEffect(() => {
    const effect = async () => {
      // console.log('in search triggering effect');

      setShowCategoriesMenu(searchTerm === '');

      if (searchInProgress || debounceTimeout?.current) {
        if (debounceTimeout.current)
          clearTimeout(debounceTimeout.current as any);

        debounceTimeout.current = setTimeout(performSearch, 500);
      } else performSearch();
    };
    effect();
  }, [searchTerm, filters, blockSortingMethod, freePaid, selectedCategoryId]);

  function orderPaid(results: SearchResult[]): SearchResult[] {
    // setting every 4th results to a paid tool so its the side bar on computers
    const paidTools = results.filter(
      result => typeof result.price === 'number' && result.price !== 0.0
    );
    const freeTools = results.filter(
      result => !(typeof result.price === 'number' && result.price !== 0.0)
    );

    //console.log('reorderedSearchResults', paidTools.length, freeTools.length);
    const reorderedSearchResults: SearchResult[] = [];

    while (paidTools.length || freeTools.length) {
      for (let i = 0; i < 3; i++) {
        if (freeTools.length) {
          const freeTool = freeTools.shift();
          if (freeTool) {
            reorderedSearchResults.push(freeTool);
          }
        }
      }
      if (paidTools.length) {
        const paidTool = paidTools.shift();
        if (paidTool) {
          reorderedSearchResults.push(paidTool);
        }
      } else if (freeTools.length) {
        // If there are no more paid tools, use a free tool as a fallback
        const freeTool = freeTools.shift();
        if (freeTool) {
          reorderedSearchResults.push(freeTool);
        }
      }
    }

    //console.log('reorderedSearchResults', reorderedSearchResults);

    return reorderedSearchResults;
  }

  const handleSearchInputChange = ({ target: { value } }: any) => {
    setSearchTerm(value);
    setSelectedCategoryId('all');
    // const params = new URLSearchParams(window.location.search);
    // if (params.get('c')) navigate('/page-search');
  };

  // const handleSearchInputChange = useCallback(
  //   (e: React.ChangeEvent<HTMLInputElement>) => {
  //     const inputValue = e.target.value;
  //     setSearchInputValue(inputValue);
  //     setSearchTerm(inputValue);

  //     if (debounceTimeout.current) {
  //       clearTimeout(debounceTimeout.current as any);
  //     }

  //     console.log('search input changed');
  //     debounceTimeout.current = setTimeout(async () => {
  //       const searchResults = await searchEntities(inputValue, filters); // include filters here

  //       // if (typeof searchResults !== 'string')
  //       //   setSearchResults(orderPaid(searchResults));
  //       // setSearchResults(results);
  //     }, 500);
  //   },
  //   [filters, searchInputValue] // include filters in the dependencies array
  // );

  const handleLinkClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();

    const newPath = event.currentTarget.getAttribute('href');
    if (newPath) {
      navigate(newPath);
    }
  };

  // fade effects
  // <div className={`nc-PageSearch  ${className} bg-gradient-to-r from-purple-400 to-blue-200`} data-nc-id="PageSearch">

  const location = useLocation();

  useEffect(() => {
    if (window.location.search.includes('page=1')) {
      setLPCopy(
        <div className='dark:color-white-900 w-9/12 lg:w-1/2 xl:w-5/12 text-xl'>
          <p className='text-5xl py-4'>
            Speed up Your Success with these GPT-Powered AI Tools{' '}
          </p>

          <p className='text-3xl py-4'>
            Do 10x more than you can with Chatbots
          </p>

          <p>
            ✔️ Tools created and vetted by real humans <br />
            ✔️ multiple prompts combined <br />
            ✔️ web access <br />
            ✔️ image generation <br />
            ✔️ embedded knowledge <br />
            ✔️ Zapier connection <br />
            ✔️ Google search <br />
            ✔️ Longer output
          </p>

          <p className='pt-4'>
            Write entire books from a single command, generate entire business
            pitch decks, generate 10 logo ideas at once, and more...{' '}
          </p>

          <p className='py-4 text-red-500'>
            Register for instant access to 26 Free AI Tools
          </p>

          <p>You can also make, publish, and sell your own tools.</p>
          <br />

          <ButtonPrimary href='/signup'>
            <span className=''>Register</span>
            <UserAddIcon className='w-5 h-5 ml-2.5' />
          </ButtonPrimary>
          <br />
          <br />
          <br />
          <br />
        </div>
      );
    }

    if (window.location.search.includes('q=')) {
      const params = new URLSearchParams(window.location.search);
      const qValue = params.get('q');
      //if (qValue) searchEntities(qValue, filters).then(orderPaid).then(setSearchResults);
      if (typeof qValue === 'string') setSearchTerm(qValue);
      // console.log('set search entities', qValue);
    }
  }, [window.location.search]);

  const dateOfNextPayment = location.state?.dateOfNextPaymentString
    ? new Date(location.state?.dateOfNextPaymentString)
    : undefined;

  const [hoveredCategoryId, setHoveredCategoryId] = useState<any>('');

  // useEffect(() => {
  //   if (mode === 'preview') return;
  //   const params = new URLSearchParams(window.location.search);
  //   params.set('c', selectedCategoryId);
  //   navigate(`/page-search?c=${selectedCategoryId}`);
  // }, [selectedCategoryId]);

  // const buildCategoryTreeElement = (categoryTree: any[]) => {
  //   const build = (tree: any, topLevel = true) => (
  //     <ul className={`my-2 ${topLevel ? 'list-none' : 'pl-6 list["-"]'}`}>
  //       {tree.map(({ _id, catName, children }: any) => (
  //         <li
  //           key={_id}
  //           className={`${
  //             topLevel ? 'text-md font-bold' : 'text-sm font-normal'
  //           } my-2 cursor-pointer p-2 ${
  //             selectedCategoryId === _id
  //               ? 'bg-gradient-to-b from-blue-200 to-purple-300' // rounded-xl
  //               : hoveredCategoryId === _id
  //               ? 'bg-gradient-to-b from-blue-100 to-purple-200' // rounded-xl
  //               : ''
  //           }`}
  //           onClick={e => {
  //             setSelectedCategoryId(_id);
  //             setSearchTerm('');
  //             navigate(`/page-search?c=${selectedCategoryId}`);
  //             e.stopPropagation();
  //           }}
  //           onMouseOver={e => {
  //             setHoveredCategoryId(_id);
  //             e.stopPropagation();
  //           }}
  //           onMouseOut={() => topLevel && setHoveredCategoryId(undefined)}
  //         >
  //           {catName} {children?.length > 0 && build(children, false)}
  //         </li>
  //       ))}
  //     </ul>
  //   );
  //   return build(categoryTree);
  // };

  const ancestors = (category: any): any[] =>
    category
      ? [
          ...(category?.parentCategory
            ? ancestors(
                categories.find(
                  ({ _id }: any) => _id === category.parentCategory
                )
              )
            : []),
          category,
        ]
      : [];

  const selectedCategory = categories.find(
    ({ _id }: any) => _id === selectedCategoryId
  );

  const selectedCategoryAncestors = ancestors(selectedCategory);

  const selectedCategoryAncestorIds = selectedCategoryAncestors.map(
    ({ _id }: any) => _id
  );

  // console.log(selectedCategoryAncestors.map(({ catName }) => catName));

  const [categoriesSidebarVisible, setCategoriesSidebarVisible] =
    useState(false);

  const BreadcrumbItem = ({ key, onClick, text }: any) => (
    <li
      key={key}
      className='text-sm font-normal p-2 underline'
    >
      <span
        className='cursor-pointer'
        onClick={onClick}
      >
        {text}
      </span>
    </li>
  );

  const CategoryBreadcrumb = (
    <Transition
      show={!categoriesSidebarVisible}
      appear
      as='ul'
      className='my-2 list-none flex gap-1 items-center flex-wrap'
      enter='transition ease-out duration-300'
      enterFrom='transform opacity-0 -translate-x-full'
      enterTo='transform opacity-100 translate-x-0'
      leave='transition ease-in duration-300'
      leaveFrom='transform opacity-100 translate-x-0'
      leaveTo='transform opacity-0 -translate-x-full'
      // onBlur={() => setCategoriesSidebarVisible(false)}
    >
      {BreadcrumbItem({
        key: 'all',
        onClick: (e: any) => {
          setSelectedCategoryId('all');
          navigate(`/page-search?c=all`);
          setSearchTerm('');
        },
        text: 'All categories',
      })}
      {selectedCategoryAncestors.map(({ _id, catName }: any) => (
        <React.Fragment key={_id}>
          <span>&gt;</span>
          {BreadcrumbItem({
            onClick: (e: any) => {
              setSelectedCategoryId(_id);
              navigate(`/page-search?c=${_id}`);
              setSearchTerm('');
            },
            text: catName,
          })}
        </React.Fragment>
      ))}
    </Transition>
  );

  const buildCategoryMenu = (categoryTree: any) => {
    const build = (tree: any, topLevel = true) => (
      <ul>
        {tree.map(({ _id, catName, children }: any) => (
          <Disclosure
            as='li'
            key={_id}
            className={`${
              topLevel ? 'text-sm font-medium' : 'text-xs font-normal pl-4 '
            } cursor-pointer p-2 ${
              selectedCategoryId === _id
                ? 'bg-gradient-to-b from-blue-200 to-purple-300' // rounded-xl
                : hoveredCategoryId === _id
                  ? 'bg-gradient-to-b from-blue-100 to-purple-200' // rounded-xl
                  : ''
            }`}
            onClick={(e: any) => {
              setSelectedCategoryId(_id);
              navigate(`/page-search?c=${_id}`);
              setSearchTerm('');
              e.stopPropagation();
            }}
            onMouseOver={(e: any) => {
              setHoveredCategoryId(_id);
              e.stopPropagation();
            }}
            onMouseOut={() => topLevel && setHoveredCategoryId(undefined)}
            defaultOpen={selectedCategoryAncestorIds.includes(_id)}
          >
            <Disclosure.Button
              as='div'
              className='flex space-between items-center'
            >
              {catName}
              {children?.length > 0 && (
                <ChevronDownIcon
                  className='ml-2 h-4 w-4 text-neutral-500'
                  aria-hidden='true'
                />
              )}
            </Disclosure.Button>
            {children?.length > 0 && (
              <Disclosure.Panel>{build(children, false)}</Disclosure.Panel>
            )}
          </Disclosure>
        ))}
      </ul>
    );
    return build(categoryTree);
  };

  const CategoriesMenu = (
    <>
      <h2
        className={`font-bold text-md cursor-pointer p-2 ${
          selectedCategoryId === 'all'
            ? 'bg-gradient-to-b from-blue-200 to-purple-300'
            : hoveredCategoryId === 'all'
              ? 'bg-gradient-to-b from-blue-100 to-purple-200'
              : ''
        }`}
        onClick={(e: any) => {
          setSelectedCategoryId('all');
          navigate(`/page-search?c=all`);
          setSearchTerm('');
        }}
        onMouseOver={(e: any) => {
          setHoveredCategoryId('all');
        }}
        onMouseOut={() => setHoveredCategoryId(undefined)}
      >
        All categories
      </h2>
      {buildCategoryMenu(categoryTree)}
    </>
  );

  const toggleOpenMenu = () =>
    setCategoriesSidebarVisible(previous => !previous);
  // const handleOpenMenu = () => setCategoriesSidebarVisible(true);
  // const handleCloseMenu = () => setCategoriesSidebarVisible(false);

  const CategoriesSidebar = (
    // <Transition
    //   appear
    //   show={categoriesSidebarVisible}
    //   as={React.Fragment}
    // >
    //   <Dialog
    //     as='div'
    //     className='fixed inset-0 z-50 overflow-y-auto'
    //     onClose={handleCloseMenu}
    //   >
    //     <div className='fixed bottom-0 md:w-auto z-max outline-none focus:outline-none'>
    //       <React.Fragment>
    //         <Transition.Child
    //           as={React.Fragment}
    //           enter='transition duration-100 transform'
    //           enterFrom='opacity-0 -translate-x-14'
    //           enterTo='opacity-100 translate-x-0'
    //           leave='transition duration-150 transform'
    //           leaveFrom='opacity-100 translate-x-0'
    //           leaveTo='opacity-0 -translate-x-14'
    //         >
    //           <div className='z-10 relative bg-white'>
    //             <div className='flex justify-between items-center border-b-1 border-black'>
    //               <h2 className='p-2 font-bold'>Categories</h2>
    //               <ButtonClose
    //                 onClick={() => setCategoriesSidebarVisible(false)}
    //               />
    //             </div>
    //             <div>{CategoriesMenu}</div>
    //           </div>
    //         </Transition.Child>

    //         <Transition.Child
    //           as={React.Fragment}
    //           enter=' duration-300'
    //           enterFrom='opacity-0'
    //           enterTo='opacity-100'
    //           leave=' duration-200'
    //           leaveFrom='opacity-100'
    //           leaveTo='opacity-0'
    //         >
    //           <Dialog.Overlay className='fixed inset-0 bg-neutral-900 bg-opacity-50' />
    //         </Transition.Child>
    //       </React.Fragment>
    //     </div>
    //   </Dialog>
    // </Transition>
    <div className='relative md:hidden z-10'>
      <Transition
        show={categoriesSidebarVisible}
        appear
        as='div'
        className='absolute h-80 overflow-y-scroll bg-white rounded-2xl shadow-lg border-2 border-slate-400' // Adjust positioning and layering
        enter='transition ease-out duration-300'
        enterFrom='transform opacity-0 -translate-x-full'
        enterTo='transform opacity-100 translate-x-0'
        leave='transition ease-in duration-300'
        leaveFrom='transform opacity-100 translate-x-0'
        leaveTo='transform opacity-0 -translate-x-full'
        // onBlur={() => setCategoriesSidebarVisible(false)}
      >
        {CategoriesMenu}
      </Transition>
    </div>
  );

  const user = useAuthUser();
  let userId = user()?.id ?? '';
  // useEffect(() => {
  //   if (mode === 'full' && !userId) {
  //     setTopMessage(
  //       <h3 className='text-center text-2xl leading-normal'>
  //         Unlock the amazing power of AI.
  //         <br />
  //         With over 100 multi-step AI powered tools
  //         <br />
  //         {'Easier and more effective than "chatbot" style tools'}
  //         <br />
  //         Save thousands of hours, tools for every task.
  //         <br />
  //         <ButtonPrimary
  //           onClick={signUpModal}
  //           className='flex-1'
  //         >
  //           Sign Up Now &nbsp; <FaArrowRight />
  //         </ButtonPrimary>
  //       </h3>
  //     ); // tested for 1 day, only got 1 cv across everything
  //   }
  // }, [mode, userId]);

  // const [showSurveyDialog, setShowSurveyDialog] = useState(false);

  // useEffect(() => {
  //   if (!userId) return;
  //   axios
  //     .get(`${backendURL}/survey/considerUser`, {
  //       headers: {
  //         Authorization: authHeader,
  //       },
  //     })
  //     .then(response => {
  //       if (response.status === 200) {
  //         if (response.data.showSurvey) {
  //           setShowSurveyDialog(true);
  //         } else {
  //           ReactGA.event('survey_test', {
  //             shownToUser: false,
  //           });
  //           ReactPixel.track('survey_test', {
  //             shownToUser: false,
  //           });
  //         }
  //       }
  //     })
  //     .catch(() => {});
  // }, []);

  const hasCreditPaymentMessage =
    location.state?.creditsBought &&
    location.state?.newCreditAmount &&
    location.state?.transactionType;

  let creditPaymentMessage = '';

  if (hasCreditPaymentMessage) {
    creditPaymentMessage += `${location.state.creditsBought} credits `;
    if (location.state.transactionType === 'subscription') {
      creditPaymentMessage += 'monthly subscription ';
    }
    creditPaymentMessage += 'added';
    if (location.state.bonusCredits) {
      creditPaymentMessage += ` (plus a bonus of ${location.state.bonusCredits} credits)`;
    }
    creditPaymentMessage += `. Your new total is ${location.state.newCreditAmount.toFixed(
      1
    )} credits.`;
    if (location.state.transactionType === 'subscription') {
      creditPaymentMessage += ' Your next payment date is ';
      if (dateOfNextPayment) {
        creditPaymentMessage += dateOfNextPayment.toLocaleDateString();
      } else {
        creditPaymentMessage += 'in one month';
      }
      creditPaymentMessage += '.';
    }
  }

  const dialogRef = useRef<HTMLDialogElement | null>(null);

  useLayoutEffect(() => {
    dialogRef.current?.showModal();
  }, [dialogRef]);

  return (
    <PageSearchContext.Provider
      value={{
        setSearchTerm,
        setSelectedCategoryId,
        blockSortingMethod,
        setBlockSortingMethod,
        freePaid,
        setFreePaid,
      }}
    >
      {location.state?.loginNotification && (
        <dialog
          className='backdrop:bg-gray-400 backdrop:bg-opacity-50'
          ref={dialogRef}
        >
          <div className='flex justify-between gap-4 mb-4'>
            <h1 className='text-lg font-bold'>Notification from Skillfusion</h1>
            <button
              onClick={() => {
                dialogRef.current?.close();
              }}
            >
              <MdClose />
            </button>
          </div>

          <p>{location.state.loginNotification}</p>
        </dialog>
      )}
      <div
        className={`nc-PageSearch  ${className}`}
        data-nc-id='PageSearch'
      >
        <Helmet>
          <title>Search || SkillFusion Search</title>
        </Helmet>
        {/* {showSurveyDialog && <SurveyDialog />} */}
        <div
          className={`nc-HeadBackgroundCommon top-0 left-0 right-0 w-full bg-primary-50 dark:bg-neutral-800/20 flex justify-center`}
          data-nc-id='HeadBackgroundCommon'
        >
          {lpCopy}
        </div>
        <div className='container'>
          {mode == 'full' ? (
            <header className='max-w-2xl mx-auto flex flex-col'>
              <form
                className='relative w-full '
                onSubmit={e => {
                  e.preventDefault();
                  performSearch();
                }}
              >
                <label
                  htmlFor='search-input'
                  className='text-neutral-500 dark:text-neutral-300'
                >
                  <span className='sr-only'>Search all icons</span>
                  <Input
                    className='shadow-lg border-0 dark:border'
                    id='search-input'
                    type='search'
                    placeholder='Search For Tools'
                    sizeClass='pl-14 py-5 pr-5 md:pl-16'
                    rounded='rounded-full'
                    onChange={handleSearchInputChange}
                    value={searchTerm}
                  />
                  <ButtonCircle
                    className='cursor-pointer absolute right-2.5 top-1/2 transform -translate-y-1/2'
                    size=' w-11 h-11'
                    type='submit'
                  >
                    <i className='las la-arrow-right text-xl'></i>
                  </ButtonCircle>
                </label>
              </form>
            </header>
          ) : (
            ''
          )}
        </div>

        <div
          className={`container py-2 lg:pb-28 lg:pt-2 space-y-16 lg:space-y-28 ${
            mode === 'full' ? '' : 'pl-0 pr-0'
          } `}
        >
          {showModal && (
            <div className='share-modal fixed inset-0 flex justify-center'>
              <div className='absolute inset-0 bg-black opacity-50 z-10'></div>
              <div className='relative z-20'>
                <div className='m-auto flex items-center justify-center p-4'>
                  <div className='bg-white p-4 rounded shadow-md m-auto max-w-[90vw] z-20'>
                    <div className='flex flex-col gap-4'>
                      <div>
                        <button
                          className='sticky left-0 top-0'
                          onClick={closeModal}
                        >
                          {' '}
                          <MdClose />
                        </button>
                      </div>
                      <div
                        className={`${'flex p-8'} justify-between gap-4 overflow-y-auto max-w-full max-h-[87vh]`}
                      >
                        {showSignup && (
                          <PageSignUp
                            inModal={true}
                            onVerify={(tempID: string, email: string) =>
                              handleVerification(tempID, email)
                            }
                          />
                        )}
                        {showVerification && (
                          <VerifyEmail
                            tempModalID={verificationDetails.tempID}
                            message={verificationDetails.verifyEmailMessage}
                            onEmailVerify={HandleEmailVerification}
                          />
                        )}
                        {/*} {showPayPage && (
                        <PagePay
                          customHeading={customPayPageHeading}
                          inModal={true}
                          onPayment={() => {
                            setShowPayPage(false);
                            setShowModal(false);
                            setPurchaseMessage('');
                          }}
                        />
                      )} */}
                      </div>
                      <div className='flex gap-2 items-center'></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <main>
            {mode == 'full' ? (
              <FilterContext.Provider value={{ filters, setFilters }}>
                <HeaderFilterSearchPage onCategoryChange={handleFilterChange} />
              </FilterContext.Provider>
            ) : (
              ''
            )}
            {hasCreditPaymentMessage && (
              <>
                <h1 className='text-3xl'>{creditPaymentMessage}</h1>
                <br />
              </>
            )}

            {location?.state?.emailVerifiedMessage && (
              <>
                <h1 className='text-3xl'>
                  {location.state.emailVerifiedMessage}
                </h1>
                <br />
              </>
            )}

            {topMessage}
            {/*<div className='grid sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-x-8 gap-y-10 mt-8 lg:mt-10'>*/}
            <div className='flex flex-col md:flex-row gap-4 mt-2'>
              {mode === 'full' && showCategoriesMenu && (
                <>
                  <div className='lg:mt-8 md:shrink-0 hidden md:block bg-neutral-100 dark:bg-neutral-900 text-neutral-500'>
                    {CategoriesMenu}
                  </div>
                  <div className='lg:mt-8 md:shrink-0 md:hidden'>
                    <div className='flex items-center gap-4'>
                      <button
                        onClick={toggleOpenMenu}
                        className='p-2.5 rounded-lg text-neutral-700 dark:text-neutral-300 focus:outline-none flex items-center justify-center'
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          className='h-7 w-7'
                          viewBox='0 0 20 20'
                          fill='currentColor'
                        >
                          <path
                            fillRule='evenodd'
                            d='M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z'
                            clipRule='evenodd'
                          />
                        </svg>
                      </button>
                      <h2 className='font-bold text-lg'>Categories</h2>
                    </div>
                    {CategoriesSidebar}

                    {categoriesSidebarVisible || CategoryBreadcrumb}
                  </div>
                </>
              )}
              <div
                className={`grid gap-x-8 gap-y-10 lg:mt-8 grid-cols-1 ${
                  mode === 'preview' ? 'overflow-auto' : 'xl:grid-cols-1'
                }`}
                style={
                  mode === 'preview'
                    ? { height: '800px', padding: '0 10px' }
                    : {}
                }
              >
                {(searchInProgress || searchResults.length === 0) && (
                  <h3 className='font-bold text-lg text-center'>
                    {searchInProgress
                      ? 'Loading tools...'
                      : searchResults.length === 0
                        ? 'No tools matched your search'
                        : ''}
                  </h3>
                )}
                {searchResults.map((result, index) => {
                  //console.log(result);
                  let link = '/';
                  if (result._type === 'category') {
                    link = `/page-collection/${result.name}`;
                  } else if (result._type === 'block') {
                    link = `/fusion/${result._id}`;
                  }
                  //const bgLightMode = `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${result.coverPhoto ? result.coverPhoto : nftsImgs[5]})`;
                  //const bgDarkMode = `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${result.coverPhoto ? result.coverPhoto : nftsImgs[5]})`;
                  return (
                    <FusionCard
                      key={result._id}
                      link={link}
                      handleLinkClick={handleLinkClick}
                      price={result.price}
                      priceFrequency={result.priceFrequency}
                      coverPhoto={result.coverPhoto}
                      name={result.name}
                      username={result.username}
                      views={result.views}
                      downloads={result.downloads}
                      description={result.description}
                    />
                  );
                })}
                {searchInProgress || (
                  <div
                    className='w-full rounded-lg text-black bg-white flex-col md:flex-row border-2 rounded-md shadow-lg w-full flex h-full rounded-3xl hover:shadow-blue pb-6'
                    style={{
                      backgroundSize: 'cover',
                      backgroundPosition: 'center center',
                    }}
                  >
                    <div className='relative flex-shrink-0 rounded-t-3xl mb-4'></div>
                    <div className=''>
                      <span className='flex items-center justify-between w-full gap-2'>
                        <div className='flex flex-col h-80 p-4'>
                          <p style={{ paddingBottom: '10px' }}>
                            <b>
                              {"Can't see a tool that you would like to use?"}
                            </b>
                          </p>
                          <p style={{ paddingBottom: '10px' }}>
                            Come suggest it on our{' '}
                            <a
                              style={{
                                color: 'violet',
                                textDecoration: 'underline',
                              }}
                              href='https://www.facebook.com/groups/707666124313547/permalink/903792524700905/?mibextid=uJjRxr'
                              target='_blank'
                              rel='noreferrer'
                            >
                              facebook group poll
                            </a>
                            .
                          </p>
                          <h2 className='font-bold text-base mb-2 overflow-ellipsis max-w-full'>
                            Or Make Your Own Tools
                          </h2>
                          <p style={{ paddingBottom: '10px' }}>
                            Make tools to use yourself or publish them here on
                            our marketplace
                          </p>
                          <p style={{ paddingBottom: '10px' }}>
                            You can sell them and get a commission or make them
                            free
                          </p>
                          <p style={{ paddingBottom: '10px' }}>
                            Try our free{' '}
                            <a
                              style={{
                                color: 'violet',
                                textDecoration: 'underline',
                              }}
                              href='/fuser/0'
                            >
                              tool builder
                            </a>{' '}
                            now
                          </p>
                          <p className='overflow-ellipsis max-w-full'></p>
                        </div>
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
            <Pagination />
            <ButtonPrimary loading>Show me more</ButtonPrimary>
          </div> */}
          </main>

          {/* 
        <div className="relative py-16 lg:py-28">
          <BackgroundSection />
          <SectionSliderCollections />
        </div> */}

          {/* <SectionBecomeAnAuthor /> */}
        </div>
      </div>
    </PageSearchContext.Provider>
  );
};

export default PageSearch;
