import React, { FC, useState, useContext, useEffect } from 'react';
import ReactGA from 'react-ga4';
import jszip from 'jszip';
import FuserLoader from '../../containers/FuserPage/FuserLoader';
import Block from '../../models/Block';
import BlockProps from '../../models/BlockProps';
import { useCredit } from '../../context/CreditContext';
import FuserContext from '../../context/FuserContext';
import { generateImageFromDALLE, pause } from '../../utils/fuser';
import useBlockRunner from '../../hooks/useBlockRunner';
import axios from 'axios';
import AutocompleteTextarea from '../../containers/FuserPage/AutocompleteTextarea';
import { useAuthHeader } from 'react-auth-kit';
import { restartButtonStyles, testButtonStyles } from '../../constants/styles';
import { backendURL } from '../../constants/environmental';
import { updateAtIndex, updateAtIndexRun } from '../../utils/array';
import { truncateAfter } from '../../utils/string';
import { MAX_PREVIEW_CHARS } from '../../constants/blocks';

const randomNumberBetween = (min: number, max: number) => {
  return min + Math.random() * (max - min);
};

// const testImageURLs = [
//   // links expire within about an hour
//   'https://oaidalleapiprodscus.blob.core.windows.net/private/org-GCecZqftekLS6KNIOMEugqfR/user-MtoqDNuinshaJYEDEftLLz14/img-yW0a6FgPFsdENTzcksyOxGIw.png?st=2023-09-07T14%3A41%3A44Z&se=2023-09-07T16%3A41%3A44Z&sp=r&sv=2021-08-06&sr=b&rscd=inline&rsct=image/png&skoid=6aaadede-4fb3-4698-a8f6-684d7786b067&sktid=a48cca56-e6da-484e-a814-9c849652bcb3&skt=2023-09-06T23%3A46%3A41Z&ske=2023-09-07T23%3A46%3A41Z&sks=b&skv=2021-08-06&sig=I5ZFmfrxVGxG3b%2ByvqxfUqTNA1yzgg6tJbRNCNfV%2BCw%3D',
//   'https://oaidalleapiprodscus.blob.core.windows.net/private/org-GCecZqftekLS6KNIOMEugqfR/user-MtoqDNuinshaJYEDEftLLz14/img-sw6HHSEUFPizRFL4uBazCREQ.png?st=2023-09-07T13%3A00%3A40Z&se=2023-09-07T15%3A00%3A40Z&sp=r&sv=2021-08-06&sr=b&rscd=inline&rsct=image/png&skoid=6aaadede-4fb3-4698-a8f6-684d7786b067&sktid=a48cca56-e6da-484e-a814-9c849652bcb3&skt=2023-09-06T23%3A46%3A07Z&ske=2023-09-07T23%3A46%3A07Z&sks=b&skv=2021-08-06&sig=CKa2A9/3D/PFKIm0catsovDjB1DCG5fG4QIrP4YgIJg%3D',
// ];

const ImageBlock: FC<BlockProps> = ({
  isLoading,
  index,
  block,
  setIsLoading,
  toolId,
  handleChange,
  collapsed,
  imageURLs,
  setImageURLs,
  isShared,
  sharedImageURLs,
  sharedBy,
}) => {
  const {
    blockStyles,
    runnerMode,
    replacePlaceholders,
    setBlocks,
    setActivityLog,
    blocks,
    userId,
    runnerIndex,
    stillRunning,
    // handleTextareaFocus,
    // textAreaRefs,
    // setRunnerIndex,
  } = useContext(FuserContext);
  const authHeader = useAuthHeader()();

  const {
    // credit,
    updateCredits,
  } = useCredit();

  const [downloadStatusMessage, setDownloadStatusMessage] =
    useState<string>('');
  const [downloadInProgress, setDownloadInProgress] = useState<boolean>(false);

  const updateBlocks = () =>
    setBlocks((blocks: Block[]) => updateAtIndex(index, block, blocks));

  useEffect(() => {
    if (block?.data?.model === undefined) {
      block.data.model = 'dall-e-2';
    }
    updateBlocks();
  }, []);

  async function fetchImageBlobFromBackend(url: string) {
    //console.log("image url:",url)
    // const encodedURL = encodeURIComponent(url);
    // let response;
    try {
      const response: any = await axios.post(
        `${backendURL}/blocks/getimages`,
        {
          url: url,
        },
        {
          headers: {
            Authorization: authHeader,
          },
          responseType: 'blob',
        }
      );

      const blob = response.data;
      // console.log('response:', response);
      // console.log('blob', blob.type, blob.size, blob.text);
      return blob;

      if (response.ok) {
        return blob;
      } else {
        throw new Error('Error fetching image');
      }
    } catch (error) {
      throw new Error(
        error //'Error downloading images, the image links may have expired'
      );
      // try {
      //   if (!response) throw new Error('No response from openAI API')
      //   const responseJSON = await response.json();
      //   if (responseJSON?.error?.status === 403) {
      //     //setImageURLs([]);
      //     throw new Error(
      //       'The image links have expired and cannot be downloaded'
      //     );
      //   }
      //   else throw error;
      // }
      // catch (error) {
      //   throw error;
      // }
    }
  }

  const downloadImages = async () => {
    if (downloadInProgress) return;
    if (imageURLs === undefined) return;

    setDownloadInProgress(true);

    try {
      const zip = new jszip();
      setDownloadStatusMessage(
        'Downloading images; this may take a minute or two'
      );

      const images = await Promise.all(
        imageURLs.map(fetchImageBlobFromBackend)
      );

      images.forEach((image, i) => {
        // console.log('image:', image.type, image.size);
        zip.file(`image-${i + 1}.jpg`, image);
        // console.log('Zip file content size:', image.size);
      });

      // Creates a dummy <a> element which allows downloading
      zip.generateAsync({ type: 'blob' }).then(content => {
        const url = URL.createObjectURL(content);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'images.zip';
        a.click();
      });

      setDownloadStatusMessage('Download successful!');
    } catch (error) {
      const errorObj = error as { message: string };
      setDownloadStatusMessage(errorObj?.message ?? 'Download failed');
    } finally {
      setDownloadInProgress(false);
    }
  };

  const cancelRequest = async () => {
    // cancel loading spinner if another answer changes
    if (isLoading) {
      console.log('cancelling image request for block', index);
      const cancelResponse = await axios.get(
        `${backendURL}/openai/cancelImage`,
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );
      setIsLoading(false);
      console.log('cancel effect finished for block', index);
    }
  };

  // const onCancelClick = async () => {
  //   cancelRequest();
  //   setRunnerIndex(-Infinity);
  // };

  useEffect(() => {
    if (index !== runnerIndex && runnerMode && stillRunning) {
      cancelRequest();
    }
  }, [runnerIndex]);

  useEffect(() => {
    if (index !== runnerIndex && runnerMode && stillRunning) {
      //console.log(`cancelling any prompts loading at block ${index}`);
      cancelRequest();
    }
  }, [runnerIndex]);

  const onRestartPrompt = async () => {
    console.log(`restarting prompt at block ${index}`);
    onImageSaveClick();
  };

  const onImageSaveClick = async () => {
    setIsLoading(true); // start loading
    setDownloadStatusMessage('');
    const block = blocks[index];
    const promptText = block.data.inputToProcess;
    const messageText = block.data.imageMessage;

    if (['', undefined].includes(promptText?.trim())) {
      setErrorMessage('Please enter an image description');
      setIsLoading(false);
      return;
    }

    setErrorMessage('');

    let processedInput: any[] = replacePlaceholders(promptText, blocks);

    if (!Array.isArray(processedInput)) {
      processedInput = [processedInput];
    }
    // if (Array.isArray(processedInput[0])) {processedInput = processedInput[0];}

    let tempImageURLs: string[] = [];

    // cut off any words that make the prompt go over 1000 characters otherwise API error
    processedInput = processedInput.map(
      prompt => prompt.match(/.{1,1000}(?=( |$))/s)[0]
    );

    const MAX_IMAGE_PROMISE_BATCH_SIZE_FOR_MODEL: any = {
      'dall-e-2': 10,
      'dall-e-3': 2,
    };

    const MAX_IMAGE_PROMISE_BATCH_SIZE =
      MAX_IMAGE_PROMISE_BATCH_SIZE_FOR_MODEL[block.data.model];

    const imagePromptBatches: any[] = [];

    // group promises into batches of MAX_IMAGE_PROMISE_BATCH_SIZE
    for (
      let imagePromptIndex = 0;
      imagePromptIndex < processedInput.length;
      imagePromptIndex++
    ) {
      const imagePrompt = processedInput[imagePromptIndex];
      const imagePromptBatchIndex = Math.floor(
        imagePromptIndex / MAX_IMAGE_PROMISE_BATCH_SIZE
      );
      const imagePromptBatch = imagePromptBatches[imagePromptBatchIndex];

      if (imagePromptBatch === undefined)
        imagePromptBatches[imagePromptBatchIndex] = [imagePrompt];
      else
        imagePromptBatches[imagePromptBatchIndex] = [
          ...imagePromptBatch,
          imagePrompt,
        ];
    }

    // let response: any[] = [];
    const MAX_RETRIES = 40;
    const MAX_RESPONSE_WAIT_TIME_IN_SECONDS = 120;
    const INITIAL_PAUSE_DURATION_IN_MS = randomNumberBetween(5000, 10000);
    let retriesRemaining = MAX_RETRIES;

    for (
      let currentimagePromptBatchIndex = 0,
        pauseDuration = INITIAL_PAUSE_DURATION_IN_MS;
      currentimagePromptBatchIndex < imagePromptBatches.length; // currentPromptBatchIndex is updated in the block of code below

    ) {
      console.log(
        'prompt batch',
        currentimagePromptBatchIndex + 1,
        'of',
        imagePromptBatches.length,
        '. Retries remaining:',
        retriesRemaining
      );

      if (retriesRemaining === 0) {
        ReactGA.event('max_retries');
        throw new Error(
          `${MAX_RETRIES} consecutive failed requests from OpenAI, please try again later.`
        );
      }

      setPromptBatchData({
        promptBatchNumber: currentimagePromptBatchIndex + 1,
        numberOfPromptBatches: imagePromptBatches.length,
      });

      const imagePromptBatch = imagePromptBatches[currentimagePromptBatchIndex];

      try {
        const response: any = await axios.post(
          `${backendURL}/openai/image`,
          {
            prompts: imagePromptBatch,
            userId,
            toolId,
            model: block.data?.model ?? 'dall-e-2',
          },
          {
            headers: {
              Authorization: authHeader,
            },
          }
        );

        // console.log('response', response);

        if (response?.status === 204) {
          console.log('Cancelled image generation');
          return;
          //return 'CANCEL';
        }

        // This is for if you change the response format to b64_json instead of urls
        // tempImageURLs = [
        //   ...tempImageURLs,
        //   ...response.data.imageUrls.map(({ b64_json }: any) => b64_json),
        // ];

        const updatedCreditInDollars = response?.data?.updatedCredit;
        const newImageUrls = response?.data?.imageUrls;

        if (newImageUrls) {
          const trimmedImageUrls = newImageUrls.map((url: string) =>
            url.trim()
          );
          block.data.response = trimmedImageUrls;
          tempImageURLs = [...tempImageURLs, ...trimmedImageUrls];
          if (updatedCreditInDollars !== undefined)
            updateCredits(updatedCreditInDollars * 10);
        }

        currentimagePromptBatchIndex++;
        pauseDuration = INITIAL_PAUSE_DURATION_IN_MS;
        retriesRemaining = MAX_RETRIES;
      } catch (error) {
        console.log(error);
        const code = error?.code;
        const status = error?.response?.status;
        const statusText =
          error?.response?.statusText || error?.response?.statusMessage;
        console.log('gpt error:', error.response);
        if (
          [502, 503].includes(status) ||
          (status === 429 && statusText?.startsWith('Rate limit reached')) ||
          statusText?.startsWith('Too Many Requests') ||
          (status === 500 &&
            statusText?.startsWith('The server had an error')) ||
          code === 'ERR_NETWORK'
        ) {
          if (status === 429) {
            ReactGA.event('token_limit_retry');
          }
          if (status === 500) ReactGA.event('openai_server_error_retry');
          if (status === 502) ReactGA.event('502_error_retry');
          if (status === 503) ReactGA.event('openai_server_overloaded_retry');
          if (code === 'ERR_NETWORK') ReactGA.event('network_error');

          console.log('pausing for', pauseDuration, 'ms');
          await pause(pauseDuration);
          pauseDuration = Math.min(
            MAX_RESPONSE_WAIT_TIME_IN_SECONDS * 1000,
            pauseDuration * randomNumberBetween(1.5, 1.7)
          );
          console.log(
            'pauseDuration set to',
            pauseDuration,
            'ms on prompt',
            currentimagePromptBatchIndex + 1
          );
          retriesRemaining--;
          continue;
        } else {
          setErrorMessage(
            error?.response?.data?.code === 'content_policy_violation'
              ? "Your request was rejected as a result of OpenAI's safety system. Your prompt may contain text that is not allowed by OpenAI's safety system"
              : error?.message ?? 'Error with GPT response'
          );
          setIsLoading(false);
          return;
        }
      }
    }

    //////////////////////////////////////////////////////////////// OLD CODE

    // try {
    //   const responses: any = await Promise.all(imagePromises);

    //   if (responses.some(({ status }: any) => status === 204)) {
    //     console.log('Cancelled image generation');
    //     return;
    //     //return 'CANCEL';
    //   }

    //   if (block.data.response === undefined) block.data.response = [];

    //   for (let i = 0; i < responses.length; i++) {
    //     const response = responses[i];

    //     if (typeof response === 'object' && 'imageURL' in response) {
    //       block.data.response[i] = response.imageURL.trim();
    //       tempImageURLs.push(response.imageURL.trim());
    //       updateCredits(response.updatedCredit * 10);
    //     }
    //   }
    // } catch (error) {
    //   let errorObj = error as { message: string };
    //   setErrorMessage(errorObj.message ?? 'Error with GPT response');
    //   setIsLoading(false);
    //   return;
    // }

    //////////////////////////////////////////////////////////////// OLD CODE

    // for (let i = 0; i < processedInput.length; i++) {
    //   const pPrompt = processedInput[i];
    //   try {
    //     const promptResponse = await generateImageFromDALLE({
    //       prompt: pPrompt,
    //       token,
    //       userId,
    //       toolId
    //     });
    // if (typeof promptResponse === 'object' && 'imageURL' in promptResponse){

    //   block.data.response[i] = promptResponse.imageURL.trim();
    //   tempImageURLs.push(promptResponse.imageURL.trim());
    //   updateCredits(promptResponse.updatedCredit*10);
    // }

    //   }
    //   catch (error) {
    //     let errorObj = error as { message: string };
    //     setErrorMessage(errorObj.message ?? 'Error with GPT response');
    //     setIsLoading(false);
    //     return;
    //   }
    // }

    const newBlock = {
      ...block,
      data: {
        ...block.data,
        type: 'imageGen',
        inputToProcess: promptText,
        processedInput,
        imageMessage: messageText,
        response: tempImageURLs,
      },
      run: true,
    };

    setBlocks((blocks: Block[]) => updateAtIndexRun(index, newBlock, blocks)); // This will update your state with the new blocks
    setImageURLs(tempImageURLs); // update the state with new image URLs

    // Updating the local storage for the individual block
    // localStorage.setItem(`block_${index}`, JSON.stringify(blocks[index]));

    setIsLoading(false);
    setActivityLog((prevLog: string[]) => [
      ...prevLog,
      `Saved image block at index: ${index}`,
    ]);
  };

  useBlockRunner(onImageSaveClick, index);

  // const onTextareaFocus =
  //   (textareaId: number) =>
  //   (e: React.FocusEvent<HTMLTextAreaElement, Element>) => {
  //     handleTextareaFocus(e, block.id, textareaId);
  //   };

  const [errorMessage, setErrorMessage] = useState<string>('');

  const [promptBatchData, setPromptBatchData] = useState({
    promptBatchNumber: 0,
    numberOfPromptBatches: 0,
  });

  const { promptBatchNumber, numberOfPromptBatches } = promptBatchData;

  const { response, inputToProcess, imageMessage, model, blockHeading } =
    block.data;

  if (collapsed) {
    return (
      <>
        <div>Image block</div>
        {errorMessage && <p>{errorMessage}</p>}
      </>
    );
  }

  if (isShared) {
    // console.log(sharedImageURLs, imageURLs);
    return (
      <>
        <div className='grid gap-1 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1'></div>

        {blockHeading && (
          <div>{truncateAfter(MAX_PREVIEW_CHARS, blockHeading || 'Info')}</div>
        )}

        <div className='grid gap-1 overflow-y-scroll grow max-h-[50vh] lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1'>
          {(sharedImageURLs ?? imageURLs)?.map?.((url: string, i: number) => {
            return (
              <a
                key={i}
                href={url}
                target='_blank'
                rel='noreferrer'
              >
                <img
                  src={url}
                  alt={`Shared by ${sharedBy}`}
                />
              </a>
            );
          })}
        </div>
      </>
    );
  }

  return (
    <FuserLoader
      name='Image Block'
      loading={isLoading}
      message={`${
        imageMessage ? imageMessage + ' ' : ''
      }(Prompt ${promptBatchNumber} of ${numberOfPromptBatches})`}
    >
      <div className='grid gap-1 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1'></div>
      <>
        {runnerMode ? (
          response && response?.length > 0 ? (
            <>
              {imageMessage && <p>{imageMessage}</p>}

              {imageURLs && (
                <div className='grid gap-1 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1'>
                  {imageURLs.map?.((url: string, i: number) => {
                    return (
                      <a
                        key={i}
                        href={url}
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img
                          src={url}
                          alt={`Generated from prompt ${index}`}
                        />
                      </a>
                    );
                  })}
                </div>
              )}

              {errorMessage && <p>{errorMessage}</p>}

              <button
                className={restartButtonStyles}
                onClick={onRestartPrompt}
                title='Retry Prompt'
              >
                <svg
                  width='40'
                  height='40'
                  viewBox='0 0 70 70'
                  preserveAspectRatio='xMidYMid meet'
                >
                  <path
                    data-name='layer1'
                    d='M3.307 22.023a3 3 0 0 1 4.17.784l2.476 3.622A27.067 27.067 0 0 1 36 6c14.557 0 26 12.036 26 26.584a26.395 26.395 0 0 1-45.066 18.678 3 3 0 1 1 4.244-4.242A20.395 20.395 0 0 0 56 32.584C56 21.344 47.248 12 36 12a21.045 21.045 0 0 0-20.257 16.059l4.314-3.968a3 3 0 0 1 4.062 4.418l-9.737 8.952c-.013.013-.03.02-.043.033-.067.06-.143.11-.215.163a2.751 2.751 0 0 1-.243.17c-.076.046-.159.082-.24.12a3.023 3.023 0 0 1-.279.123c-.08.03-.163.05-.246.071a3.045 3.045 0 0 1-.323.07c-.034.006-.065.017-.1.022-.051.006-.102-.002-.154.002-.063.004-.124.017-.187.017-.07 0-.141-.007-.212-.012l-.08-.004-.05-.003c-.06-.007-.118-.03-.178-.04a3.119 3.119 0 0 1-.388-.087c-.083-.027-.16-.064-.239-.097a2.899 2.899 0 0 1-.314-.146 2.753 2.753 0 0 1-.233-.151 2.807 2.807 0 0 1-.262-.2 2.857 2.857 0 0 1-.2-.19 3.013 3.013 0 0 1-.224-.262c-.03-.04-.069-.073-.097-.114L2.523 26.194a3.001 3.001 0 0 1 .784-4.17z'
                    fill='#202020'
                  ></path>
                </svg>
              </button>
            </>
          ) : (
            <div>Image block</div>
          )
        ) : (
          <div
            className={blockStyles}
            key={index}
          >
            <label className='text-xs'>Image Description:</label>

            <AutocompleteTextarea
              block={block}
              index={index}
              onChange={handleChange}
              textAreaIndex={0}
              className='w-full prompt-textarea resize-none  bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner '
              name='inputToProcess'
              value={inputToProcess || ''}
            />

            <label className='text-xs w-max-content'>
              GPT Model:
              <select
                className='text-xs bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner '
                onChange={handleChange}
                value={model ?? 'dall-e-2'}
                name='model'
              >
                <option value='dall-e-2'>DALL·E 2</option>
                <option value='dall-e-3'>DALL·E 3</option>
              </select>
            </label>

            <span
              className='text-xs'
              id='prompt-message-input'
            >
              Optional heading for user:
            </span>

            <input
              className=' bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner '
              type='text'
              name='imageMessage'
              value={imageMessage || ''}
              onChange={handleChange}
            />

            <div className='grid gap-1 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1'>
              {imageURLs &&
                imageURLs.map?.((url: string, i: number) => {
                  return (
                    <a
                      key={i}
                      href={url}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <img
                        src={url}
                        alt={`Generated from prompt ${index}`}
                      />
                    </a>
                  );
                })}
            </div>

            <p>{errorMessage}</p>

            <button
              className='border-2 p-2 text-xs rounded-xl bg-blue-100 hover:bg-white'
              onClick={onImageSaveClick}
            >
              Generate Image
            </button>
          </div>
        )}
        {response && response?.length > 0 && imageURLs && (
          <div className='flex flex-col items-center'>
            <button
              className={testButtonStyles}
              onClick={downloadImages}
            >
              Download images
            </button>

            <p className='text-sm'>{downloadStatusMessage}</p>
          </div>
        )}
      </>
    </FuserLoader>
  );
};

export default ImageBlock;
