import React, { FC, useContext, useEffect, useState } from "react";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";
import { BiChevronDownCircle, BiChevronUpCircle } from "react-icons/bi";
import { GiTrashCan } from "react-icons/gi";
import { MdContentCopy } from "react-icons/md";
import { FiPlus } from "react-icons/fi";
import FuserContext from "../../context/FuserContext";
import Block from "../../models/Block";
import useShallowEqualMemo from "../../hooks/useShallowEqualMemo";
import {
  blockComponents,
  blockTypesExpandedByDefault,
} from "../../constants/blocks";
import { iconStyle, menuButtonStyles } from "../../constants/styles";
import { updateAtIndex } from "../../utils/array";

const sharableBlockTypes = ["prompt", "info", "image", "textToSpeech", "chat"];

const BlockOptions: FC<{ block: any; index: number }> = ({ block, index }) => {
  const {
    blocks,
    runnerMode,
    setBlocks,
    addBlock,
    moveBlockUp,
    moveBlockDown,
    duplicateBlock,
    deleteBlock,
    toggleBlockCollapse,
    collapsedBlocks,
    blockDataKeysHoldingReferences,
    setShareModalOpen,
    setBlockToShare,
    // toolId,
    // replacePlaceholders,
    // setShareMessage,
  } = useContext(FuserContext);

  const updateBlocks = () => setBlocks(updateAtIndex(index, block, blocks));

  const handleCollapseAfterRunningClick = (e: any) => {
    const oldValue = block.collapseAfterRunning;

    // will be undefined for old tools, default is true
    const newValue = [true, undefined].includes(oldValue) ? false : true;

    setBlocks((previousBlocks: Block[]) =>
      updateAtIndex(
        index,
        { ...block, collapseAfterRunning: newValue },
        previousBlocks
      )
    );
  };

  // const [statusMessage, setStatusMessage] = useState<string>('');

  const [editingBlockName, setEditingBlockName] = useState(false);

  const blockIds = useShallowEqualMemo(blocks.map(({ id }: any) => id));

  useEffect(() => {
    // discard any pending changes if the blocks have moved around
    setEditingBlockName(false);
  }, [blockIds]);

  const handleBlockNameChange = (e: any) => {
    block.data.name = e.target.value;
    updateBlocks();
  };

  const handleSaveBlockName = (e: any) => {
    const trimmedBlockName = block.data.name.trim();

    if (/^Block\d+$/.test(trimmedBlockName)) {
      return alert(
        "Names of the form Block#number# are reserved, please choose a different name"
      );
    }

    const otherBlockNames = blocks
      .map(({ data: { name } }: any) => name)
      .filter(
        (name: string, blockIndex: number) =>
          name !== undefined && blockIndex !== index
      );

    // console.log(
    //   'otherBlockNames',
    //   otherBlockNames,
    //   'trimmed input name',
    //   trimmedBlockName
    // );

    if (otherBlockNames.includes(trimmedBlockName)) {
      const indexOfBlockWithInputName = blocks
        .map(({ data: { name } }: any) => name)
        .findIndex((name: string) => name === trimmedBlockName);
      return alert(
        `This name has been taken at block ${indexOfBlockWithInputName}, please choose a different name.`
      );
    }

    if (!/^\w+$/.test(trimmedBlockName)) {
      return alert(
        "The name must consist of one or more letters/numbers/underscores and no other characters"
      );
    }

    const blocksWithUpdatedReferences = blocks.map((block: Block) => {
      const newDataKeyValuePairs = blockDataKeysHoldingReferences
        .filter((key: string) => block.data[key] !== undefined)
        .map((key: string) => {
          // console.log(block.data[key]);
          return [
            key,
            block.data[key]
              .toString()
              .replace(
                new RegExp(`@${lastSavedBlockName}(\\W|$)`, "g"),
                (match: string, nextCharacter: string) => {
                  // console.log(
                  //   'lastSavedBlockName',
                  //   lastSavedBlockName,
                  //   'match',
                  //   match,
                  //   'nextCharacter:',
                  //   nextCharacter
                  // );
                  return `@${trimmedBlockName}${nextCharacter}`;
                }
              ),
          ];
        });
      return {
        ...block,
        data: {
          ...block.data,
          ...Object.fromEntries(newDataKeyValuePairs),
        },
      };
    });

    // console.log(blocksWithUpdatedReferences);

    setBlocks(blocksWithUpdatedReferences);

    setEditingBlockName(false);
    //alert('name saved');
    // console.log('otherBlockNames:', otherBlockNames);
  };

  const [lastSavedBlockName, setLastSavedBlockName] = useState(
    block.data.name ?? ""
  );

  const handleEditBlockNameClick = () => {
    setLastSavedBlockName(block.data.name);
    setEditingBlockName(true);
  };

  const handleDiscardBlockNameChanges = () => {
    block.data.name = lastSavedBlockName;
    updateBlocks();
    setEditingBlockName(false);
  };

  const startShareProcess = () => {
    setBlockToShare(block);
    setShareModalOpen(true);
  };

  const blockType = block.blocktype ?? block.type?.replace("blockdiv-", "");

  const isSharable =
    sharableBlockTypes.includes(blockType) &&
    !(blockType === "prompt" && !block.data.showOutputToUser) &&
    !(blockType === "info" && block.data.selectValue === "internal") &&
    !collapsedBlocks.includes(block.id);

  const shareButton = isSharable && (
    <button className={menuButtonStyles} onClick={startShareProcess}>
      Share
    </button>
  );

  return (
    <div className="w-full flex items-center justify-end">
      <div className="flex gap-3 items-center justify-between p-2 bg-gradient-to-r from-blue-100 via-purple-200 to-blue-100 rounded-md shadow-lg dark:bg-neutral-800">
        {runnerMode || (
          <div className="flex gap-2 items-center">
            {(block.data.name || editingBlockName) && (
              <>
                <label className="">
                  Name:
                  {/* (optional, only letters, numbers and underscores allowed,
              no spaces) */}
                </label>
                {editingBlockName ? (
                  <input
                    className="border border-black p-1 rounded-lg"
                    onChange={handleBlockNameChange}
                    value={block.data.name ?? ""}
                  />
                ) : (
                  <p>{block.data.name}</p>
                )}
              </>
            )}
            {editingBlockName ? (
              <>
                <button
                  className={menuButtonStyles}
                  onClick={handleSaveBlockName}
                >
                  Save
                </button>
                <button
                  className={menuButtonStyles}
                  onClick={handleDiscardBlockNameChanges}
                >
                  Discard
                </button>
              </>
            ) : (
              <button
                className={menuButtonStyles}
                onClick={handleEditBlockNameClick}
              >
                {`${!block.data.name ? "Add block" : "Edit"} name`}
              </button>
            )}
          </div>
        )}
        <div className="flex flex-col gap-1">
          <div className="text-xs flex gap-4">
            <span>Block: {index}</span>
            {runnerMode || (
              <span>
                Type:{" "}
                {blockComponents?.[block?.type]?.selectName?.replace(
                  /block/gi,
                  ""
                )}
              </span>
            )}
          </div>
          {runnerMode || (
            <div className="flex items-center gap-2">
              <label
                className="text-xs align-middle"
                htmlFor={`collapseAfterRunning-${index}`}
              >
                Collapse block after running
              </label>
              <input
                id={`collapseAfterRunning-${index}`}
                type="checkbox"
                onChange={handleCollapseAfterRunningClick}
                checked={
                  block.collapseAfterRunning !== undefined
                    ? block.collapseAfterRunning
                    : blockTypesExpandedByDefault.includes(block.type)
                      ? false
                      : true
                }
              />
            </div>
          )}
        </div>
        {runnerMode || (
          <>
            {shareButton}
            <button
              className={menuButtonStyles}
              onClick={() => duplicateBlock(index)}
              title="Duplicate Block"
            >
              <MdContentCopy className={iconStyle} />
            </button>

            <button
              className={menuButtonStyles}
              onClick={() => moveBlockUp(index)}
              title="Move Block Up"
            >
              <AiOutlineArrowUp className={iconStyle} />
            </button>

            <button
              className={menuButtonStyles}
              onClick={() => moveBlockDown(index)}
              title="Move Block Down"
            >
              <AiOutlineArrowDown className={iconStyle} />
            </button>

            <button
              className={menuButtonStyles}
              onClick={() => deleteBlock(block.id)}
              title="Delete Block"
            >
              <GiTrashCan className={iconStyle} />
            </button>

            {/*<button className={menuButtonStyles} onClick={toggleJsonVisibility}>
          <BsFiletypeJson className={iconStyle} />
  </button>*/}
          </>
        )}

        {runnerMode && shareButton}

        <button
          className={menuButtonStyles}
          onClick={() => toggleBlockCollapse(block.id)}
          title={`${
            collapsedBlocks.includes(block.id) ? "Expand" : "Collapse"
          } Block`}
        >
          {collapsedBlocks.includes(block.id) ? (
            <BiChevronUpCircle className={iconStyle} />
          ) : (
            <BiChevronDownCircle className={iconStyle} />
          )}
        </button>

        {runnerMode || (
          <button
            className={`${menuButtonStyles}`}
            onClick={() => addBlock(index)}
          >
            <FiPlus className={`${iconStyle}`} />
          </button>
        )}
      </div>
    </div>
  );
};

export default BlockOptions;
