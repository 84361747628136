import React, { FC, useContext } from 'react';
import axios from 'axios';
import { useAuthHeader, useAuthUser } from 'react-auth-kit';
import ReactGA from 'react-ga4';
import FuserContext from '../../context/FuserContext';
import MyToolTips from '../../components/MyTooltip';
import Toggle from '../../components/Toggle';
import { backendURL } from '../../constants/environmental';

const IsPublishedSection: FC<{}> = () => {
  const {
    toolId,
    title,
    priceFrequency,
    price,
    description,
    chosenCategories,
  } = useContext(FuserContext);

  const { isRequestPublished, setIsRequestPublished, saveBlock } =
    useContext(FuserContext);

  const authHeader = useAuthHeader()();

  // console.log(chosenCategories);

  const togglePublish = async () => {
    // save first
    //handleSaveBlock={saveBlock}
    if (toolId == '') {
      alert('Please save tool first');
    } else {
      await saveBlock();

      try {
        const TogglePublishRequest = await axios.patch(
          `${backendURL}/blocks/publish/${toolId}`,
          {
            title,
            priceFrequency,
            price,
            description,
            categories: chosenCategories,
          },
          {
            headers: {
              Authorization: authHeader,
            },
          }
        );
        //console.log('toggled ', TogglePublishRequest.data);
        setIsRequestPublished(!isRequestPublished); // update the isPublished state

        // publish toggle clicked
        ReactGA.event('publish_toggle');
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    // console.log('block id : ', toolId);
    togglePublish();
  };

  return (
    <label className='flex items-center cursor-pointer'>
      <Toggle
        toggled={isRequestPublished}
        onChange={handleToggle}
      />
      <div
        className={`ml-3 text-gray-700 font-medium ${
          isRequestPublished ? 'text-green-500' : 'text-red-500'
        }`}
      >
        {isRequestPublished ? 'Publish Requested' : 'Publish'}
      </div>{' '}
      &nbsp;{' '}
      <MyToolTips
        content='
              <p>Publishing:</p>
              <p>Press the toggle if you wish to publish your tool.</p>
              <p>We will review your tool before publishing, we may send feed back and recommend improvements</p>
              <p>Tools can not be a single prompt, check out our help files for more information.</p>
              '
        tipID='block-types'
        datatooltipplace='left'
      />
    </label>
  );
};

const IsAdminPublishedSection: FC<{}> = () => {
  const { toolId, blocks } = useContext(FuserContext);

  const { isPublished, setIsPublished, saveBlock } = useContext(FuserContext);

  const user = useAuthUser();
  const authHeader = useAuthHeader()();

  const togglePublish = async () => {
    await saveBlock();
    const hasHTMLPreview = blocks
      .map((block: any) => block.blocktype)
      .includes('html');

    // saveBlock will unpublish by default so nothing else to do if we are unpublishing
    if (hasHTMLPreview && isPublished) return;

    try {
      const TogglePublishRequest = await axios.patch(
        `${backendURL}/blocks/admin-publish/${toolId}`,
        {},
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );
      //console.log('toggled ', TogglePublishRequest.data);
      setIsPublished(!isPublished); // update the isPublished state

      // publish toggle clicked
      ReactGA.event('publish_toggle');
    } catch (error) {
      console.error(error);
    }
  };

  const handleToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    togglePublish();
  };

  if (user()?.loggedin != 'false') {
    return <></>;
  }

  return (
    <label className='flex items-center cursor-pointer'>
      <Toggle
        toggled={isPublished}
        onChange={handleToggle}
      />
      <div
        className={`ml-3 text-gray-700 font-medium ${
          isPublished ? 'text-green-500' : 'text-red-500'
        }`}
      >
        {isPublished ? 'Published By Admin' : 'Admin Approved'}
      </div>{' '}
      &nbsp;{' '}
      <MyToolTips
        content='
              <p>Admin Recommendations:</p>
              <p>Is it more than just a prompt, 3+ steps minimum</p>
              <p>Check for anything malicious or spammy</p>
              <p>Check for anything that may get us in trouble with openai like inapproiate requests</p>
              <p>Does it do what the title and description say?</p>
              <p>Is it high quality, is it unique from other tools?</p>
              <p>Is the price reasonable based on what the tool can do?</p>
              '
        tipID='block-types'
        datatooltipplace='left'
      />
    </label>
  );
};

export default IsPublishedSection;
export { IsAdminPublishedSection };
