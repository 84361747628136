import React, { FC, useState, FormEvent, useContext } from 'react';
import axios from 'axios';
import { useAuthHeader, useAuthUser } from 'react-auth-kit';
import FuserContext from '../../context/FuserContext';
import MyToolTips from '../../components/MyTooltip';
import { backendURL } from '../../constants/environmental';

const PublishForm: FC<{}> = () => {
  const {
    toolId,
    price,
    priceFrequency,
    setPrice,
    setPriceFrequency,
    description,
    handleDescriptionChange,
    furtherInfo,
    handleFurtherInfoChange,
    isPublished,
  } = useContext(FuserContext);

  // const auth = useAuthUser();
  const authHeader = useAuthHeader()();

  const [loading, setLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState<string>('');

  const handleRadioClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPriceFrequency(e.target.value);
    setStatusMessage('');
  };

  const [localPrice, setLocalPrice] = useState<string>(price.toString());

  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputPrice = e.target.value;
    setLocalPrice(inputPrice);
    setPrice(Number(inputPrice));
    setStatusMessage('');
  };

  const validateAndSubmit = (event: FormEvent) => {
    event.preventDefault();
    const newPrice = Math.round(price * 10) / 10;
    if (newPrice != price) setStatusMessage('Price rounded to ' + newPrice);
    handlePublish(price);
  };

  const handlePublish = async (price: number) => {
    setLoading(true);
    try {
      console.log({ price, priceFrequency });
      await axios.put(
        `${backendURL}/blocks/updateprice/${toolId}`,
        { price, priceFrequency },
        {
          headers: {
            Authorization: authHeader,
            'Content-Type': 'application/json',
          },
        }
      );

      // Handle the response as needed, e.g., update the UI to reflect the changes.
      setStatusMessage('Block price updated successfully!');
      setLoading(false);
    } catch (error) {
      setStatusMessage('Error updating block prices');
      console.error('Error updating block prices:', error);
      // Handle the error as needed, e.g., show an error message to the user.
    }
  };

  return (
    <>
      <form
        className='w-full text-xs gap-2 flex flex-col'
        onSubmit={validateAndSubmit}
      >
        <label htmlFor='description'>
          Description:{' '}
          <MyToolTips
            content='
              <p>You can use any of these HTML tags to format your description:</p>
              <p>h2, h3, a, ul, li, p, b, i, u, em, strong</p>
              <p>Links to other pages on Skillfusion are allowed</p>
              <p>Youtube video URLs will be embedded</p>'
            tipID='block-types'
            datatooltipplace='left'
          />
        </label>

        <textarea
          placeholder='Add lots to your description to let users know what is inside. Adding plenty of text here can also increase the chances your tool will come up in a Google Search. What does your tool do, what makes it special, who is it for...'
          id='description'
          className='w-full h-64 px-2 py-2 rounded-md text-xs dark:bg-neutral-900 dark:text-neutral-200'
          value={description}
          onChange={handleDescriptionChange}
        />

        <label htmlFor='furtherInfo'>
          Further info:{' '}
          <MyToolTips
            content='This section is minimized by default on the tool details page</p>'
            tipID='block-types'
            datatooltipplace='left'
          />
        </label>

        <textarea
          placeholder="This is a good place to show examples of the tool's output. Adding more content about your tool here may also help your tool rank higher in search engines like Google."
          id='furtherInfo'
          className='w-full h-64 px-2 py-2 rounded-md text-xs dark:bg-neutral-900 dark:text-neutral-200'
          value={furtherInfo}
          onChange={handleFurtherInfoChange}
        />

        <label htmlFor='priceInDollars'>
          Price: $ (USD){' '}
          <i style={{ fontSize: '8pt', color: 'darkred' }}>you get 100%.</i>
        </label>

        <div className='flex flex-col lg:flex-row gap-2 items-center'>
          <input
            className='w-full lg:w-2/3 shrink px-2 py-2 rounded-md resize-none text-xs dark:bg-neutral-900 dark:text-neutral-200'
            type='number'
            min='0'
            max='9999'
            step='1'
            id='priceInDollars'
            value={localPrice}
            onChange={handlePriceChange}
          />
          <span className='w-max shrink-0'>
            {`(${+localPrice * 10} credits)`}
          </span>
        </div>

        <fieldset className='flex flex-col lg:flex-row gap-2'>
          <legend className='publish-legend'>Frequency</legend>

          <div className='flex gap-2'>
            <input
              type='radio'
              id='oneTimePrice'
              name='pricing-frequency'
              value='one-time'
              checked={priceFrequency === 'one-time'}
              onChange={handleRadioClick}
            />

            <label htmlFor='pricing-frequency'>One time</label>
          </div>

          <div className='flex gap-2'>
            <input
              type='radio'
              id='monthlyPrice'
              name='pricing-frequency'
              value='monthly'
              checked={priceFrequency === 'monthly'}
              onChange={handleRadioClick}
            />

            <label htmlFor='pricing-frequency'>Monthly</label>
          </div>
        </fieldset>

        {isPublished ? (
          <button
            className='w-full bg-blue-500 hover:bg-blue-700 text-white font-thin py-2 px-4 rounded-xl flex items-center justify-center text-xs'
            type='submit'
          >
            update price
          </button>
        ) : null}

        <p className='text-sm'>{statusMessage}</p>
      </form>
    </>
  );
};

export default PublishForm;
