import React, { useMemo, useEffect, useContext } from 'react';
import NavigationItem from './NavigationItem';
import { NAVIGATION_DEMO_2 } from '../../data/navigation';
import { useAuthHeader, useAuthUser } from 'react-auth-kit';
import axios from 'axios';
import { useCredit } from '../../context/CreditContext';
import HeaderContext from '../../context/HeaderContext';
import { backendURL } from '../../constants/environmental';

function Navigation() {
  // const user = useContext(AuthContext)
  const { setReferAFriendModalOpen } = useContext(HeaderContext);
  const { credit, setCredit } = useCredit();
  const authHeader = useAuthHeader()();
  const user = useAuthUser()();
  const userId = user?.id;
  // const location = useLocation();
  const navigationItems = useMemo(() => {
    return NAVIGATION_DEMO_2({
      uid: userId,
      onReferAFriendClick: () => setReferAFriendModalOpen(true),
      credit,
      profileImage: user?.profileImage,
    });
  }, [user, credit]);

  // const [refreshKey, setRefreshKey] = useState(0);
  // check credits
  //const [userCredit, setCredits] =  useState<number>(0);

  // console.log(
  //   'auth cookie (navigation component):',
  //   token,
  //   'authuser:',
  //   auth()
  // );
  //console.log('navigation token:', token);
  // get credits on mount incase of refresh or already logged in when entering site
  const fetchCredits = async () => {
    if (!authHeader) return;

    try {
      const response = await axios.post(
        `${backendURL}/user/checkCredits`,
        {},
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );

      if (response.status === 200) {
        const dollarsToCredits = response.data * 10;
        setCredit(dollarsToCredits);
        // localStorage.setItem('credit', dollarsToCredits.toString()); // if you need this
      }
    } catch (error) {
      // just means user wasn't logged in on page refresh which is fine
    }
  };

  useEffect(() => {
    if (!authHeader) return;
    fetchCredits();
  }, []); // The empty array means this useEffect runs once when the component mounts

  /*
  useEffect(() => {
    let currentToken = cookies._auth;

    async function updateUserCredits() {


      // change if localstorage is defineds
      if (localStorage.getItem("credit")) {
        currentToken = localStorage.getItem("credit");
        setCredits(currentToken);
      } else setCredits(0);
    }


    const handleStorageChange = () => {
      updateUserCredits();
    };
    
    window.addEventListener('storage', handleStorageChange); // any storage updates will cause credits to update
    // just add a time stamp to local storage to update the credits e.g.
    // localStorage.setItem('triggerUpdate', Date.now().toString());

    
    updateUserCredits();

    return () => { 
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [location.pathname, cookies._auth]);
*/

  return (
    <ul className='nc-Navigation flex w-full items-center space-x-1 relative'>
      {navigationItems.map((item, index) => (
        <NavigationItem
          // className={item.className}
          className={item.className}
          key={item.id}
          onClick={item.onClick}
          menuItem={item}
          uid={user?.id}
        />
      ))}
    </ul>
  );
}

export default Navigation;
