import React, { useContext, useState } from 'react';
import { BiRun } from 'react-icons/bi';
import { FaPause, FaPlay } from 'react-icons/fa';
import { MdFullscreen, MdFullscreenExit } from 'react-icons/md';
import FuserContext from '../../context/FuserContext';
import { TbLayoutSidebarRightExpand } from 'react-icons/tb';
import Toggle from '../../components/Toggle';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { useAuthHeader, useAuthUser } from 'react-auth-kit';
import { iconStyle, menuButtonStyles } from '../../constants/styles';
import { backendURL } from '../../constants/environmental';

const FuserMainOptions = () => {
  const {
    blocks,
    runnerMode,
    pauseRunnerMode,
    restartRunnerMode,
    isFullScreen,
    toggleFullScreen,
    stillRunning,
    isOwner,
    authorId,
    resumeRunnerMode,
    onRunnerModeClick,
    runnerIndex,
    blockRefs,
    isSidebarOpen,
    toggleSidebar,
    minimalMode,
    setMinimalMode,
    restartQueued,
    setBlockScrollingIntoView,
  } = useContext(FuserContext);

  // const currentBlockType = blocks?.[runnerIndex]?.type?.split('-')?.[1];

  // const currentBlockMessage =
  //   currentBlockType === 'question'
  //     ? 'Please answer the question'
  //     : currentBlockType === 'prompt'
  //       ? 'Talking to AI'
  //       : currentBlockType === 'image'
  //         ? 'Generating image'
  //         : currentBlockType === 'textToSpeech'
  //           ? 'Generating audio'
  //           : currentBlockType === 'download'
  //             ? 'Your download is ready'
  //             : currentBlockType === 'google'
  //               ? 'Searching Google'
  //               : currentBlockType === 'scraper'
  //                 ? 'Scraping websites'
  //                 : 'You are';

  // const isSmallScreen = window.matchMedia('(pointer: coarse)').matches;
  const authHeader = useAuthHeader()();
  const user = useAuthUser()();
  const userId = user?.id;
  const [confirmRestartShowing, setConfirmRestartShowing] = useState(false);

  const [cookies, setCookie] = useCookies(['_auth_state']);

  return (
    <div
      className={`flex ${
        runnerMode ? 'justify-between' : 'justify-end'
      } gap-2 items-center mt-2 p-2 py-4 dark:bg-neutral-800 rounded-lg shadow-xl text-xs`}
    >
      {runnerMode && (
        <label className='flex gap-3 w-fit items-center'>
          <Toggle
            className='cursor-pointer'
            toggled={minimalMode}
            onChange={() => {
              const newMinimalMode = !minimalMode;
              setMinimalMode((mode: boolean) => !mode);
              // setCookie(
              //   '_auth_state',
              //   {
              //     ...cookies._auth_state,
              //     minimalMode: newMinimalMode ? 'true' : 'false',
              //   },
              //   { path: '/' }
              // );
              axios.patch(
                `${backendURL}/user/details/${userId}`,
                {
                  minimalMode: newMinimalMode,
                },
                {
                  headers: { Authorization: authHeader },
                }
              );
            }}
          />
          <span>Minimal mode</span>
        </label>
      )}
      <div className='flex items-center justify-end gap-2'>
        {/*<span className="w-full text-xs">
          Mode: {runnerMode ? 'Runner' : 'Fuser'}
        </span>*/}
        {/*
        <select
        value={themes.indexOf(selectedTheme)}
        onChange={(e) => handleThemeChange(parseInt(e.target.value))}
        className="bg-gray-200 rounded-lg p-2 text-xs w-10 h-4"
        >
          {themes.map((theme, index) => (
            <option key={theme.name} value={index}>
              {theme.name}
            </option>
          ))}
        </select>
        */}
        {runnerMode && Number.isInteger(runnerIndex) && runnerIndex >= 0 && (
          <p className='text-lg'>
            {runnerIndex < blocks.length ? (
              <>
                {/* {currentBlockMessage + ' on '} */}
                <a
                  className='cursor-pointer underline'
                  onClick={() => {
                    blockRefs.current[runnerIndex].scrollIntoView({
                      block: 'nearest',
                      behavior: 'smooth',
                    });
                    setBlockScrollingIntoView(true);
                  }}
                >
                  Block {runnerIndex}
                </a>{' '}
                / {blocks.length}
              </>
            ) : (
              'Finished running 🎉🥳🎊'
            )}
          </p>
        )}

        {runnerMode && confirmRestartShowing && (
          <div className='flex flex-col sm:flex-row items-center gap-0 sm:gap-2'>
            <p className='text-center'>Confirm restart</p>
            <div className='flex gap-2 justify-center'>
              <button
                className={
                  menuButtonStyles +
                  ' h-8 w-8 sm:h-10 sm:w-10 p-0 sm:p-1 text-2xl font-bold'
                }
                onClick={() => {
                  restartRunnerMode();
                  setConfirmRestartShowing(false);
                }}
              >
                ✔
              </button>
              <button
                className={
                  menuButtonStyles +
                  ' h-8 w-8 sm:h-10 sm:w-10 text-center align-middle p-0 sm:p-1 text-lg'
                }
                onClick={() => {
                  setConfirmRestartShowing(false);
                }}
              >
                ❌
              </button>
            </div>
          </div>
        )}
        {runnerMode && !confirmRestartShowing && (
          <button
            className={menuButtonStyles + ' flex items-center h-10'}
            onClick={() => setConfirmRestartShowing(true)}
          >
            {restartQueued ? (
              <div className='mb-1/2'>Restarting...</div>
            ) : (
              <svg
                width='25'
                height='25'
                viewBox='0 0 70 70'
                preserveAspectRatio='xMidYMid meet'
              >
                <path
                  data-name='layer1'
                  d='M3.307 22.023a3 3 0 0 1 4.17.784l2.476 3.622A27.067 27.067 0 0 1 36 6c14.557 0 26 12.036 26 26.584a26.395 26.395 0 0 1-45.066 18.678 3 3 0 1 1 4.244-4.242A20.395 20.395 0 0 0 56 32.584C56 21.344 47.248 12 36 12a21.045 21.045 0 0 0-20.257 16.059l4.314-3.968a3 3 0 0 1 4.062 4.418l-9.737 8.952c-.013.013-.03.02-.043.033-.067.06-.143.11-.215.163a2.751 2.751 0 0 1-.243.17c-.076.046-.159.082-.24.12a3.023 3.023 0 0 1-.279.123c-.08.03-.163.05-.246.071a3.045 3.045 0 0 1-.323.07c-.034.006-.065.017-.1.022-.051.006-.102-.002-.154.002-.063.004-.124.017-.187.017-.07 0-.141-.007-.212-.012l-.08-.004-.05-.003c-.06-.007-.118-.03-.178-.04a3.119 3.119 0 0 1-.388-.087c-.083-.027-.16-.064-.239-.097a2.899 2.899 0 0 1-.314-.146 2.753 2.753 0 0 1-.233-.151 2.807 2.807 0 0 1-.262-.2 2.857 2.857 0 0 1-.2-.19 3.013 3.013 0 0 1-.224-.262c-.03-.04-.069-.073-.097-.114L2.523 26.194a3.001 3.001 0 0 1 .784-4.17z'
                  fill='#202020'
                ></path>
              </svg>
            )}
          </button>
        )}

        {runnerMode &&
          !confirmRestartShowing &&
          (stillRunning || restartQueued ? (
            <button
              className={menuButtonStyles}
              onClick={pauseRunnerMode}
            >
              <FaPause className={iconStyle} />
            </button>
          ) : (
            <button
              className={
                menuButtonStyles + ' flex items-center gap-1 animate-toms-glow'
              }
              onClick={resumeRunnerMode}
            >
              (resume)
              <FaPlay
                className={iconStyle}
                style={{ display: 'inline' }}
              />
            </button>
          ))}
        {/*{runnerMode || (
          <>
      
            <button
              className={menuButtonStyles}
              onClick={undoChanges}
            >
              <FaUndo className={iconStyle} />
            </button>
            <button
              className={menuButtonStyles}
              onClick={redoChanges}
            >
              <FaRedo className={iconStyle} />
            </button>
          </>
        )}*/}
        <button
          className={menuButtonStyles}
          onClick={toggleFullScreen}
        >
          {isFullScreen ? (
            <MdFullscreenExit className={iconStyle} />
          ) : (
            <MdFullscreen className={iconStyle} />
          )}
        </button>
        {
          //show runner mode toggle if the block has not been saved, ie no authorId
          (isOwner || !authorId) && (
            <button
              className={menuButtonStyles}
              onClick={onRunnerModeClick}
            >
              <BiRun className={iconStyle} />
            </button>
          )
        }
        {runnerMode && !isSidebarOpen && (
          <button
            className={menuButtonStyles + ' hidden sm:block'}
            onClick={toggleSidebar}
          >
            <TbLayoutSidebarRightExpand className={iconStyle} />
          </button>
        )}
        {(isOwner || !authorId) && (
          <a
            href='/maker-help'
            target='_blank'
            style={{ color: 'darkblue', textDecoration: 'underline' }}
          >
            help
          </a>
        )}
      </div>
    </div>
  );
};

export default FuserMainOptions;
