import axios from 'axios';
import React, { useState } from 'react';
import { backendURL } from '../constants/environmental';
import { useAuthHeader } from 'react-auth-kit';
import MyToolTips from './MyTooltip';

const ShareToolSection = ({ toolId }: { toolId: string }) => {
  const authHeader = useAuthHeader()();
  const [shareStatusMessage, setShareStatusMessage] = useState<string>();
  const [waitingForShareResponse, setWaitingForShareResponse] =
    useState<boolean>(false);

  return (
    <div className='w-full gap-2 bg-blue-200 dark:bg-neutral-800 p-2 flex flex-col items-center justify-between rounded-lg shadow-xl'>
      <div>
        Share tool{' '}
        <MyToolTips
          content="<p>Share your tool with any email address to let other people run or edit your tool</p>
          <p>You can share unpublished tools, and you can share with any email, even if it is not registered with a Skillfusion account</p>
          <p>Once you click Share, an email will be sent containing a link to the tool</p>
          <p>If the email is not registered with a Skillfusion account, then access will automatically given once their account is verified</p>
          <p>If you would like to revoke an email's edit permission, set the permission to run only and click 'Share'"
          tipID='block-types'
          datatooltipplace='left'
        />
      </div>
      <form
        className='w-full flex flex-col gap-2 items-center'
        onSubmit={handleFormSubmit}
      >
        <label className='w-full'>
          Email address:
          <input
            className='w-full'
            name='email'
            type='email'
          />
        </label>
        <label className='w-full flex items-center gap-2'>
          Permission:
          <select
            className='w-full'
            name='permission'
          >
            <option value='run'>Run only</option>
            <option value='editAndRun'>Edit and run</option>
          </select>
        </label>
        <button
          type='submit'
          className='justify-self-center md:justify-self-end bg-blue-100 border-2 p-2 rounded-lg hover:bg-white border border-black border-1 rounded-lg p-1 px-2'
        >
          {waitingForShareResponse ? 'Sharing...' : 'Share'}
        </button>
        {shareStatusMessage && <p>{shareStatusMessage}</p>}
      </form>
    </div>
  );

  function handleFormSubmit(e: any) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const requestBody = Object.fromEntries(formData.entries());
    setWaitingForShareResponse(true);
    axios
      .post(`${backendURL}/blocks/${toolId}/share`, requestBody, {
        headers: { Authorization: authHeader },
      })
      .then(response => {
        console.log(response);
        switch (response.status) {
          case 201:
            setShareStatusMessage('Tool shared!');
            break;
          case 200:
            setShareStatusMessage('Permission updated');
            break;
        }
      })
      .catch(error => {
        console.log(error);
        switch (error.response.status) {
          case 400:
            setShareStatusMessage('Invalid email address');
            break;
          case 403:
            setShareStatusMessage(
              "You don't have permission to share this tool"
            );
            break;
          case 409:
            setShareStatusMessage(
              'You have already shared the tool with this email'
            );
            break;
          default:
            setShareStatusMessage('There was an error sharing the tool');
            break;
        }
      })
      .finally(() => {
        setWaitingForShareResponse(false);
      });
  }
};

export default ShareToolSection;
