import React, {
  FC,
  useState,
  useEffect,
  useContext,
  ChangeEvent,
  useRef,
  useCallback,
} from 'react';
import ReactGA from 'react-ga4';
import FuserLoader from '../../containers/FuserPage/FuserLoader';
import BlockProps from '../../models/BlockProps';
import '../../index.css';
import { useCredit } from '../../context/CreditContext';
import FuserContext from '../../context/FuserContext';
import Block from '../../models/Block';
import GPTParameterSection from '../../containers/FuserPage/GPTParameterSection';
import useBlockRunner from '../../hooks/useBlockRunner';
import useCancellablePause from '../../hooks/useCancellablePause';
import axios from 'axios';
import TextareaAutosize from 'react-textarea-autosize';
import AutocompleteTextarea from '../../containers/FuserPage/AutocompleteTextarea';
import { useAuthHeader, useAuthUser } from 'react-auth-kit';
import { getBrowser, getOperatingSystem } from '../../utils/userDeviceInfo';
import { randomNumberBetween } from '../../utils/math';
import MyToolTips from '../MyTooltip';
import { testButtonStyles } from '../../constants/styles';
import { backendURL } from '../../constants/environmental';
import { updateAtIndex, updateAtIndexRun } from '../../utils/array';
import { truncateAfter } from '../../utils/string';
import { MAX_PREVIEW_CHARS } from '../../constants/blocks';

interface gptParamsType {
  prompt?: string | string[];
  temporaryExtraInfo?: string;
  model?: string;
  temperature?: number;
  topP?: number;
  frequencyPenalty?: number;
  presencePenalty?: number;
  maximumWordsInResponse?: number;
}

const MAX_WORDS_FOR_MODEL: Record<string, string> = {
  turbo: '1500',
  gpt4: '1000',
  llama2: '750',
};

const defaultInputValues: any = {
  presencePenalty: 0.6,
  frequencyPenalty: 0.15,
  topP: 1,
  temperature: 0.9,
  maximumWordsInResponse: {
    turbo: 800,
    gpt4: 600,
    llama2: 400,
  },
};

const ChatBlock: FC<BlockProps> = ({
  isLoading,
  setIsLoading,
  index,
  block,
  handleChange,
  collapsed,
  isShared,
}) => {
  const {
    blockStyles,
    toolId,
    replacePlaceholders,
    blocks,
    setBlocks,
    setActivityLog,
    runnerMode,
    runnerIndex,
    setRunnerIndex,
    setStillRunning,
    blockCancellationState,
    setBlockCancellationState,
    cancellingBlocksMemo,
    saveRunnerModeBlocksData,
    restartQueued,
    anyBlocksLoading,
    blockScrollingIntoView,
    setBlockScrollingIntoView,
    // runnerIndexHistory,
  } = useContext(FuserContext);

  const authHeader = useAuthHeader()();
  const user = useAuthUser()();
  const userId = user?.id;

  const { pause, cancelPause } = useCancellablePause();

  useEffect(() => {
    setErrorMessage('');
  }, [runnerMode]);

  async function handleAxiosError(axiosError: any) {
    //console.log('Handling axios error');
    const response = axiosError?.response;
    const status = response?.status;
    const statusText = response?.statusText;
    const message = response?.data?.error?.message;

    //console.log('error object recieved:', errorObj);

    if (status === 400) {
      ReactGA.event('bad_openai_request');
      throw new Error(
        response?.error ||
          'Error: Bad request to OpenAI API. Try reducing the prompt/input size.'
      );
    } else if (status === 401) {
      ReactGA.event('unauthorized_openai_call');
      throw new Error(
        'Error: Unauthorized request. Please check that you are logged in.'
      );
    } else if (status === 402) {
      throw new Error('Error: You have insufficient credit.');
    } else if (
      status === 429 &&
      statusText?.startsWith('You exceeded your current quota')
    ) {
      ReactGA.event('monthly_openai_limit_reached');
      throw new Error('Error: Monthly limit reached');
    } else {
      //      ReactGA.event('express_error_' + status);
      if (status && message)
        throw new Error(
          `Error: Status ${status} ${message ? ` - ${message}` : ''}`
        );
      else {
        // unknown error, log to database
        try {
          const errorDetails = {
            error: axiosError,
            toolId,
            runnerIndex,
            blocks,
            browser: getBrowser(),
            operatingSystem: getOperatingSystem(),
          };
          const postResponse = await axios.post(`${backendURL}/errorDetails`, {
            errorDetails,
          });
        } catch (e) {
          console.log('Unknown error:', e);
        }
        throw new Error(
          'An unknown error occurred, please contact support if the error persists.'
        );
      }
      //console.error('Error from Backend API:', axiosError, axiosError?.response?.data);
    }
  }

  async function gpt({
    prompt = ['tell me that I forgot the prompt'],
    temporaryExtraInfo,
    model = 'turbo',
    temperature = defaultInputValues.temperature,
    topP = defaultInputValues.topP,
    frequencyPenalty = defaultInputValues.frequencyPenalty,
    presencePenalty = defaultInputValues.presencePenalty,
    maximumWordsInResponse = defaultInputValues.maximumWordsInResponse['turbo'],
  }: gptParamsType) {
    if (!Array.isArray(prompt)) prompt = [prompt];

    let response;
    const MAX_RETRIES = 5;
    const MAX_RESPONSE_WAIT_TIME_IN_SECONDS = 120;
    const INITIAL_PAUSE_DURATION_IN_MS = randomNumberBetween(5000, 10000);

    let retriesRemaining = MAX_RETRIES;
    let updatedCredit = null;
    let pauseDuration = INITIAL_PAUSE_DURATION_IN_MS;

    for (;;) {
      //console.log('promptBatch', promptBatch);
      if (retriesRemaining === 0) {
        ReactGA.event('max_retries');
        throw new Error(
          `${MAX_RETRIES} consecutive failed requests from OpenAI, please try again later.`
        );
      }
      try {
        const promptResponse: any = await axios.post(
          `${backendURL}/openai/prompt`,
          {
            userId,
            toolId,
            prompts: prompt,
            model,
            temperature,
            topP,
            frequencyPenalty,
            presencePenalty,
            maximumWordsInResponse,
            trimPromptIfTooLarge: true,
            temporaryExtraInfo,
            stopSequences: ['Human:', 'AI:', 'System:'],
          },
          {
            headers: {
              Authorization: authHeader,
            },
          }
        );

        if (promptResponse?.data?.message === 'cancelled')
          return promptResponse;

        if (promptResponse?.data?.message === 'timed-out') {
          console.log('response timed out, retrying');
          retriesRemaining--;
          continue;
        }

        updatedCredit = promptResponse.data.updatedCredit;

        // Handle the new results and updated credit balance as needed
        response = promptResponse.data.results;
        break;
      } catch (error) {
        // console.log(error);
        const code = error?.code;
        const status = error?.response?.status;
        const statusText =
          error?.response?.statusText || error?.response?.statusMessage;
        // console.log('gpt error:', error.response);
        if (
          [502, 503].includes(status) ||
          (status === 429 && statusText?.startsWith('Rate limit reached')) ||
          statusText?.startsWith('Too Many Requests') ||
          (status === 500 &&
            statusText?.startsWith('The server had an error')) ||
          code === 'ERR_NETWORK'
        ) {
          if (status === 429) {
            ReactGA.event('token_limit_retry');
          }
          if (status === 500) ReactGA.event('openai_server_error_retry');
          if (status === 502) ReactGA.event('502_error_retry');
          if (status === 503) ReactGA.event('openai_server_overloaded_retry');
          if (code === 'ERR_NETWORK') ReactGA.event('network_error');

          console.log('pausing for', pauseDuration, 'ms');
          await pause(pauseDuration);
          pauseDuration = Math.min(
            MAX_RESPONSE_WAIT_TIME_IN_SECONDS * 1000,
            pauseDuration * randomNumberBetween(1.5, 1.7)
          );
          retriesRemaining--;
          continue;
        } else {
          if (error?.response?.data?.code === 'content_policy_violation') {
            const modelProvider = gptModel === 'llama2' ? 'Meta' : 'OpenAI';
            throw new Error(
              `Your request was rejected as a result of ${modelProvider}'s safety system. Your prompt may contain text that is not allowed by ${modelProvider}'s safety system`
            );
          }
          await handleAxiosError(error);
        }
      }
    }
    return { response, updatedCredit };
  }

  const updateBlocks = () =>
    setBlocks((blocks: Block[]) => updateAtIndex(index, block, blocks));

  const { credit, updateCredits } = useCredit();

  useEffect(() => {
    if (block.data.inputToProcess === undefined) {
      block.data.inputToProcess = '';
    }
    if (block.data.gptModel === undefined) {
      block.data.gptModel = 'turbo';
    }
    if (block.data.showOutputToUser === undefined) {
      block.data.showOutputToUser = true;
    }
    if (block.data.temperature === undefined) {
      block.data.temperature = defaultInputValues.temperature;
    }
    if (block.data.topP === undefined) {
      block.data.topP = defaultInputValues.topP;
    }
    if (block.data.frequencyPenalty === undefined) {
      block.data.frequencyPenalty = defaultInputValues.frequencyPenalty;
    }
    if (block.data.presencePenalty === undefined) {
      block.data.presencePenalty = defaultInputValues.presencePenalty;
    }
    if (
      block.data.gptModel &&
      typeof block.data.gptModel === 'string' &&
      block.data.maximumWordsInResponse === undefined
    ) {
      block.data.maximumWordsInResponse =
        defaultInputValues.maximumWordsInResponse[block.data.gptModel];
    }
    if (block.data.editableOutput === undefined) {
      block.data.editableOutput = false;
    }
    if (!chatHasStartedInThisSession && !block.data.inputToProcess) {
      // block.data.inputToProcess = 'AI: Hi, please write me a message.';
    }
    updateBlocks();
  }, []);

  const [responseDescription, setResponseDescription] = useState('');

  /* the regular autosave only triggers after the next block runs, this
     is for triggering autosaves after the AI has written a response and is
     waiting for the next input message, or when the chat history is cleared */
  const [blockDataAwaitingSave, setBlockDataAwaitingSave] =
    useState<boolean>(false);

  const onNextMessageReceived = async () => {
    setResponseDescription('');
    const currentBlock = blocks[index];

    const {
      temporaryExtraInfo,
      inputToProcess,
      response: userInput,
      dontSendToOpenAI,
      nextMessageStartIndex,
      nextMessageEndIndex,
      // permanentExtraInfo,
    } = currentBlock.data;

    let chatHistory = inputToProcess ?? '';

    if (!chatHistory && !userInput) {
      return;
    }

    if (runnerMode && dontSendToOpenAI && inputToProcess) {
      if (!currentBlock.data.inputToProcess.endsWith('\n\n')) {
        currentBlock.data.inputToProcess += '\n\n';
      }
      setBlocks(blocks);
      setBlockDataAwaitingSave(true);
      return;
    }
    // console.log(`running block ${index}`);
    setIsLoading(true); // start loading
    setErrorMessage('');

    // console.log({
    //   nextMessageEndIndex,
    //   chatHistoryLength: inputToProcess.length,
    // });

    const permanentExtraInfoAdded =
      inputToProcess.length - 1 !== nextMessageEndIndex;

    const previousMessages = chatHistory.slice(0, nextMessageStartIndex);
    let nextMessage = chatHistory.slice(nextMessageStartIndex);

    if (temporaryExtraInfo) {
      const systemPrompt = temporaryExtraInfo + '\n\n';
      nextMessage = systemPrompt + nextMessage;
    }

    const prompt = previousMessages + nextMessage + '\nAI:';
    // prompt += 'Human: ';

    // if (permanentExtraInfo) {
    //   prompt += permanentExtraInfo;
    //   chatHistory += permanentExtraInfo;
    // }

    // prompt += `${userInput ?? ''}\n\nAI: `;

    try {
      console.log('awaiting gpt', { chatHistory, prompt, temporaryExtraInfo });
      const gptResponse: any = await gpt({
        prompt: prompt,
        temporaryExtraInfo,
        model: block.data.gptModel,
        temperature: +block.data.temperature,
        topP: +block.data.topP,
        frequencyPenalty: +block.data.frequencyPenalty,
        presencePenalty: +block.data.presencePenalty,
        maximumWordsInResponse: +block.data.maximumWordsInResponse,
      });

      const updatedCredit =
        gptResponse?.data?.updatedCredit ?? gptResponse?.updatedCredit;

      if (updatedCredit != null) updateCredits(updatedCredit * 10);

      if (gptResponse?.data?.message === 'cancelled') {
        console.log('Cancelled prompt at block', index);
        return;
        //return 'CANCEL';
      }

      const promptResponse = gptResponse.response;
      if (!chatHistory.endsWith('\n\n')) {
        chatHistory += '\n\n';
      }
      if (!permanentExtraInfoAdded) {
        chatHistory += 'AI: ';
      }
      chatHistory += promptResponse + '\n\n';

      currentBlock.data.inputToProcess = chatHistory;
      currentBlock.data.temporaryExtraInfo = undefined;
      setBlocks(blocks);

      setBlockDataAwaitingSave(true);
    } catch (error) {
      // console.log('Error generating prompt', error);
      const errorObj = error as any;
      setErrorMessage(errorObj.message ?? 'Error with GPT response');
      setStillRunning(false);
      return;
      //return 'CANCEL';
    } finally {
      console.log('setting isloading false for block', index);
      setIsLoading(false);
    }
    setActivityLog((prevLog: string[]) => [
      ...prevLog,
      `Saved chat block at index: ${index}`,
    ]);
  };

  const chatHistoryTextareaRef = useRef<any>();

  const focusElementIfReady = (element: any) => {
    if (
      runnerMode &&
      runnerIndex === index &&
      element &&
      !isLoading &&
      !restartQueued
    ) {
      element.focus();
    }
  };

  const userInputRef = useRef<any>();
  const userInputCallbackRef = useCallback(
    (element: any) => {
      if (!element) {
        return;
      }
      userInputRef.current = element;
      focusElementIfReady(element);
    },
    // needs dependencies otherwise the initial value of focusElementIfReady is always used
    [runnerMode, runnerIndex, index, isLoading, restartQueued]
  );

  useEffect(() => {
    if (userInputRef.current) {
      focusElementIfReady(userInputRef.current);
    }
  }, [runnerMode, runnerIndex, index, isLoading, restartQueued]);

  useEffect(() => {
    if (
      blockScrollingIntoView &&
      runnerMode &&
      runnerIndex === index &&
      userInputRef.current &&
      !isLoading
    ) {
      // view jumps to focused elements without smooth scrolling if there's no delay
      setTimeout(() => userInputRef.current.focus(), 500);
    }
    setBlockScrollingIntoView(false);
  }, [blockScrollingIntoView]);

  const scrollToBottom = (element: any) => {
    element.scrollTop = element.scrollHeight;
  };

  useEffect(() => {
    if (chatHistoryTextareaRef.current) {
      scrollToBottom(chatHistoryTextareaRef.current);
    }
  }, [block.data.inputToProcess]);

  const clearChatHistory = () => {
    block.data.inputToProcess = '';
    block.data.processedInput = undefined;
    block.data.temporaryExtraInfo = undefined;
    block.data.dontSendToOpenAI = false;
    updateBlocks();
    setBlockDataAwaitingSave(true);
  };

  useEffect(() => {
    if (!blockDataAwaitingSave) {
      return;
    }

    saveRunnerModeBlocksData();

    setBlockDataAwaitingSave(false);
  }, [blockDataAwaitingSave]);

  const onSendMessage = () => {
    // block.data.temporaryExtraInfo = '';
    // block.data.permanentExtraInfo = '';
    if (
      block.data.inputToProcess &&
      !block.data.inputToProcess.endsWith('\n\n')
    ) {
      block.data.inputToProcess += '\n\n';
    }
    block.data.response = block.data.inputMessage;
    block.data.nextMessageStartIndex = block.data.inputToProcess.length;
    block.data.inputToProcess += `Human: ${block.data.inputMessage ?? ''}\n\n`;
    block.data.nextMessageEndIndex = block.data.inputToProcess.length - 1;
    block.data.inputMessage = '';

    setChatHasStartedInThisSession(true);
    setBlocks(updateAtIndexRun(index, block, blocks));
    // setResponseDescription('Message queued.');
  };

  const cancelPrompt = async () => {
    if (isLoading) {
      console.log('cancelling prompt for block', index);
      // cancel the pause if the block is waiting for the next retry
      cancelPause();
      const cancelResponse = await axios.get(
        `${backendURL}/openai/cancelPrompt`,
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );
      console.log('cancelPrompt response:', cancelResponse);
      console.log('setting isloading false for block', index);
      setIsLoading(false);
    }
  };

  const onCancelClick = async () => {
    cancelPrompt();
    setStillRunning(false);
  };

  const onOtherBlockCancelRequest = async () => {
    const { idOfBlockToRunAfterwards } = blockCancellationState;

    if (block.id !== idOfBlockToRunAfterwards) {
      await cancelPrompt();
      console.log('removing block', index, 'from ids to cancel');
      setBlockCancellationState((previousState: any) => {
        return {
          ...previousState,
          idsOfBlocksToCancel: previousState.idsOfBlocksToCancel.filter(
            (id: string) => id !== block.id
          ),
        };
      });
    }
  };

  useEffect(() => {
    if (cancellingBlocksMemo) onOtherBlockCancelRequest();
  }, [cancellingBlocksMemo]);

  const [chatHasStartedInThisSession, setChatHasStartedInThisSession] =
    useState<boolean>(false);

  useEffect(() => {
    setChatHasStartedInThisSession(!runnerMode ? false : runnerIndex !== index);
  }, [runnerMode]);

  useEffect(() => {
    if (!restartQueued) {
      return;
    }
    clearChatHistory();
    setChatHasStartedInThisSession(false);
  }, [restartQueued]);

  /* if this was the last block to save in runner mode, make sure it doesnt
     run again on the next load */
  useBlockRunner(() => {
    /* the last item of runnerIndexHistory should really be the current block
       index but I don't think it's updating in time here, it 
       seems to be the index of the last block that ran */
    // if (runnerIndexHistory.at(-1) < index) {
    //   clearChatHistory();
    // }
    /* else */ if (chatHasStartedInThisSession) {
      onNextMessageReceived();
    }
    const currentBlock = blocks[index];
    if (
      currentBlock.data.inputToProcess &&
      !currentBlock.data.inputToProcess.endsWith('\n\n')
    ) {
      currentBlock.data.inputToProcess += '\n\n';
    }
    currentBlock.data.dontSendToOpenAI = false;
    setBlocks(blocks);
  }, index);

  useEffect(() => {
    if (isShared) return;
    const { cancellingBlocks, idsOfBlocksToCancel, idOfBlockToRunAfterwards } =
      blockCancellationState;

    if (cancellingBlocks) {
      const indexOfBlockToRunNext = blocks.findIndex(
        ({ id }: any) => id === idOfBlockToRunAfterwards
      );
      if (idsOfBlocksToCancel.length === 0 && index === indexOfBlockToRunNext) {
        // runs after all other blocks have cancelled
        console.log('restarting block', indexOfBlockToRunNext);
        setRunnerIndex(indexOfBlockToRunNext);
        setStillRunning(true);
        setBlockCancellationState({
          cancellingBlocks: false,
          idsOfBlocksToCancel: [],
          idOfBlockToRunAfterwards: undefined,
        });
      }
    }
  }, [blockCancellationState]);

  // const onRestartPrompt = async () => {
  //   const idsOfBlocksToCancel = blocks
  //     .filter((block: any, blockIndex: number) => {
  //       return block.blocktype === 'prompt' && blockIndex !== index;
  //     })
  //     .map((block: any) => block.id);

  //   setBlockCancellationState({
  //     cancellingBlocks: true,
  //     idsOfBlocksToCancel,
  //     idOfBlockToRunAfterwards: block.id,
  //   });
  // };

  const sendMessageIfEnterPressed = (e: any) => {
    if (e.shiftKey || e.ctrlKey || e.altKey || e.metaKey) {
      return;
    }
    if (e.key === 'Enter') {
      onSendMessage();
      e.target.blur();
    }
  };

  const [errorMessage, setErrorMessage] = useState<string>('');

  const {
    response,
    blockHeading,
    inputMessage,
    inputToProcess,
    message,
    gptModel,
    showOutputToUser,
    temporaryExtraInfo,
    temperature,
    topP,
    frequencyPenalty,
    presencePenalty,
    maximumWordsInResponse,
    // permanentExtraInfo,
  } = block.data;

  const handleModelChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value: newModel } = e.target;
    block.data.maximumWordsInResponse =
      defaultInputValues.maximumWordsInResponse[newModel];
    updateBlocks();
    handleChange(e);
  };

  const processedMessage = replacePlaceholders(message, blocks);

  const [disableUserInput, setDisableUserInput] = useState<boolean>(false);

  useEffect(() => {
    if (!runnerMode) {
      setDisableUserInput(false);
      return;
    }

    if (anyBlocksLoading) {
      setDisableUserInput(true);
      return;
    }

    const enableUserInputTimeoutId = setTimeout(() => {
      setDisableUserInput(false);
    }, 200);

    return () => clearTimeout(enableUserInputTimeoutId);
  }, [anyBlocksLoading, runnerMode]);

  if (isShared) {
    return (
      <>
        <div>{truncateAfter(MAX_PREVIEW_CHARS, blockHeading || 'Chat')}</div>
        <TextareaAutosize
          className='resize-y min-h-[8rem] max-h-96 bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner overflow-y-scroll w-full'
          readOnly
          value={inputToProcess || ''}
        />
      </>
    );
  }

  if (collapsed) {
    if (errorMessage)
      return (
        <>
          <div>GPT Error</div>
          <div>{truncateAfter(MAX_PREVIEW_CHARS, errorMessage.toString())}</div>
        </>
      );
    if (response?.length > 0 && showOutputToUser)
      return (
        <>
          <div>
            {truncateAfter(
              MAX_PREVIEW_CHARS,
              message ? processedMessage : 'GPT response'
            )}
          </div>
          <div>{truncateAfter(MAX_PREVIEW_CHARS, response.toString())}</div>
        </>
      );
    else return <div>Chat block</div>;
  }

  return (
    <FuserLoader
      name='Chat Block'
      loading={isLoading}
      message={`${message ? processedMessage + ' ' : ''}(Processing prompt)`}
      onCancelClick={onCancelClick}
    >
      <div
        className={blockStyles}
        key={index}
      >
        <>
          {!runnerMode && (
            <label className='text-xs'>
              Chat history:{' '}
              <MyToolTips
                content="<p>Messages from the user will start with <q>Human:</q> and messages from chatGPT will start with <q>AI:</q>.</p>
<p>Once an input message from the user has been queued, you can add extra info before the message using a processing block (under the modify references menu).</p>
<p>The extra info can be hidden or displayed in the chat. You can generate different extra info depending on the nature of the user's input by using an embeddings block and if-else blocks.</p>
<p>The message along with the extra info and chat history will be sent to chatGPT when the chat block runs again. You can use a jump block to run the block again in runner mode.</p>
<p>The chat history will clear if the last block to run before this has a lower block number.</p>
<p>We recommend adding <q>Optional heading for user</q> to improve the user experience. They won't be able to see your prompt so they will be waiting for it to process.</p>"
                tipID={index.toString()}
                datatooltipplace='below'
              />
            </label>
          )}
          <TextareaAutosize
            className='resize-y min-h-[8rem] max-h-96 bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner overflow-y-scroll w-full'
            readOnly
            value={inputToProcess || ''}
            ref={chatHistoryTextareaRef}
          />
          {!runnerMode && (
            <>
              {' '}
              <div className='flex flex-col gap-2'>
                <label className='text-xs w-max-content'>
                  GPT Model:{' '}
                  <MyToolTips
                    content='<p>GPT Models:
                    <br />
- Turbo 3.5 is the same model as the current free version of chatGPT, it is very cheap and it can process up to 12,000 words.
<br />
- GPT-4 is about 40x more expensive than Turbo. It is a text completion model so you can start the answer you want and it will complete it, as opposed to replying. It can be helpful if turbo is not producing the answer you wish.
<br />
 </p>

<p>Increasing <q>Temperature</q> will increase the randomness of your output. Information on the other settings are available in the openAI docs.</p> '
                    tipID={index.toString()}
                    datatooltipplace='below'
                  />
                  <select
                    className='text-xs bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner '
                    onChange={handleModelChange}
                    value={gptModel}
                    name='gptModel'
                  >
                    <option value='turbo'>Turbo 3.5</option>
                    <option value='gpt4'>GPT-4</option>
                    <option value='llama2'>LLaMA-2</option>
                  </select>
                </label>

                <div className='grid lg:grid-cols-2 md:grid-cols-1 gap-3'>
                  <GPTParameterSection
                    labelText='Temperature:'
                    name='temperature'
                    min='0'
                    max='2'
                    value={temperature ?? defaultInputValues.temperature}
                    onChange={handleChange}
                  />

                  <GPTParameterSection
                    labelText='Top P:'
                    name='topP'
                    min='0'
                    max='1'
                    value={topP ?? defaultInputValues.topP}
                    onChange={handleChange}
                  />

                  <GPTParameterSection
                    labelText='Frequency penalty:'
                    name='frequencyPenalty'
                    min='0'
                    max='2'
                    value={
                      frequencyPenalty ?? defaultInputValues.frequencyPenalty
                    }
                    onChange={handleChange}
                  />

                  <GPTParameterSection
                    labelText='Presence penalty:'
                    name='presencePenalty'
                    min='0'
                    max='2'
                    value={
                      presencePenalty ?? defaultInputValues.presencePenalty
                    }
                    onChange={handleChange}
                  />

                  <GPTParameterSection
                    labelText='Maximum words in response:'
                    name='maximumWordsInResponse'
                    min='1'
                    max={MAX_WORDS_FOR_MODEL[gptModel]}
                    step='1'
                    value={
                      maximumWordsInResponse ??
                      defaultInputValues.maximumWordsInResponse[gptModel]
                    }
                    onChange={handleChange}
                    tooltipContent='Setting a smaller response length increases the chances of your prompt going through quickly, and reduces the risk of capacity issues.'
                  />
                </div>
              </div>
              <label className='text-xs'>Optional heading for user:</label>
              {/* old optional message input */}
              {/* <input
              className='resize-none bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner'
              type='text'
              name='message'
              value={message ?? ''}
              onChange={handleChange}
            /> */}
              <AutocompleteTextarea
                autosize={true}
                block={block}
                index={index}
                onChange={handleChange}
                textAreaIndex={1}
                className='w-full resize-none bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner'
                name='message'
                value={message ?? ''}
                placeholder='Heading for user'
              />
            </>
          )}
          {!runnerMode && (
            <>
              <p className='text-xs'>
                Preview of hidden extra info for next message:
              </p>

              <TextareaAutosize
                className='resize-y max-h-24 bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner overflow-y-scroll w-full'
                readOnly
                value={temporaryExtraInfo || ''}
              />

              {/* <p className='text-xs'>
                Preview of extra info for next message (will be added to chat
                history):
              </p>

              <TextareaAutosize
                className='resize-y max-h-24 bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner overflow-y-scroll w-full'
                readOnly
                value={permanentExtraInfo || ''}
              /> */}
            </>
          )}
          {!runnerMode && <p className='text-xs'>Next message:</p>}{' '}
          {!disableUserInput && (
            <textarea
              placeholder={'Enter your next message here:'}
              name='inputMessage'
              value={inputMessage || ''}
              onChange={handleChange}
              className='rounded-lg'
              onKeyDown={sendMessageIfEnterPressed}
              ref={userInputCallbackRef}
              // disabled={disableUserInput}
            />
          )}
          <span className='flex items-center justify-between w-full'>
            <div>
              {runnerMode ? null : ( // </p> //   new line. //   Press enter to send your message; shift+enter will insert a // <p>
                <>
                  <button
                    className={testButtonStyles + ' w-fit'}
                    onClick={onSendMessage}
                  >
                    Send message
                  </button>
                  <button
                    className={testButtonStyles}
                    onClick={onNextMessageReceived}
                  >
                    Test response
                  </button>
                </>
              )}
            </div>
            {!runnerMode && (
              <button
                className={testButtonStyles}
                onClick={clearChatHistory}
              >
                Clear chat history
              </button>
            )}
          </span>
          <p>{errorMessage}</p>
          {!runnerMode && responseDescription && <p>{responseDescription}</p>}
        </>
      </div>
    </FuserLoader>
  );
};

export default ChatBlock;
