import React, { FC, Fragment, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import NcImage from '../../shared/NcImage/NcImage';
import ButtonPrimary from '../../shared/Button/ButtonPrimary';
import { nftsImgs } from '../../constants/fakeData';
import '../../styles/Glow.css';
import { Tab } from '@headlessui/react';
import FusionCard from '../../components/FusionCard';
import axios from 'axios';
import { User } from '../../../TypeScript/Interfaces';
import { useLocation, useNavigate } from 'react-router-dom';
import 'firebase/auth';
import { Link } from 'react-router-dom';
import { useAuthHeader, useAuthUser } from 'react-auth-kit';
import MarkdownSyntaxReader from '../../components/MarkdownSyntaxReader';
import { backendURL } from '../../constants/environmental';
import avatarNull from 'images/avatars/Image-Null.png';
import { truncateAfter } from '../../utils/string';
// import NftMoreDropdown from '../../components/NftMoreDropdown';
// import ButtonDropDownShare from '../../components/ButtonDropDownShare';
// import SocialsList from '../../shared/SocialsList/SocialsList';
// import FollowButton from '../../components/FollowButton';
// import ArchiveFilterListBox from '../../components/ArchiveFilterListBox';
// import { FaCamera } from 'react-icons/fa';
// import Button from '../../shared/Button/Button';

export interface AuthorPageProps {
  className?: string;
}

// interface BlockType {
//   id: string;
//   name: string;
//   description: string;
//   authorId: User;
//   uuid: string;
//   // add other fields as necessary
// }
// interface Review {
//   userPostingReview: {
//     _id: string;
//   };
//   title: string;
//   text: string;
//   rating: number;
//   timestamp: string;
//   _id: string;
// }

// const MAX_STARS = 5;

const AuthorPage: FC<AuthorPageProps> = ({ className = '' }) => {
  const user = useAuthUser();
  const authHeader = useAuthHeader()();
  const location = useLocation();
  const uid = location.pathname.split('/').pop() || '';
  const [userSchema, setUserSchema] = useState<any>(null);
  const [userFusions, setUserFusions] = useState<any[] | undefined>();
  const [loadingUserFusions, setLoadingUserFusions] = useState(true);
  const [sharedBlocks, setSharedBlocks] = useState<any[] | undefined>();
  const [loadingSharedBlocks, setLoadingSharedBlocks] = useState(true);
  // const [userBlocks, setUserBlocks] = useState<BlockType[]>([]);
  const navigate = useNavigate();

  const [following, setFollowing] = useState<User[]>([]);
  const [followers, setFollowers] = useState<User[]>([]);
  const [reviews, setReviews] = useState<any[]>([]);

  // const [hoverRating, setHoverRating] = useState(0);

  // const [title, setTitle] = useState('');
  // const [reviewText, setReviewText] = useState('');

  // const [rating, setRating] = useState(5);
  // const [showIcon, setShowIcon] = useState(false);

  // const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setTitle(e.target.value);
  // };

  // const handleReviewChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
  //   setReviewText(e.target.value);
  // };

  // const handleRatingChange = (rating: number) => {
  //   setRating(rating);
  // };

  // const handleStarHover = (rating: number) => {
  //   setHoverRating(rating);
  // };

  // const handleStarHoverEnd = () => {
  //   setHoverRating(0);
  // };

  // const [loading, setLoading] = useState(false);
  // const [error, setError] = useState(false);

  /*
  const leaveReview = async () => {
    setLoading(true);

    const newReview: Review = {
      userPostingReview: user()?.id,
      text: reviewText,
      title: title,
      rating: rating,
      timestamp: new Date().toISOString(),
      userReceivingReview: uid,
      _id: ''
    };

    console.log("Review Data to Send " , newReview);

    try {
      const response = await axios.post(
        `${backendURL}/user/leaveReview/${uid}`, 
        newReview,
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );
      console.log('Response successful', response.data);
      setReviews([response.data.review, ...reviews]);
    } 
    catch (err) {
      setError(true);
      console.log('There was an error posting this review', err);
    }

    setTitle('');
    setReviewText('');

    setLoading(false);
  };
  */

  const categories = [
    'Fusions',
    'Shared Blocks',
    'Description',
    // 'Following',
    // 'Followers',
    // 'Reviews',
    // 'Contact',
  ];

  // const [selectedCategory, setSelectedCategory] = useState('');

  const [fetchingUser, setFetchingUser] = useState(true);

  const [idOfSharedBlockBeingEdited, setIdOfSharedBlockBeingEdited] = useState<
    string | undefined
  >();

  const [newCustomTitle, setNewCustomTitle] = useState<string>('');

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`${backendURL}/user/${uid}`, {
          headers: {
            Authorization: authHeader,
          },
        });
        if (response.data.name == '' || response.data.name == null)
          response.data.name = 'username';
        setUserSchema(response.data);
      } catch (error) {
        console.error('Error fetching user: ', error);
        // Handle error accordingly
      } finally {
        setFetchingUser(false);
      }
    };

    fetchUser();
  }, [uid]);

  const userId = user()?.id;

  const handlePanelClick = () => {
    if (userId) {
      navigate(`/messages/${userId}`);
    }
  };

  const loadUserFusions = async () => {
    try {
      const response = await axios.get(`${backendURL}/blocks/fusions/${uid}`, {
        headers: {
          authorization: authHeader,
          uid: `${uid}`,
        },
      });

      setUserFusions(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingUserFusions(false);
    }
  };

  const loadSharedBlocks = async () => {
    try {
      const response = await axios.get(
        `${backendURL}/user/${uid}/sharedBlocks`,
        {
          headers: {
            authorization: authHeader,
            uid: `${uid}`,
          },
        }
      );

      // console.log(response);
      setSharedBlocks(response.data.sharedBlocks);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingSharedBlocks(false);
    }
  };

  // console.log(sharedBlocks);

  const removeFusionCardFromAuthorPage = (idOfFusionToRemove: string) => () => {
    setUserFusions(fusions =>
      fusions?.filter(({ _id }) => _id !== idOfFusionToRemove)
    );
  };

  const duplicateTool = (toolId: string) => async () => {
    try {
      const newName = prompt('Enter a name for the duplicated tool:');
      const response = await axios.post(
        `${backendURL}/blocks/${toolId}/duplicate`,
        { newName },
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );

      if (response.data.newToolData.authorId._id === uid) {
        // update the page if the owner duplicated their tool
        setUserFusions(
          fusions => fusions && [...fusions, response.data.newToolData]
        );
      }

      confirm('Tool successfully duplicated.');
    } catch (error) {
      console.log('Error duplicating tool');
    }
  };

  const togglePublish =
    ({ id, isPublished, isRequestPublished }: any) =>
    async () => {
      try {
        if (isRequestPublished && !isPublished)
          return window.confirm('Publish request has been sent');

        const confirm = window.confirm(
          isPublished ? 'Unpublish tool?' : 'Request publish?'
        );
        if (!confirm) return;
        const toggleResponse = await axios.patch(
          `${backendURL}/blocks/publish/${id}`, // Include blockId in the URL
          {},
          {
            headers: {
              Authorization: authHeader,
            },
          }
        );
        const newIsRequestPublished = toggleResponse.data.isRequestPublished;
        setUserFusions(fusions =>
          fusions?.map(fusion =>
            fusion._id === id
              ? {
                  ...fusion,
                  isPublished: toggleResponse.data.isPublished,
                  isRequestPublished: newIsRequestPublished,
                }
              : fusion
          )
        );
        console.log('new is request published', newIsRequestPublished);
        window.confirm(
          newIsRequestPublished
            ? 'Publish request sent'
            : 'Tool successfully unpublished'
        );
      } catch (error) {
        console.error(error); // Log the error
      }
    };

  const [idOfBlockWithDeleteConfirmation, setIdOfBlockWithDeleteConfirmation] =
    useState<string | undefined>();

  const [selectedTabIndex, setSelectedTabIndex] = useState<
    undefined | number
  >();

  useEffect(() => {
    loadUserFusions();
    loadSharedBlocks();
  }, [selectedTabIndex, location.pathname]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     let response;

  //     try {
  //       switch (selectedCategory) {
  //         case 'Description':
  //           // console.log(
  //           //   'We should be showing off the users descrtion in markdown syntax'
  //           // );
  //           break;

  //         case 'Following':
  //           response = await axios.get(`${backendURL}/user/following/${uid}`);
  //           break;
  //         case 'Followers':
  //           response = await axios.get(`${backendURL}/user/followers/${uid}`);
  //           break;
  //         case 'Reviews':
  //           console.log('fetching reviews');
  //           try {
  //             response = await axios.get(`${backendURL}/user/reviews/${uid}`);
  //             setReviews(response.data);
  //             //console.log('Here are the reviews for this user ', response.data);
  //           } catch (err) {
  //             setError(true);
  //             console.log('Error fetching reviews', err);
  //           }

  //           break;

  //         // case 'Contact':
  //         //   navigate(`/messages/${user()?.id}`);
  //         //   break;

  //         default:
  //           // response = await axios.get(`${backendURL}/fusions/${uid}`);
  //           break;
  //       }
  //       // setData(response.data);
  //     } catch (error) {
  //       console.error('Error fetching data: ', error);
  //       // Handle error accordingly
  //     }
  //   };
  //   fetchData();
  // }, [selectedCategory, uid]);

  // Use effect that builds the page depending on the uid in the url
  if (fetchingUser) {
    return (
      <div className='border-2 text-center h-48 flex items-center justify-center'>
        Loading details...
      </div>
    );
  }
  if (!userSchema) {
    return (
      <div className='border-2 text-center h-48 flex items-center justify-center'>
        This user was not found!
      </div>
    );
  }

  // const handleFollowClick = async () => {
  //   console.log('We should be following or unfollowing');
  // };

  // const messageUser = () => {
  //   navigate(`/messages/${user()?.id}`);
  // };

  // const handleEditReview = async (review: Review) => {
  //   console.log(review);
  //   const newReviewText = prompt('Enter new review text:'); // Modify this part to fit your user interface
  //   if (newReviewText === null) return; // If user cancelled the prompt

  //   try {
  //     const response = await axios.patch(
  //       `${backendURL}/user/reviews/${review._id}`,
  //       { text: newReviewText },
  //       {
  //         headers: {
  //           Authorization: authHeader,
  //         },
  //       }
  //     );
  //     // Handle successful update
  //     const indexOfReviewToUpdate = reviews.findIndex(
  //       oldReview => oldReview._id === review._id
  //     );

  //     const reviewToUpdate = reviews[indexOfReviewToUpdate];
  //     reviewToUpdate.text = newReviewText;

  //     setReviews(updateAtIndex(indexOfReviewToUpdate, reviewToUpdate, reviews));

  //     //console.log(response.data);
  //   } catch (err) {
  //     // Handle error
  //     console.error(err);
  //   }
  // };

  const handleDeleteReview = async (review: any) => {
    const { toolBeingReviewed: toolId } = review;

    try {
      const response = await axios.delete(
        `${backendURL}/blocks/deleteReview/${user()?.id}/${toolId}`,
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );
      // Handle successful deletion
      setReviews(reviews.filter(oldReview => oldReview._id !== review._id));
      console.log('Delete successful', response.data);
    } catch (err) {
      console.log('There was an error deleting your review', err);
    }
  };

  const setSharedBlockVisibilityOnProfile =
    ({ id, visible }: any) =>
    async () => {
      try {
        const response = await axios.patch(
          `${backendURL}/blocks/shared/${id}`,
          { linkToProfile: visible },
          {
            headers: {
              Authorization: authHeader,
            },
          }
        );
        if (response?.status === 200) {
          setSharedBlocks(previous =>
            previous?.map((sharedBlock: any) =>
              sharedBlock._id === id
                ? { ...sharedBlock, linkToProfile: visible }
                : sharedBlock
            )
          );
        }
      } catch (error) {
        console.log(error);
      }
    };

  const editSharedBlockTitle =
    ({ id, title }: any) =>
    async () => {
      try {
        const response = await axios.patch(
          `${backendURL}/blocks/shared/${id}`,
          { customTitle: title },
          {
            headers: {
              Authorization: authHeader,
            },
          }
        );
        if (response?.status === 200) {
          setSharedBlocks(previous =>
            previous?.map((sharedBlock: any) =>
              sharedBlock._id === id
                ? { ...sharedBlock, customTitle: title }
                : sharedBlock
            )
          );
        }
      } catch (error) {
        console.log(error);
      } finally {
        setNewCustomTitle('');
        setIdOfSharedBlockBeingEdited(undefined);
      }
    };

  const deleteSharedBlock = (id: string) => async () => {
    try {
      const response = await axios.delete(`${backendURL}/blocks/shared/${id}`, {
        headers: {
          Authorization: authHeader,
        },
      });
      if (response?.status === 200) {
        setSharedBlocks(previous =>
          previous?.filter(({ _id }: any) => _id !== id)
        );
        setIdOfBlockWithDeleteConfirmation(undefined);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      className={`nc-AuthorPage  ${className}`}
      data-nc-id='AuthorPage'
    >
      <Helmet>
        <title>
          {userSchema.name
            ? user()?.id === uid
              ? 'My profile - '
              : `${userSchema.name}'s profile - `
            : ''}
          Skillfusion
        </title>
        <link
          rel='canonical'
          href={`https://skillfusion.ai/profile/${uid}`}
        />
      </Helmet>

      {/* HEADER */}
      <div className='w-full'>
        <div className='relative w-full h-40 md:h-60 2xl:h-72'>
          <div className='absolute inset-0 bg-black bg-opacity-50 hover:opacity-100 transition-opacity duration-300'>
            <NcImage
              containerClassName='w-full h-full object-cover'
              src={userSchema?.coverPhoto || nftsImgs[3]}
              className='object-cover w-full h-full'
            />
            {userSchema._id === user()?.id && (
              <div className='absolute inset-0 bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition-opacity duration-300 text-center flex items-center justify-center text-white'>
                <button
                  className='w-full h-full'
                  onClick={() => {
                    navigate(`/edit_account/${user()?.id}`);
                  }}
                >
                  Change Cover Image
                </button>
              </div>
            )}
          </div>
        </div>

        <div className='container -mt-10 lg:-mt-16'>
          <div className='relative bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 p-5 lg:p-8 rounded-3xl md:rounded-[40px] shadow-xl flex flex-col md:flex-row '>
            <div className='w-32 lg:w-44 flex-shrink-0 mt-12 sm:mt-0  '>
              <div className='aspect-w-1 aspect-h-1 rounded-3xl overflow-hidden shadow-xl border-2 border-blue-400'>
                <NcImage
                  src={userSchema?.profileImage || avatarNull}
                  containerClassName='w-full h-full object-cover transition-all duration-300 transform hover:scale-105 avatar-glow'
                  className='w-full h-full object-cover avatar-glow'
                />

                {userSchema._id === user()?.id && (
                  <div className='absolute inset-0 bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition-opacity duration-300 text-center flex items-center justify-center text-white avatar-glow'>
                    <button
                      className='w-full h-full'
                      onClick={() => {
                        navigate(`/edit_account/${user()?.id}`);
                      }}
                    >
                      Change Profile Image
                    </button>
                  </div>
                )}
              </div>
            </div>

            <div className='pt-5 md:pt-1 md:ml-6 xl:ml-14 flex-grow '>
              <div className='max-w-screen-sm '>
                <h2 className='inline-flex items-center text-2xl sm:text-3xl lg:text-4xl font-semibold'>
                  <span>{userSchema?.name || userSchema?.username}</span>
                </h2>
                {userSchema?.username && (
                  <p>Registered email: {userSchema.username}</p>
                )}
              </div>
              {/*}
              <div className="mt-4 ">
                <SocialsList 
                facebookUrl={userSchema?.socialLinks?.facebook} 
                twitterUrl={userSchema?.socialLinks?.twitter} 
                youtubeUrl={userSchema?.socialLinks?.youtube} 
                telegramUrl={userSchema?.socialLinks?.telegram} 
                itemClass="block w-7 h-7" 
                />
              </div>
*/}
            </div>
            {/* 
// still needs coding
            <div className='absolute md:static left-5 top-4 sm:left-auto sm:top-5 sm:right-5 flex flex-row-reverse justify-end '>
              <NftMoreDropdown
                actions={[
                  {
                    to: '/example',
                    id: 'report',
                    name: 'Report problem',
                    icon: 'las la-flag',

                    // onClick: () => handleActionClick('edit_account'),
                  },
                  {
                    to: '/example',
                    id: 'dashboard',
                    name: 'User Dashboard',
                    icon: 'las la-flag',

                    // onClick: () => handleActionClick('edit_account'),
                  },
                  {
                    to: 'example',
                    id: 'transferToken',
                    name: 'Support Creator',
                    icon: 'las la-dollar-sign',

                    // onClick: () => handleActionClick('edit_account'),
                  },
                  {
                    to: 'contact',
                    id: 'contactCreator',
                    name: 'Contact Creator',
                    icon: 'las la-sync',

                    // onClick: () => handleActionClick('edit_account'),
                  },
                ]}
                containerClassName='w-8 h-8 md:w-10 md:h-10 flex items-center justify-center rounded-full bg-neutral-100 hover:bg-neutral-200 dark:hover:bg-neutral-700 dark:bg-neutral-800 cursor-pointer'
              />
              <ButtonDropDownShare
                className='w-8 h-8 md:w-10 md:h-10 flex items-center justify-center rounded-full bg-neutral-100 hover:bg-neutral-200 dark:hover:bg-neutral-700 dark:bg-neutral-800 cursor-pointer mx-2 shadow-md inset-0 hover:shadow-xl'
                panelMenusClass='origin-top-right !-right-5 !w-40 sm:!w-52'
              />

              <FollowButton
                isFollowing={false}
                onClick={handleFollowClick}
                belongsTo={uid!}
                currentUser={user()?.id}
                fontSize='text-sm md:text-base font-medium'
                sizeClass='px-4 py-1 md:py-2.5 h-8 md:!h-10 sm:px-6 lg:px-8 shadow-md inset-0 hover:shadow-xl'
              />
            </div>
              */}
          </div>
        </div>
      </div>
      {/* ====================== END HEADER ====================== */}

      <div className='container py-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28 w-full'>
        <main className='w-full'>
          <Tab.Group
            defaultIndex={0}
            onChange={setSelectedTabIndex}
          >
            <div className='flex flex-col lg:flex-row justify-between rounded-lg p-2 w-full'>
              <Tab.List className='flex space-x-0 sm:space-x-2 overflow-x-auto rounded-lg py-4'>
                {categories?.map(item => (
                  <Tab
                    key={item}
                    as={Fragment}
                  >
                    {({ selected }) => {
                      // if (selected) setSelectedCategory(item);
                      return (
                        <button
                          className={`flex-shrink-0 block font-medium px-4 py-2 text-sm sm:px-6 sm:py-2.5 capitalize rounded-full focus:outline-none ${
                            selected
                              ? 'bg-neutral-900 dark:bg-neutral-100 text-neutral-50 dark:text-neutral-900'
                              : 'text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100/70 dark:hover:bg-neutral-800'
                          } `}
                        >
                          {item}
                        </button>
                      );
                    }}
                  </Tab>
                ))}
              </Tab.List>

              <div className='mt-5 lg:mt-0 flex items-end justify-end'>
                {/* <ArchiveFilterListBox className='' /> */}
              </div>
            </div>

            <Tab.Panels className='w-full'>
              <Tab.Panel className='w-full'>
                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4'>
                  {userFusions && userFusions.length > 0 ? (
                    userFusions.map((fusion: any, index) => (
                      <FusionCard
                        key={index.toString()}
                        data={fusion}
                        className='...'
                        removeFromAuthorPage={removeFusionCardFromAuthorPage(
                          fusion._id
                        )}
                        duplicateTool={duplicateTool(fusion._id)}
                        togglePublish={togglePublish({
                          id: fusion._id,
                          isPublished: fusion.isPublished,
                          isRequestPublished: fusion.isRequestPublished,
                        })}
                      />
                    ))
                  ) : (
                    <div className='bg-white dark:bg-neutral-900 p-4 rounded-md shadow text-center w-full border-2 w-full'>
                      {loadingUserFusions
                        ? 'Loading fusions...'
                        : "Were sorry, but this user either doesnt have any public fusions yet, or we're having trouble loading them. Try reloading the page if you believe this may be an error"}
                    </div>
                  )}
                </div>
              </Tab.Panel>

              <Tab.Panel>
                {loadingSharedBlocks ? (
                  <div>Loading...</div>
                ) : sharedBlocks !== undefined && sharedBlocks?.length > 0 ? (
                  <ul>
                    {sharedBlocks
                      .sort(
                        (
                          { dateCreated: date1, linkToProfile: show1 },
                          { dateCreated: date2, linkToProfile: show2 }
                        ) =>
                          show1 === show2
                            ? +new Date(date2) - +new Date(date1)
                            : show1
                              ? -1
                              : 1
                      )
                      .map(
                        ({
                          _id,
                          blockHeading,
                          response,
                          dateCreated,
                          blockType,
                          customTitle,
                          linkToProfile,
                        }: any) => (
                          <li
                            key={_id}
                            className='flex gap-2 items-center mb-1 flex-wrap'
                          >
                            <Link
                              to={`/sharedBlock/${_id}`}
                              className='underline text-blue-800'
                            >
                              {customTitle ||
                                (['prompt', 'info'].includes(blockType)
                                  ? truncateAfter(
                                      50,
                                      (response ?? '').toString() ||
                                        blockHeading ||
                                        blockType[0].toUpperCase() +
                                          blockType.slice(1)
                                    )
                                  : blockType === 'image'
                                    ? 'Image'
                                    : blockType === 'textToSpeech'
                                      ? 'Audio'
                                      : blockType === 'chat'
                                        ? 'Chat'
                                        : 'Link')}
                            </Link>
                            <p>{`(Shared on ${new Date(
                              dateCreated
                            ).toLocaleString()})`}</p>
                            {uid === userId && (
                              <>
                                <label>Visible on profile</label>
                                <input
                                  type='checkbox'
                                  checked={linkToProfile}
                                  onChange={setSharedBlockVisibilityOnProfile({
                                    id: _id,
                                    visible: !linkToProfile,
                                  })}
                                />

                                {idOfSharedBlockBeingEdited === _id ? (
                                  <>
                                    <input
                                      className='border-2 border-black rounded-lg px-2'
                                      value={newCustomTitle}
                                      onChange={({ target: { value } }) =>
                                        setNewCustomTitle(value)
                                      }
                                    />
                                    <button
                                      className='border border-black rounded-lg px-2'
                                      onClick={editSharedBlockTitle({
                                        title: newCustomTitle,
                                        id: _id,
                                      })}
                                    >
                                      Save new title
                                    </button>
                                    <button
                                      className='border border-black rounded-lg px-2'
                                      onClick={() => {
                                        setNewCustomTitle('');
                                        setIdOfSharedBlockBeingEdited(
                                          undefined
                                        );
                                      }}
                                    >
                                      Discard changes
                                    </button>
                                  </>
                                ) : (
                                  <button
                                    className='border border-black rounded-lg px-2'
                                    onClick={() => {
                                      setIdOfSharedBlockBeingEdited(_id);
                                      setNewCustomTitle(customTitle);
                                    }}
                                  >
                                    Edit title
                                  </button>
                                )}

                                {/* {linkToProfile ? (
                                  <button
                                    className='border border-black rounded-lg px-2'
                                    onClick={setSharedBlockVisibilityOnProfile({
                                      id: _id,
                                      visible: false,
                                    })}
                                  >
                                    Hide from profile
                                  </button>
                                ) : (
                                  <button
                                    className='border border-black rounded-lg px-2'
                                    onClick={setSharedBlockVisibilityOnProfile({
                                      id: _id,
                                      visible: true,
                                    })}
                                  >
                                    Show on profile
                                  </button>
                                )} */}
                                {_id === idOfBlockWithDeleteConfirmation ? (
                                  <>
                                    <span>Confirm unshare:</span>
                                    <button
                                      className='border border-black rounded-lg px-2'
                                      onClick={deleteSharedBlock(_id)}
                                    >
                                      Confirm
                                    </button>
                                    <button
                                      className='border border-black rounded-lg px-2'
                                      onClick={() =>
                                        setIdOfBlockWithDeleteConfirmation(
                                          undefined
                                        )
                                      }
                                    >
                                      Cancel
                                    </button>
                                  </>
                                ) : (
                                  <button
                                    className='border border-black rounded-lg px-2'
                                    onClick={() =>
                                      setIdOfBlockWithDeleteConfirmation(_id)
                                    }
                                  >
                                    Unshare
                                  </button>
                                )}
                              </>
                            )}
                          </li>
                        )
                      )}
                  </ul>
                ) : (
                  <div>This user has not shared any blocks</div>
                )}
              </Tab.Panel>

              <Tab.Panel>
                {userSchema.description ? (
                  <MarkdownSyntaxReader description={userSchema.description} />
                ) : (
                  <p className={`border-2 rounded-lg p-2 `}>
                    Sorry, but there is no description for this user. But this
                    user is awesome never the less :) Stick around for more
                    updates on{' '}
                    {userSchema?.name || userSchema?.username || 'username'}
                  </p>
                )}
              </Tab.Panel>

              {/* Following */}
              <Tab.Panel className=' flex items-center justify-center text-center'>
                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 '>
                  {following.length > 0 ? (
                    following.map(user => (
                      <span key={user.id}>{user.name}</span>
                    ))
                  ) : (
                    <span className={`border-2 rounded-lg p-2 `}>
                      <p>This user is not following any users</p>
                    </span>
                  )}
                </div>
              </Tab.Panel>

              {/* Followers */}
              <Tab.Panel className=' flex items-center justify-center text-center'>
                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 '>
                  {followers.length > 0 ? (
                    followers.map(user => (
                      <span key={user.id}>{user.name}</span>
                    ))
                  ) : (
                    <span className='border-2 rounded-lg p-2'>
                      <p>
                        This user does not have any followers. Be their first,
                        leave them a follow
                      </p>
                      <ButtonPrimary>Follow</ButtonPrimary>
                    </span>
                  )}
                </div>
              </Tab.Panel>

              <Tab.Panel className='w-full rounded-lg'>
                {/*
                <div className="flex border-2 p-2 rounded-lg shadow-xl">
                  <div className="sm:w-1/4 w-1/2 h-full border-2 rounded-l-lg px-2 py-4 h-72 gap-2 flex flex-col">
                    <p>Leave a Review</p>
                    
                    <Input onChange={handleTitleChange} value={title} placeholder="title" />

                    <Textarea 
                      className="resize-none" 
                      onChange={handleReviewChange} 
                      value={reviewText}
                      placeholder="Review"
                    />

                    <p className="text-xs">Rating</p>

                    <div className="flex w-full text-xs">
                      {[...Array(MAX_STARS)].map((_, index) => {
                        const starValue = index + 1;
                        return (
                          <label
                            key={starValue}
                            className="w-full flex gap-2 items-center justify-start"
                            onMouseEnter={() => handleStarHover(starValue)}
                            onMouseLeave={handleStarHoverEnd}
                          >
                            <input
                              type="radio"
                              name="rating"
                              value={starValue}
                              onClick={() => handleRatingChange(starValue)}
                              checked={rating === undefined ? starValue === 5 : rating === starValue}
                            />
                        {index +1}
                          </label>
                        );
                      })}
                    </div>

                    <ButtonPrimary onClick={leaveReview}>
                      Leave Review
                    </ButtonPrimary>
                  </div>
                */}

                <div className='w-full border-2 rounded-r-lg px-2 py-4 h-72 overflow-x-auto'>
                  {reviews.length > 0 ? (
                    <ul className='w-full h-full flex flex-col'>
                      {reviews.map((review, index) => {
                        return (
                          <li
                            key={index}
                            className='review-item flex w-full border-2 rounded-md my-4 p-4 bg-white shadow-lg'
                          >
                            <Link
                              to={`/users/${review.userPostingReview._id}`}
                              className='mr-4'
                            >
                              <img
                                className='w-8 h-8 rounded-full'
                                src={
                                  review.userPostingReview.profileImage ||
                                  nftsImgs[3]
                                }
                                alt='Reviewer'
                              />
                            </Link>

                            <div className='flex-grow'>
                              {/* TOOL NAME HERE <div className="font-bold">{review.title}</div>*/}

                              <div className='font-bold'>{review.title}</div>

                              <div className='text-sm text-gray-700'>
                                {review.text.length > 250
                                  ? `${review.text.substring(0, 250)}...`
                                  : review.text}
                              </div>

                              <div className='text-xs text-gray-500 mt-2'>
                                Posted on:{' '}
                                {new Date(review.timestamp).toLocaleString()}
                              </div>
                            </div>

                            <div className='ml-4 flex flex-col items-end'>
                              <div>{review.rating} stars</div>

                              <div className='text-xs'>Reviewed by:</div>

                              <Link
                                to={`/profile/${review.userPostingReview._id}`}
                                className='text-xs text-blue-500'
                              >
                                {review.userPostingReview.name}
                              </Link>

                              {user()?.id === review.userPostingReview?._id && (
                                <div className='mt-2'>
                                  {/*<button className="text-xs text-blue-500 mr-2" onClick={() => handleEditReview(review)}>Edit</button>*/}
                                  <button
                                    className='text-xs text-red-500'
                                    onClick={() => handleDeleteReview(review)}
                                  >
                                    Delete
                                  </button>
                                </div>
                              )}
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  ) : (
                    <div className='empty-reviews'>
                      {"Sorry, this user doesn't have any reviews yet."}
                    </div>
                  )}
                </div>
                {/*</div>*/}
              </Tab.Panel>

              <Tab.Panel onClick={handlePanelClick}>
                {/* <Link to={`/messages/${user()?.id}`}> </Link> */}
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </main>
      </div>
    </div>
  );
};

export default AuthorPage;
