import React, { FC, useState, useContext } from 'react';
import FuserLoader from '../../containers/FuserPage/FuserLoader';
import BlockProps from '../../models/BlockProps';
import FuserContext from '../../context/FuserContext';
import Block from '../../models/Block';
import useBlockRunner from '../../hooks/useBlockRunner';
import AutocompleteTextarea from '../../containers/FuserPage/AutocompleteTextarea';
import { testButtonStyles } from '../../constants/styles';
import { blockReferenceRegex } from '../../constants/blocks';
import { is1dArray } from '../../utils/array';

const CustomJavascriptBlock: FC<BlockProps> = ({
  isLoading,
  setIsLoading,
  index,
  resultHtml,
  block,
  handleChange,
}) => {
  const {
    blockStyles,
    handleTextareaFocus,
    evaluatePlaceholder,
    replacePlaceholders,
    runnerMode,
    blocks,
    setBlocks,
    setActivityLog,
    textAreaRefs,
  } = useContext(FuserContext);

  const onTestClick = async () => {
    setIsLoading(true);

    const { inputToProcess } = block.data;

    const processedInput: string = inputToProcess
      ? inputToProcess.replace(blockReferenceRegex, (reference: string) => {
          const referenceContents = evaluatePlaceholder(reference, blocks);
          if (typeof referenceContents === 'string') {
            return referenceContents;
          }
          if (is1dArray(referenceContents) && referenceContents.length === 1) {
            return referenceContents[0];
          }
          return JSON.stringify(referenceContents);
        })
      : '';

    console.log({ processedInput });
    let response: any;
    let error;

    try {
      response = eval(processedInput);
    } catch (e: any) {
      console.log('Error in javascript to process: ', e);
      error = e.toString();
      setErrorMessage(error);
      response = [];
    }

    console.log({ response });
    if (error === undefined) {
      setErrorMessage('');
    }

    setBlocks((blocks: Block[]) => {
      const newBlocks = [...blocks];
      newBlocks[index] = {
        ...newBlocks[index],
        updatedBlock: true,
        data: {
          ...newBlocks[index].data,
          type: 'custom-javascript',
          processedInput,
          response,
        },
      };
      return newBlocks;
    });

    setIsLoading(false);
    setActivityLog((prevLog: string[]) => [
      ...prevLog,
      `Saved custom javascript block at index: ${index}`,
    ]);
  };

  useBlockRunner(onTestClick, index);

  const [errorMessage, setErrorMessage] = useState<string>('');

  return (
    <FuserLoader
      name='Custom Javascript Block'
      loading={isLoading}
    >
      {runnerMode ? (
        block.data.response && block.data.response.length > 0 ? (
          <div
            className='overflow-x-auto'
            dangerouslySetInnerHTML={{ __html: resultHtml }}
          />
        ) : (
          <div>Custom Javascript block</div>
        )
      ) : (
        <div
          className={blockStyles}
          key={index}
        >
          <label
            className='text-xs'
            id='prompt-textarea'
          >
            Javascript to process:
          </label>

          <AutocompleteTextarea
            block={block}
            index={index}
            onChange={handleChange}
            textAreaIndex={0}
            className='w-full prompt-textarea bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner '
            name='inputToProcess'
            value={block.data.inputToProcess || ''}
          />

          <button
            onClick={onTestClick}
            className={testButtonStyles}
          >
            Test
          </button>

          {errorMessage === '' || <p>{errorMessage}</p>}

          <div
            className='overflow-x-auto'
            dangerouslySetInnerHTML={{ __html: `Result: ${resultHtml}` }}
          />
        </div>
      )}
    </FuserLoader>
  );
};

export default CustomJavascriptBlock;
