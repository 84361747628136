import { FC, useRef, useState, useEffect } from 'react';
// import facebookSvg from 'images/Facebook.svg';
// import twitterSvg from "images/Twitter.svg";
// import googleSvg from 'images/Google.svg';
import { Helmet } from 'react-helmet';
import Input from '../../shared/Input/Input';
import { Link, useLocation } from 'react-router-dom';
import ButtonPrimary from '../../shared/Button/ButtonPrimary';
import { useCredit } from '../../context/CreditContext';
import { useNavigate } from 'react-router-dom';
// import { GoogleAuthProvider , FacebookAuthProvider , TwitterAuthProvider} from 'firebase/auth';
import axios from 'axios';
import ErrorPopup from '../../components/ErrorPopup';
import { useSignIn, useAuthUser, useSignOut } from 'react-auth-kit';
import ReactGA from 'react-ga4';
import React from 'react';
import { backendURL } from '../../constants/environmental';
import { useCookies } from 'react-cookie';

export interface PageLoginProps {
  className?: string;
}

const PageLogin: FC<PageLoginProps> = ({ className = '' }) => {
  const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const auth = useAuthUser();
  const user = auth();
  const signIn = useSignIn();
  const signOut = useSignOut();
  const { credit, updateCredits } = useCredit();
  // console.log(user);
  const [error, setError] = useState<string | null>(null);
  const handleCloseError = () => {
    console.log('close error');
    setError(null);
  };

  const redirectIfLoggedInElseClearAuthCookies = async () => {
    try {
      const checkJWTActive = await axios.get(
        `${backendURL}/user/checkLoggedIn`
      );
      if (user) navigate(`/page-search/`);
    } catch (error) {
      signOut();
    }
  };

  useEffect(() => {
    redirectIfLoggedInElseClearAuthCookies();
  }, []);

  const [, setCookie] = useCookies(['l_id']);

  const handleSignInClick = async () => {
    setIsLoggingIn(true);
    const username = emailRef.current!.value;
    const password = passwordRef.current!.value;

    try {
      const res = await axios.post(
        `${backendURL}/user/login`,
        {
          username,
          password,
        },
        { withCredentials: true }
      );

      //console.log(res.data);
      // Update the user state using setUser from AuthContext
      // console.log(res.data.authUserState);
      const { loginNotification } = res.data;

      if (
        signIn({
          token: res.data.token,
          expiresIn: res.data.expirationTimeInMinutes,
          tokenType: 'Bearer',
          authState: res.data.authUserState,
        })
      ) {
        setCookie('l_id', res.data.userId, {
          path: '/',
          expires: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
        });
        // console.log(res.data.token);
        // console.log(res.data);
        // localStorage.setItem('userLoggedIn', res.data.token); // to get nav to rerender
        // get credits here and add to local storage
        try {
          const response = await axios.post(
            `${backendURL}/user/checkCredits`,
            {},
            {
              headers: {
                Authorization: `Bearer ${res.data.token}`,
              },
            }
          );

          if (response.status === 200) {
            const dollarsToCredits = response.data * 10;
            updateCredits(dollarsToCredits);
            //localStorage.setItem('credit',dollarsToCredits.toString()); // to get nav to rerender correct credit
            //console.log("got credits during login",dollarsToCredits);
          }
        } catch (error) {
          // Handle error
          console.error(error);
        }
        setIsLoggingIn(false);

        ReactGA.event('successful_login');
        navigate(`/page-search/`, {
          state: {
            loginNotification,
          },
        });
      } else {
        setError(
          'Error signing in, please check that you have cookies enabled'
        );
      }
    } catch (registerError: any) {
      if (
        registerError?.response?.data?.error.includes(
          'Please verify your email'
        )
      ) {
        const idRegex = /\((\d+)\)/;
        const match = idRegex.exec(registerError.response.data.error);

        if (match && match[1]) {
          const tempID = match[1];
          navigate(`/verify-email/${tempID}`);
        }
      }

      console.error('Error creating account', registerError);
      console.log(registerError);

      setError(`${registerError.response.data.error}`);
      // track failed login
      ReactGA.event('failed_login_attempt');
    } finally {
      setIsLoggingIn(false);
    }
  };

  // const handleGoogleSignIn = async () => {};

  // const handleTwitterSignIn = async () => {};
  // const handleFacebookSignIn = async () => {};

  // const loginSocials = [
  //   {
  //     name: 'Continue with Facebook',
  //     function: handleFacebookSignIn,
  //     icon: facebookSvg,
  //   },
  //   {
  //     name: 'Continue with Google',
  //     function: handleGoogleSignIn,
  //     icon: googleSvg,
  //   },
  // ];

  // const handleForgotPassword = async () => {
  //   const email = emailRef.current!.value;

  //   try {
  //     await axios.post(`${backendURL}/user/forgot-password`, { email });
  //     alert('Password reset link has been sent to your email address.');
  //   } catch (error) {
  //     console.error('Error sending password reset link', error);
  //   }
  // };

  const location = useLocation();

  return (
    <div
      className={`nc-PageLogin ${className}`}
      data-nc-id='PageLogin'
    >
      {!user ? (
        <>
          <Helmet>
            <title>Login</title>
            <link
              rel='canonical'
              href='https://skillfusion.ai/login'
            />
          </Helmet>

          <div className='container mb-24 lg:mb-32'>
            <h2 className='my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center'>
              Login
            </h2>

            <div className='max-w-md mx-auto space-y-6'>
              {navigator.cookieEnabled || (
                <ErrorPopup
                  message={
                    'Please enable cookies in your browser otherwise this website will not function properly.'
                  }
                  onClose={handleCloseError}
                />
              )}
              {location?.state?.resetPasswordMessage && (
                <h3 className='text-center text-xl'>
                  {location.state.resetPasswordMessage}
                </h3>
              )}
              {/* <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <button
                key={index}
                onClick={item.function}
                className="flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </button>
            ))}
          </div>

          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>
           */}
              {/* FORM */}
              <form className='grid grid-cols-1 gap-6'>
                <label className='block'>
                  <span className='text-neutral-800 dark:text-neutral-200'>
                    Email address
                  </span>
                  <Input
                    type='email'
                    placeholder='example@example.com'
                    className='mt-1'
                    ref={emailRef}
                  />
                </label>
                <label className='block'>
                  <span className='flex justify-between items-center text-neutral-800 dark:text-neutral-200'>
                    Password
                    <Link
                      to='/forgot-password'
                      className='text-sm text-green-600'
                    >
                      Forgot password?
                    </Link>
                  </span>
                  <Input
                    type='password'
                    ref={passwordRef}
                    className='mt-1'
                  />
                </label>
                {error && (
                  <ErrorPopup
                    message={error}
                    onClose={handleCloseError}
                  />
                )}
              </form>
              <div className='flex justify-center'>
                <ButtonPrimary onClick={handleSignInClick}>
                  {isLoggingIn ? 'Signing in...' : 'Sign in'}
                </ButtonPrimary>
              </div>
              {/* ==== */}
              <Link
                to='/signup'
                className='block text-center text-neutral-700 dark:text-neutral-300 hover:scale-110 font-bold text-lg'
              >
                New user? {` `}
                <span className='text-green-600'>Create an account</span>
              </Link>
            </div>
          </div>
        </>
      ) : (
        <div className='container flex items-center flex-col justify-center gap-4 m-2'>
          <h2>
            Sign in successful, you are now being redirected to the tools
            page...
          </h2>
          {/* <h2>
            {' '}
            <span>You are currently signed in as: {user?.username}</span>
          </h2>
          <ButtonPrimary onClick={signOut}>Sign out</ButtonPrimary>
          <ButtonPrimary onClick={() => navigate(`/profile/${user?.id}`)}>
            My Profile
          </ButtonPrimary> */}
        </div>
      )}
    </div>
  );
};

export default PageLogin;
