import axios from 'axios';

axios.interceptors.response.use(
    
  (response) => response,
  (error) => {
    if (error.response && error.response.data.error === 'Token has expired') {

        localStorage.removeItem('token'); 

        document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        if (window.location.pathname !== '/login') {
            console.log('Redirecting to login...');
            window.location.href = '/login';
          }

    }
    return Promise.reject(error);
  }
);
