// components/ReviewForm.tsx
import axios from 'axios';
import React, { useState, FormEvent, useEffect, useContext } from 'react';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';
import FuserContext from '../../context/FuserContext';
import { useAuthHeader } from 'react-auth-kit';
import { backendURL } from '../../constants/environmental';

const validStarRatings = [1, 2, 3, 4, 5];

const ReviewForm: React.FC<{}> = () => {
  const [titleText, setTitleText] = useState('');
  const [reviewText, setReviewText] = useState('');
  const [rating, setRating] = useState(0);
  const [statusMessage, setStatusMessage] = useState('');
  const [lookingForExistingReview, setLookingForExistingReview] =
    useState(true);
  const [reviewAlreadyExists, setReviewAlreadyExists] = useState(false);
  const [reviewId, setReviewId] = useState<string>('');
  const { toolId, userId, authorId } = useContext(FuserContext);
  const authHeader = useAuthHeader()();

  useEffect(() => {
    if (lookingForExistingReview)
      (async () => {
        try {
          const response = await axios.get(
            `${backendURL}/blocks/getUsersReview/${userId}/${toolId}`
          );

          // console.log('Response successful', response.data);

          const reviewData = response?.data?.reviewData;

          if (!reviewData) return;

          const { id, title, text, rating } = reviewData;
          setReviewId(id);
          setTitleText(title);
          setReviewText(text);
          setRating(rating);
          setReviewAlreadyExists(true);
        } catch (err) {
          console.log('There was an error retrieving your review', err);
        } finally {
          // delay this in case the review fields update before this
          setTimeout(() => setLookingForExistingReview(false), 500);
        }
      })();
  }, []);

  const [deleteReviewButtonText, setDeleteReviewButtonText] =
    useState('Delete review');

  const deleteReview = async (event: FormEvent) => {
    event.preventDefault();
    setDeleteReviewButtonText('Deleting review...');
    try {
      const response = await axios.delete(
        `${backendURL}/blocks/review/${reviewId}`,
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );

      //console.log('Delete successful', response.data);

      setTitleText('');
      setReviewText('');
      setRating(0);
      setStatusMessage('Review successfully deleted');
      setReviewAlreadyExists(false);
    } catch (err) {
      console.log('There was an error deleting your review', err);
      setStatusMessage('There was an error deleting your review');
    }
    setDeleteReviewButtonText('Delete review');
  };

  const [postReviewButtonText, setPostReviewButtonText] = useState('');

  useEffect(
    () =>
      setPostReviewButtonText(
        reviewAlreadyExists ? 'Update review' : 'Submit review'
      ),
    [reviewAlreadyExists]
  );

  const [postingReview, setPostingReview] = useState<boolean>(false);

  const handleSubmit = async (event: FormEvent) => {
    try {
      setPostingReview(true);
      event.preventDefault();

      if (titleText === '') {
        return setStatusMessage('Please write a title for the review');
      }

      if (reviewText === '') {
        return setStatusMessage('Please write a review');
      }

      if (!validStarRatings.includes(rating)) {
        return setStatusMessage('Please give the review a star rating');
      }

      setPostReviewButtonText(
        reviewAlreadyExists ? 'Updating review...' : 'Submitting review...'
      );

      setStatusMessage('');

      const response = await axios.put(
        `${backendURL}/blocks/${toolId}/review`,
        { text: reviewText, title: titleText, rating: rating },
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );
      //console.log('Response successful', response.data);
      const responseMessage = response?.data?.message;
      setReviewId(response.data.reviewId);
      setReviewAlreadyExists(true);

      if (responseMessage) setStatusMessage(responseMessage);
    } catch (err) {
      console.log('There was an error posting this review', err);
      setStatusMessage(`Sorry, there was an error in submitting your review`);
    } finally {
      setPostReviewButtonText(
        reviewAlreadyExists ? 'Update review' : 'Submit review'
      );
      setPostingReview(false);
    }
  };

  const renderStars = (number: number) => {
    const stars: any[] = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        i <= number ? (
          <AiFillStar
            key={i}
            onClick={() => {
              setRating(i);
              setStatusMessage('');
            }}
            className='cursor-pointer text-yellow-500'
          />
        ) : (
          <AiOutlineStar
            key={i}
            onClick={() => {
              setRating(i);
              setStatusMessage('');
            }}
            className='cursor-pointer text-gray-500'
          />
        )
      );
    }
    return stars;
  };

  return lookingForExistingReview ? (
    <p>Checking if you have already wrote a review...</p>
  ) : (
    <form
      onSubmit={handleSubmit}
      className='w-full flex flex-col gap-2 items-center'
    >
      <p>{`${reviewAlreadyExists ? 'Edit your' : 'Write a'} review:`}</p>

      <div className='w-full flex items-center gap-2 justify-between'>
        <label className='text-sm'>Title: </label>
        <input
          type='text'
          placeholder='Review title'
          className='w-3/4 rounded-xl text-sm border border-neutral-100 shadow-inner '
          value={titleText}
          onChange={e => {
            setTitleText(e.target.value);
            setStatusMessage('');
          }}
        />
      </div>

      <textarea
        placeholder='Write your review here'
        className='w-full prompt-textarea rounded-xl text-sm border border-neutral-100 shadow-inner '
        value={reviewText}
        onChange={e => {
          setReviewText(e.target.value);
          setStatusMessage('');
        }}
        required
      />

      <div className='flex items-center'>
        <label className='mr-2'>Rating:</label>
        {renderStars(rating)}
      </div>

      <button
        className='border border-gray-300 border-2 p-1 px-2 rounded-lg bg-white hover:bg-blue-100'
        type='submit'
        disabled={postingReview}
      >
        {postReviewButtonText}
      </button>

      {reviewAlreadyExists && (
        <button
          className='border border-gray-300 border-2 p-1 px-2 rounded-lg bg-white hover:bg-blue-100'
          onClick={deleteReview}
        >
          {deleteReviewButtonText}
        </button>
      )}

      <p className='text-sm'>{statusMessage}</p>
    </form>
  );
};

export default ReviewForm;
