import { Helmet } from "react-helmet";
import { FC, useState, FormEvent, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate, useLocation, Link } from "react-router-dom";
import "firebase/auth";
import Input from "../shared/Input/Input";
import ButtonPrimary from "../shared/Button/ButtonPrimary";
import { useAuthHeader, useAuthUser } from "react-auth-kit";
import React from "react";
import { backendURL } from "../constants/environmental";
import missingpic from "../images/missing.jpg";
// import { SearchResult } from '../../TypeScript/Interfaces';

/*
todo:
earnings and downloads data for creators
could make this in to the search page too eventually to make app more efficient
*/
export interface DashboardPageProps {
  className?: string;
}

interface Block {
  _id: string;
  name: string;
  description: string;
  authorId: string;
  coverPhoto: string;
  price: number;
  priceFrequency: "monthly" | "yearly" | "one-time";
  isPublished: string;
  numberOfTransactions: 0;
  totalUserCost: 0;
  totalCostToSF: 0;
  isRequestPublished: string;
  // ... add other properties if needed
}

interface purchasedTools {
  blocks: Block[];
  // ... add other properties if the API response has more
}

// enum DashboardMode {
//   Creator = 'Creator',
//   User = 'User',
// }

const trimDescriptionAndRemoveSkillfusionLinks = (
  desc: string,
  maxLength: number
) => {
  if (typeof desc !== "string") {
    // Return a default string or keep it empty if desc is not valid
    return "";
  }

  if (desc.length > maxLength) {
    // Return the trimmed description with an ellipsis
    return `${desc.substring(0, maxLength)} ...read more`;
  }

  // Return the original description with skillfusion links removed if it doesn't need trimming
  return desc.replace(
    /(https?:\/\/)?(www\.)?(blog\.)?skillfusion\.ai[A-Za-z0-9\-._~!$&'()*+,;=:@/?#[\]%]* ?/gi,
    ""
  );
};

const ToolImage: FC<any> = ({ src }: any) => {
  const [imageFailedToLoad, setImageFailedToLoad] = useState(false);

  return (
    <img
      src={!src || imageFailedToLoad ? missingpic : src}
      onError={() => setImageFailedToLoad(true)}
      alt="Cover Photo"
    />
  );
};

const UserDashboard: FC<DashboardPageProps> = ({ className = "" }) => {
  const authHeader = useAuthHeader()();

  const user = useAuthUser();
  const navigate = useNavigate();
  const location = useLocation();
  const uid = location.pathname.split("/").pop()?.split("?")[0] || "";
  // const [searchTerm, setSearchTerm] = useState<string>('');
  // const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  // const debounceTimeout = useRef<NodeJS.Timeout | number | null>(null);
  // const [filters, setFilters] = useState({});
  // const [category, setCategory] = useState<string>('All SkillFusion');

  const [totalTransactions, setTotalTransactions] = useState(0);
  const [totalUserCost, setTotalUserCost] = useState(0);
  const [totalCostToSF, setTotalCostToSF] = useState(0);

  // for managing emails
  const [usernameError, setUsernameError] = useState(false);
  const usernameRef = useRef<HTMLInputElement>(null);
  const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [email, setEmail] = useState("");
  const [buttonText, setButtonText] = useState("update email");
  // check email is valid

  useEffect(() => {
    if (email.length > 0) setUsernameError(!EMAIL_REGEX.test(email));
    else setUsernameError(false);
  }, [email]);
  const emailInputClasses = `border ${
    usernameError
      ? "border-red-800 dark:border-red-800 border-4"
      : "border-gray-300 dark:border-gray-300"
  } mt-1`;

  const handleCreatorSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    async function updatePaymentEmail() {
      try {
        const response = await axios.post(
          `${backendURL}/user/updatepayments/`,
          {},
          {
            headers: {
              Authorization: authHeader,
              newPaymentEmail: email,
            },
          }
        );

        if (response.status === 200) {
          //console.log(response);
          setEmail(response.data.paymentEmail);
          setButtonText("updated!");
        }
      } catch (error) {
        console.error("Error updating email", error);
        setButtonText("Error!");
      }
    }

    if (!usernameError) updatePaymentEmail();
  };

  // redirects user if not logged in
  useEffect(() => {
    if (!user()?.id) {
      console.log("Not logged in");
      navigate("/login");
    }
  });

  // if ID isn't in the url try and get ID from cookie and redirect user
  //if (uid=="") {}

  const queryParameters = new URLSearchParams(window.location.search);
  const type = queryParameters.get("type") || "user";
  // console.log('type=' + type);

  // get list of purchased tools
  const [Tools, setTools] = useState<purchasedTools | null>(null);
  const [fetchingTools, setFetchingTools] = useState<boolean>(true);

  useEffect(() => {
    async function fetchTools() {
      try {
        if (type == "creator") {
          const response = await axios.post(
            `${backendURL}/blocks/getMadeBlocksByUserIDPrivate/${uid}`,
            {},
            {
              headers: {
                Authorization: authHeader,
              },
            }
          );
          // todo get creator stats here like toos sold, monthly sign ups

          if (response.status === 200) {
            const sortedBlocks: Block[] = [...response.data.blocks].sort(
              (a, b) => {
                if (a.isRequestPublished && !b.isRequestPublished) return -1;
                if (!a.isRequestPublished && b.isRequestPublished) return 1;

                // If 'isRequestPublished' is the same, sort by 'isPublished'
                if (a.isRequestPublished === b.isRequestPublished) {
                  if (a.isPublished && !b.isPublished) return 1;
                  if (!a.isPublished && b.isPublished) return -1;
                }

                return b.numberOfTransactions - a.numberOfTransactions;
              }
            );
            // console.log(sortedBlocks);

            setTools({ ...response.data, blocks: sortedBlocks } as any);

            //setTools(response.data);
            setEmail(response.data.paymentEmail);

            let tempTotalTransactions = 0;
            let tempTotalUserCost = 0;
            let tempTotalCostToSF = 0;

            for (const tool of response.data.blocks) {
              tempTotalTransactions += tool.numberOfTransactions || 0;
              tempTotalUserCost += tool.totalUserCost || 0;
              tempTotalCostToSF += tool.totalCostToSF || 0;
            }

            setTotalTransactions(tempTotalTransactions);
            setTotalUserCost(tempTotalUserCost);
            setTotalCostToSF(tempTotalCostToSF);

            console.log("local scope check", totalTransactions);
            //console.log(response.data);
          }
        }

        if (type == "user") {
          const response = await axios.post(
            `${backendURL}/blocks/getPublishedBlocksByUserID/${uid}`,
            {},
            {
              headers: {
                Authorization: authHeader,
              },
            }
          );

          if (response.status === 200) {
            const filteredTools = response.data.blocks.filter(
              (tool: any) => user()?.id !== tool.authorId
            );
            console.log(user()?.id);

            const newData = { ...response.data, blocks: filteredTools };
            setTools(newData);
            console.log(newData);
          }
        }
      } catch (error) {
        console.error("Error fetching purchased tools", error);
      } finally {
        setFetchingTools(false);
      }
    }

    fetchTools();
  }, [location]);

  // console.log('user ID when getting error: ' + user()?.id);
  if (user()?.id !== uid) {
    // front end?
    return (
      <div className="w-full  flex items-center justi fy-center flex-col gap-2 min-h-96">
        <p>
          You have entered an incorrect user ID {user()?.id} vs {uid}
        </p>
      </div>
    );
  }

  const handleLinkClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();

    const newPath = event.currentTarget.getAttribute("href");
    if (newPath) {
      navigate(newPath);
    }
  };

  /// copied from PageSearch
  /*
  const searchEntities = async (searchTerm: string, filters: any): Promise<SearchResult[]> => {
    try {
      const params = category !== 'All NFTs' ? { searchTerm, category } : { searchTerm };
      const searchForCategory = axios.get(`${backendURL}/site/search/categories`, { params: { searchTerm: searchTerm, ...filters } });
      const searchForBlock = axios.get(`${backendURL}/site/search/blocks`, { params: { searchTerm: searchTerm, ...filters } });

      const [categoryResponse, blockResponse] = await Promise.all([searchForCategory, searchForBlock]);
  
      const categories = categoryResponse.data.results.map((category: any) => ({...category, username: category.catName || 'default', _type: 'category'}));
      const blocks = blockResponse.data.results.map((block: any) => ({...block, title: block.title, price: block.price || 'default', _type: 'block'}));
  
      console.log("search results \n" , "\n category: " , categories, "\n blocks : " , blocks);
      return [...categories, ...blocks];
    } catch(err) {
      console.log("Error searching: ", err);
      return [];
    }
};


  const handleSearchInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value;
      setSearchTerm(inputValue);
  
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current as any);
      }
  
      if (inputValue.length > 2) {
        debounceTimeout.current = setTimeout(async () => {
          const results = await searchEntities(inputValue, filters); // include filters here
          setSearchResults(results);
        }, 500);
      } else {
        setSearchResults([]);
      }
    },
    [filters] // include filters in the dependencies array
  );
  */
  //// end of copied from search page

  if (Tools) {
    return (
      <div>
        <Helmet>
          <title>My Tools - Skillfusion</title>
        </Helmet>
        {/* copied from search page for when we add search functionality */}
        {/*
<div className="container">
        <header className="max-w-2xl mx-auto -mt-10 flex flex-col lg:-mt-7">
          <form className="relative w-full ">
            <label
              htmlFor="search-input"
              className="text-neutral-500 dark:text-neutral-300"
            >
              <span className="sr-only">Search all icons</span>
              <Input
                className="shadow-lg border-0 dark:border"
                id="search-input"
                type="search"
                placeholder="Type your keywords"
                sizeClass="pl-14 py-5 pr-5 md:pl-16"
                rounded="rounded-full"
                onChange={handleSearchInputChange}
              />
              <ButtonCircle
                className="absolute right-2.5 top-1/2 transform -translate-y-1/2"
                size=" w-11 h-11"
                type="submit"
              >
                <i className="las la-arrow-right text-xl"></i>
              </ButtonCircle>
            </label>
          </form>
        </header>
      </div>
*/}

        <div className="container py-2 lg:pb-28 lg:pt-2 space-y-2 lg:space-y-4">
          <h2 className="my-20 flex items-center text-xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
            {type === "user" ? "Your Purchased Tools" : null}
            {type === "creator" ? "Tools You Have Made" : null}
            {type === null ? "Tools" : null}
          </h2>
          {type === "creator" ? (
            <p className="text-neutral-900  dark:text-neutral-100">
              Total tool sales: {totalTransactions}
              <br />
              Total sales revenue: ${totalUserCost}
              <br />
              Your total commission: ${totalCostToSF}
            </p>
          ) : null}
          <div className="grid sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-x-8 gap-y-10 mt-2 lg:mt-2">
            {Tools.blocks.map((result, index) => {
              const link = `/fuser/${result._id}`;
              //const bgLightMode = `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${result.coverPhoto ? result.coverPhoto : nftsImgs[5]})`;
              //const bgDarkMode = `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${result.coverPhoto ? result.coverPhoto : nftsImgs[5]})`;

              return (
                <div style={{ display: "grid" }} key={result._id}>
                  <Link
                    //className='w-full rounded-lg text-black mb-20'

                    className={`w-full rounded-lg text-black mb-20 bg-purple-100 ${
                      result.isRequestPublished
                        ? " border-solid border-red-800 border-2 "
                        : " "
                    } ${result.isPublished ? " border-none " : ""}`}
                    key={result._id}
                    to={link}
                    onClick={handleLinkClick}
                    style={{
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  >
                    <div className="border-2 p-4 rounded-md shadow-lg w-full flex h-full overflow-y-scroll">
                      <span className="flex items-center justify-between w-full gap-2">
                        <div className="flex flex-col h-52">
                          <h2 className="font-bold text-lg mb-2 overflow-ellipsis max-w-full">{`${result.name}`}</h2>
                          <p>
                            Price:&nbsp;
                            <b>
                              {typeof result.price === "number" &&
                              result.price !== 0.0
                                ? `${
                                    result.priceFrequency === "monthly"
                                      ? "You pay " +
                                        result.price * 10 +
                                        " credits per month"
                                      : "You paid " +
                                        result.price * 10 +
                                        " credits"
                                  }`
                                : "Free Tool"}
                            </b>
                          </p>
                          {type === "creator" ? (
                            <>
                              <p>
                                Publish Request Made:&nbsp;
                                <b>{String(result.isRequestPublished)}</b>
                              </p>
                            </>
                          ) : null}
                          {type === "creator" ? (
                            <>
                              <p>
                                Published:&nbsp;
                                <b>{String(result.isPublished)}</b>
                              </p>
                            </>
                          ) : null}
                          <p className="overflow-ellipsis max-w-full">
                            {`${trimDescriptionAndRemoveSkillfusionLinks(
                              result.description,
                              250
                            )}` || ""}
                          </p>
                          <ToolImage src={result?.coverPhoto} />
                          {/* <div className="flex items-center justify-end gap-2 z-10 text-blue-400">
            <AiOutlineContacts onClick={() => { navigate(`/profile/${result.authorId}`) }} className="hover:scale-110 " />
            <AiOutlineEye onClick={() => { navigate(`/fusion/${result._id}`) }} className="hover:scale-110 dark:text-blue-400 " />
            <MdViewInAr onClick={() => { navigate(`/fuser/${result._id}`) }} className="hover:scale-110 " />
  </div> */}
                        </div>
                      </span>
                    </div>
                    {type === "creator" ? (
                      <p className="text-neutral-900  dark:text-neutral-100">
                        Total sales {String(result.numberOfTransactions)}
                        <br />
                        Total sales value: ${String(result.totalUserCost)}
                        <br />
                        Your total commission: ${String(result.totalCostToSF)}
                        <br />
                      </p>
                    ) : (
                      ""
                    )}
                    {/* </Link>
                  {type === 'creator' &&
                  typeof result.price === 'number' &&
                  result.price !== 0.0 &&
                  result.isPublished ? (
                    <a
                      onClick={handleLinkClick}
                      href={`/offers/${result._id}`}
                      style={{ color: 'purple', textDecoration: 'underline' }}
                    >
                      Create a special offer for your tool
                    </a>
                  ) : (
                    ''
                  )}*/}
                  </Link>
                  {type === "creator" &&
                  typeof result.price === "number" &&
                  result.price !== 0.0 &&
                  result.isPublished ? (
                    <a
                      onClick={handleLinkClick}
                      href={`/offers/${result._id}`}
                      style={{}}
                      className="text-purple-800 dark:text-purple-300 underline underline-offset-2"
                    >
                      Create a special offer for your tool
                    </a>
                  ) : (
                    ""
                  )}
                </div>
              );
            })}
          </div>
        </div>

        {type == "creator" ? (
          <div className="max-w-md mx-auto space-y-6">
            <form
              onSubmit={handleCreatorSubmit}
              className="grid grid-cols-1 gap-6 "
            >
              <p>
                Please add your Paypal email. Commissions paid monthly on the
                following 8th of each month.
              </p>
              <label className="block">
                <span className="text-neutral-800 dark:text-neutral-200">
                  Paypal email:
                </span>
                <Input
                  type="email"
                  placeholder={email}
                  className={emailInputClasses}
                  ref={usernameRef}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {/* <div>
                {error && <p>{error}</p>}
              </div> */}
                {usernameError ? <p>please check your email</p> : null}
              </label>

              <ButtonPrimary type="submit">{buttonText}</ButtonPrimary>
            </form>
            <br />
            <br />
            <br />
            <br />
          </div>
        ) : null}
      </div>
    );
  } else
    return (
      <div
        style={{ width: "100%", textAlign: "center", paddingBottom: "50px" }}
      >
        <Helmet>
          <title>My Tools - Skillfusion</title>
        </Helmet>
        {type === "creator" ? (
          <p>
            Loading.... can take a while to load stats
            <br />
            {"If you haven't made any tools, check out the "}
            <a
              className="text-blue-700 underline cursor-pointer"
              onClick={() => navigate("/fuser/0")}
            >
              Tool Maker
            </a>
          </p>
        ) : null}
        {type === "user" ? (
          fetchingTools ? (
            <p> Loading your purchased tools... </p>
          ) : (
            <p>
              {"You don't have any tools, check out our "}
              <a
                className="text-blue-700 underline cursor-pointer"
                onClick={() => navigate("/page-search")}
              >
                discovery page
              </a>
              .
            </p>
          )
        ) : null}
      </div>
    );
};

export default UserDashboard;
