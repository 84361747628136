import React, {
  FC,
  useState,
  useEffect,
  // useRef,
  // useCallback,
  // useContext,
} from 'react';
import Logo from '../../shared/Logo/Logo';
import MenuBar from '../../shared/MenuBar/MenuBar';
import Navigation from '../../shared/Navigation/Navigation';
import { useSignOut, useAuthUser } from 'react-auth-kit';
import { useCredit } from '../../context/CreditContext';
import PagePay from '../../containers/PagePay/PagePay';
import { MdClose } from 'react-icons/md';
import { Link, useNavigate, useLocation } from 'react-router-dom';
// import { CombinedResult } from "../../sanityClient"
// import { SearchResult } from '../../../TypeScript/Interfaces';
// import axios from 'axios';
// import HeaderContext from '../../context/HeaderContext';
// import { backendURL } from '../../constants/environmental';

export interface MainNav2LoggedProps {}

function LogOutCheck() {
  const signOut = useSignOut();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (window.location.hash === '#logout') {
      // Handle logout logic here
      console.log('Logging out...');
      signOut();
      navigate('/');
    }
  }, [location]);

  return null;
}

const MainNav2Logged: FC<MainNav2LoggedProps> = () => {
  // I've removed search bar so these probably aren't used at the moment
  // const [searchTerm, setSearchTerm] = useState<string>('');
  // const [searchResults, setSearchResults] = useState<SearchResult[]>([]);

  // const debounceTimeout = useRef<NodeJS.Timeout | number | null>(null);
  const user = useAuthUser();
  const location = useLocation();
  const url = location.pathname;

  // const handleSearchInputChange = useCallback(
  //   (e: React.ChangeEvent<HTMLInputElement>) => {
  //     const inputValue = e.target.value;
  //     setSearchTerm(inputValue);

  //     if (debounceTimeout.current) {
  //       clearTimeout(debounceTimeout.current as any);
  //     }

  //     if (inputValue.length > 2) {
  //       debounceTimeout.current = setTimeout(async () => {
  //         const results = await searchEntities(inputValue);
  //         setSearchResults(results);
  //       }, 500); // 500ms debounce time
  //     } else {
  //       setSearchResults([]);
  //     }
  //   },
  //   []
  // );

  const navigate = useNavigate();
  // const handleLinkClick = (
  //   event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  // ) => {
  //   event.preventDefault();

  //   // Assuming the `to` prop in your <Link> components holds the correct path
  //   const newPath = event.currentTarget.getAttribute('href');

  //   if (newPath) {
  //     navigate(newPath);
  //   }
  // };

  // const searchEntities = async (
  //   searchTerm: string
  // ): Promise<SearchResult[]> => {
  //   try {
  //     const searchForUser = axios.get(`${backendURL}/site/search/users`, {
  //       params: { searchTerm: searchTerm },
  //     });
  //     const searchForCategory = axios.get(
  //       `${backendURL}/site/search/categories`,
  //       { params: { searchTerm: searchTerm } }
  //     );
  //     const searchForFusion = axios.get(`${backendURL}/site/search/fusions`, {
  //       params: { searchTerm: searchTerm },
  //     });
  //     // Added this line
  //     console.log(
  //       'search results \n',
  //       'user : ',
  //       searchForUser,
  //       '\n category: ',
  //       searchForCategory,
  //       '\n fusions : ',
  //       searchForFusion
  //     );
  //     const [userResponse, categoryResponse, fusionResponse] =
  //       await Promise.all([searchForUser, searchForCategory, searchForFusion]); // Added blocksResponse

  //     const users = userResponse.data.results.map((user: any) => ({
  //       ...user,
  //       _type: 'user',
  //     }));
  //     const categories = categoryResponse.data.results.map((category: any) => ({
  //       ...category,
  //       _type: 'category',
  //     }));
  //     const fusions = fusionResponse.data.results.map((fusion: any) => ({
  //       ...fusion,
  //       _type: 'fusion',
  //     }));

  //     console.log(
  //       'search results \n',
  //       'user : ',
  //       users,
  //       '\n category: ',
  //       categories,
  //       '\n fusions : ',
  //       fusions
  //     );

  //     return [...users, ...categories, ...fusions]; // Added blocks
  //   } catch (err) {
  //     console.log('Error searching: ', err);
  //     return []; // Return empty array in case of error
  //   }
  // };

  // const renderBadge = (type: string) => {
  //   const badgeClass = 'inline-block ml-2 px-2 py-1 text-xs rounded-md';

  //   switch (type) {
  //     case 'user':
  //       return (
  //         <span className={`${badgeClass} bg-blue-500 text-white`}>User</span>
  //       );
  //     case 'category':
  //       return (
  //         <span className={`${badgeClass} bg-green-500 text-white`}>
  //           Category
  //         </span>
  //       );
  //     case 'fusion':
  //       return (
  //         <span className={`${badgeClass} bg-purple-500 text-white`}>
  //           Fusion
  //         </span>
  //       );
  //     case 'blocks':
  //       return (
  //         <span className={`${badgeClass} bg-red-500 text-white`}>Blocks</span>
  //       ); // Uncommented this line
  //     default:
  //       return null;
  //   }
  // };

  // const renderSearchResults = () => {
  //   if (searchResults.length === 0) {
  //     if (searchTerm.length > 2) {
  //       return (
  //         <div className='search-results bg-white p-4 rounded shadow'>
  //           <p className='text-gray-600'>
  //             No results found for "{searchTerm}".
  //           </p>
  //         </div>
  //       );
  //     }
  //     return null;
  //   }

  //   return (
  //     <div className='search-results bg-white border border-gray-200 rounded shadow-md absolute mt-1 w-full h-48 overflow-y-scroll'>
  //       {searchResults.map(result => {
  //         let link = '/';
  //         if (result._type === 'user') {
  //           link = `/profile/${result._id}`;
  //         } else if (result._type === 'category') {
  //           link = `/page-collection/${result.name}`;
  //         } else if (result._type === 'fusion') {
  //           link = `/fusion/${result._id}`;
  //         } else if (result._type === 'blocks') {
  //           link = `/blocks/${result._id}`; // You need to make sure this link is correct
  //         }

  //         return (
  //           <Link
  //             key={result._id}
  //             to={link}
  //             className='block px-4 py-2 hover:bg-gray-100 hover:text-gray-900 text-black flex items-center justify-between text-xs'
  //             onClick={handleLinkClick}
  //           >
  //             {result.username} {/* Change this line */}
  //             {renderBadge(result._type)}
  //           </Link>
  //         );
  //       })}
  //     </div>
  //   );
  // };

  const [showPayPage, setShowPayPage] = useState<boolean>(false);

  const { credit } = useCredit();
  const userId = user()?.id;

  // const { setReferAFriendModalOpen } = useContext(HeaderContext);

  const isTouchScreen = window.matchMedia('(pointer: coarse)').matches;
  // console.log(isTouchScreen);
  return (
    <div className={`nc-MainNav2Logged relative z-10 ${'onTop '}`}>
      <div className='container py-5 relative flex justify-between items-start md:items-center space-x-4 xl:space-x-8'>
        <div className='flex flex-col md:flex-row justify-start flex-grow items-start md:items-center space-x-3'>
          <LogOutCheck />
          <Logo />
          <Navigation />

          {/*
          <div className="hidden sm:block flex-grow max-w-xs">

            <form className="relative">

            <div>
            <Input
                type="search"
                placeholder="Search items"
                value={searchTerm}
                onChange={handleSearchInputChange}
                className="pr-10 w-full"
                sizeClass="h-[42px] pl-4 py-3"
              />
               {renderSearchResults()}
            </div>
             
              <span className="absolute top-1/2 -translate-y-1/2 right-3 text-neutral-500">
                <svg
                  className="h-5 w-5"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M22 22L20 20"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              <input type="submit" hidden value="" />
            </form>


  </div>*/}
        </div>
        <div className='flex-shrink-0 flex mt-2 md:mt-0 items-center justify-end text-neutral-700 dark:text-neutral-100 space-x-1'>
          <div className='items-center xl:flex space-x-2'>
            {/* <div className="hidden sm:block h-6 border-l border-neutral-300 dark:border-neutral-6000"></div>
            <div className="flex">
              <SwitchDarkMode />
              <NotifyDropdown />
            </div>

  <div></div>*/}

            {/*
            <Link to={"/fuser/0"}>
            <ButtonPrimary
              sizeClass="px-4 py-2 sm:px-5"
            >
              Fuser
            </ButtonPrimary>
            </Link>
            <div></div>


            <AvatarDropdown />  */}
          </div>

          <div
            className={`flex items-center space-x-3${
              isTouchScreen ? '' : ' lg:hidden'
            }`}
          >
            {/*<NotifyDropdown />*/}
            {/*<AvatarDropdown />*/}
            <MenuBar />
          </div>
        </div>
      </div>
      {credit !== undefined &&
        credit <= 0.1 &&
        userId &&
        !url.split('/').includes('buy-credits') && (
          <div className='flex flex-col md:flex-row gap-2 items-center justify-center'>
            <h1 className='text-center text-2xl font-black'>
              It looks like you are enjoying Skillfusion! Buy more credits to
              continue.
            </h1>
            <button
              onClick={() => {
                navigate(`/buy-credits/${user()?.id}`);
                //setShowPayPage(true);
              }}
              className='text-3xl font-black border-black border-2 p-1 px-2 rounded-xl text-align-center'
            >
              Buy more
            </button>
            {/*<h1 className='text-center text-3xl font-black'>or</h1>
            <button
              className='text-3xl font-black border-black border-2 p-1 px-2 rounded-xl text-align-center'
              onClick={() => setReferAFriendModalOpen(true)}
            >
              Refer a friend
            </button>*/}
          </div>
        )}
      {showPayPage && (
        <div className='share-modal fixed inset-0 flex justify-center'>
          <div className='absolute inset-0 bg-black opacity-50 z-10'></div>
          <div className='relative z-20'>
            <div className='m-auto flex items-center justify-center p-4'>
              <div className='bg-white p-4 rounded shadow-md m-auto max-w-[90vw] z-20'>
                <div className='flex flex-col gap-4'>
                  <div>
                    <button
                      className='sticky left-0 top-0'
                      onClick={() => setShowPayPage(false)}
                    >
                      <MdClose />
                    </button>
                  </div>
                  <div className='p-x-8 justify-between gap-4 overflow-y-auto max-w-full max-h-[87vh]'>
                    <PagePay
                      inModal={true}
                      onPayment={() => setShowPayPage(false)}
                    />
                  </div>
                  <div className='flex gap-2 items-center'></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MainNav2Logged;
