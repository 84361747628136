import React from 'react';
import ReactDOM from 'react-dom/client';
//
import './styles/index.scss';
import './index.css';
import './fonts/line-awesome-1.3.0/css/line-awesome.css';
import 'rc-slider/assets/index.css';

//
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { persistor, store } from './app/store';
import { PersistGate } from 'redux-persist/integration/react';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import RedditPixel from 'react-reddit-pixel';

// reddit
RedditPixel.init('a2_dx62bkev0fjf');
RedditPixel.pageVisit();

// Initialize GA4 with your Measurement ID
ReactGA.initialize('G-6TP0SCH9Y7');

// Track the initial pageview
ReactGA.event('page_view', {
  page_path: window.location.pathname + window.location.search,
});

const options = {
  autoConfig: true,
  debug: false,
};
ReactPixel.init('1273471480198682', undefined, options);
ReactPixel.track('PageView', { path_name: window.location.pathname });

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate
      loading={null}
      persistor={persistor}
    >
      {/* <p style={{backgroundColor:"lightblue",textAlign:"center"}}>Currently down for maintenance for the next hour</p>*/}
      <App />
    </PersistGate>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
