import Label from '../../components/Label/Label';
import React, { FC, useRef, useState, useEffect } from 'react';
import ButtonPrimary from '../../shared/Button/ButtonPrimary';
import Input from '../../shared/Input/Input';
import Textarea from '../../shared/Textarea/Textarea';
import { Helmet } from 'react-helmet';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import NcImage from '../../shared/NcImage/NcImage';
import { nftsImgs } from '../../constants/fakeData';
import { Avatar3ds } from '../../constants/fakeData';
import { useAuthHeader, useAuthUser } from 'react-auth-kit';
import { backendURL } from '../../constants/environmental';
import axios, { AxiosError } from 'axios';
// import { AiOutlineSend } from 'react-icons/ai';
// import { errorMonitor } from 'events';
// import Avatar from '../../shared/Avatar/Avatar';
// import { UserSchema } from '../../../TypeScript/Interfaces';
// import AccountSidebar from './AccountSidebar';

export interface AccountPageProps {
  className?: string;
}

const AccountPage: FC<AccountPageProps> = ({ className = '' }) => {
  const navigate = useNavigate();
  const user = useAuthUser();
  const userId = user()?.id;
  // const [isCreator, setIsCreator] = useState<Boolean>(false);

  const authHeader = useAuthHeader()();

  // useEffect(() => {
  //   const fetchUserIsCreator = async () => {
  //     try {
  //       const response = await axios.get(
  //         `${backendURL}/user/isCreator/${userId}`
  //       );
  //       const data = await response.data;
  //       setIsCreator(data.isCreator);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  //   fetchUserIsCreator();
  // }, [userId]);

  // const handleButtonClick = () => {
  //   if (isCreator) {
  //     navigate(`/fuser`);
  //   } else {
  //     navigate(`/creator-form`);
  //   }
  // };

  // const buttonText = isCreator ? 'Create New Tool' : 'Register As Creator';

  // const boilerplates = [
  //   {
  //     icon: Avatar3ds[14],
  //     label: 'Default',
  //     value: 'Something about yourself in a few words.',
  //   },
  //   {
  //     icon: Avatar3ds[13],
  //     label: 'AI Fan',
  //     value:
  //       'Passionate about technology and always exploring new possibilities.',
  //   },
  //   {
  //     icon: Avatar3ds[2],
  //     label: 'Starup Owner',
  //     value:
  //       'Passionate about technology and always exploring new possibilities.',
  //   },
  //   {
  //     icon: Avatar3ds[8],
  //     label: 'Programmer',
  //     value:
  //       'Passionate about technology and always exploring new possibilities.',
  //   },
  //   {
  //     icon: Avatar3ds[5],
  //     label: 'Lawyer',
  //     value:
  //       'Passionate about technology and always exploring new possibilities.',
  //   },
  //   {
  //     icon: Avatar3ds[1],
  //     label: 'Doctor',
  //     value:
  //       'Passionate about technology and always exploring new possibilities.',
  //   },
  //   {
  //     icon: Avatar3ds[11],
  //     label: 'Scientist / Data Scientist',
  //     value:
  //       'Passionate about technology and always exploring new possibilities.',
  //   },
  //   // Add more boilerplate options as needed
  // ];

  // Pre-fill the form fields with user data
  const [displayName, setDisplayName] = useState<string>('');

  const [email, setEmail] = useState<string>('');
  const [bio, setBio] = useState<string>('');

  const [fetchingUserDetails, setFetchingUserDetails] = useState(true);
  const [uploadedImage, setUploadedImage] = useState<string>('');
  const [coverPhoto, setCoverPhoto] = useState<string>('');
  const [imageUploadStatusMessage, setImageUploadStatusMessage] = useState('');
  // const [profileImagePrompt, setProfileImagePrompt] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [statusMessage, setStatusMessage] = useState<string>('');

  const handleImageChange =
    (imageDestination: string) =>
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      console.log('handling image change');
      if (e.target.files && e.target.files[0]) {
        setLoading(true);
        const file = e.target.files[0];
        const validFileTypes = ['png', 'jpeg', 'jpg'];
        if (
          !validFileTypes
            .map((type: string) => `image/${type}`)
            .includes(file.type)
        ) {
          setImageUploadStatusMessage(
            `Invalid file type, must be one of: ${validFileTypes.join(', ')}`
          );
          return setLoading(false);
        }
        if (file.size >= 1048576) {
          setImageUploadStatusMessage('Sorry, this image file is too large');
          return setLoading(false);
        }
        const reader = new FileReader();
        reader.onloadend = async () => {
          if (reader.result) {
            try {
              // Convert to Data URL for Cloudinary upload
              const dataUrl = reader.result as string;
              // setImageUrl(dataUrl); // Update the image preview here
              if (imageDestination === 'profileImage')
                setUploadedImage(dataUrl);
              else if (imageDestination === 'coverPhoto')
                setCoverPhoto(dataUrl);
              await handleImageSave({
                imageDataURL: dataUrl,
                userDetailsKey: imageDestination,
              });
            } catch (e) {
              setImageUploadStatusMessage(
                'Sorry, there was an error uploading your image'
              );
            } finally {
              setLoading(false);
              // clear value of file input so that if the upload fails the user can attempt to upload the same image
              if (
                imageDestination === 'profileImage' &&
                profileImageInputRef?.current
              ) {
                profileImageInputRef.current.value = '';
              }
              if (
                imageDestination === 'coverPhoto' &&
                coverPhotoInputRef?.current
              ) {
                coverPhotoInputRef.current.value = '';
              }
            }
          }
        };
        reader.readAsDataURL(file);
      }
      e.target.files = null;
    };

  const [cookies, setCookie] = useCookies(['_auth_state']);

  async function handleImageSave({ imageDataURL, userDetailsKey }: any) {
    setImageUploadStatusMessage('Saving image...');

    if (!['coverPhoto', 'profileImage'].includes(userDetailsKey)) {
      console.log('Invalid user details key');
      return;
    }

    try {
      const saveResponse = await axios.patch(
        `${backendURL}/user/${userId}/${userDetailsKey}`,
        { imageDataURL },
        {
          headers: {
            Authorization: authHeader,
            'Content-Type': 'application/json',
          },
        }
      );

      setImageUploadStatusMessage(
        userDetailsKey === 'profileImage'
          ? 'Image saved (it may take a while for the old images to update on your current device, but you can log out and back in to force an update).'
          : 'Image saved'
      );
      setCookie('_auth_state', {
        ...cookies._auth_state,
        profileImage: saveResponse.data.cloudinaryImageURL,
      });
    } catch (error) {
      console.log(error);
      setImageUploadStatusMessage(
        error?.response?.data?.msg ?? 'Error saving image'
      );
    }
  }

  // const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   if (e.target.files && e.target.files[0]) {
  //     console.log(e.target.files[0]);
  //     setUploadedImage('');
  //   }
  // };

  // const handleCoverChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   if (e.target.files && e.target.files[0]) {
  //     setCoverPhoto(e.target.value[0]);
  //   }
  // };

  // We need a handle submit that will allow us to update the sanity user we just fetched

  useEffect(() => {
    setLoading(true);
    try {
      const fetchUserDetails = async () => {
        try {
          const response = await axios.get(`${backendURL}/user/${userId}`, {
            headers: {
              Authorization: authHeader,
            },
          });
          //console.log('This is the user details ', response);
          // Set the user details in the state variables
          setDisplayName(response.data?.name ?? response.data.username);
          // data.username is the email address
          setEmail(response.data.username);
          setBio(response.data.description);
          // setWebsite(response.data.socialLinks.website);

          setUploadedImage(response.data.profileImage);
          setCoverPhoto(response.data.coverPhoto);
        } catch (err) {
          setError(
            'Error fetching user details, please try again in a few minutes'
          );
          console.log('Error fetching user details', err);
        } finally {
          setFetchingUserDetails(false);
          setLoading(false);
        }
      };

      fetchUserDetails();
    } catch (err) {
      console.log('There was an error fetching user details', err);
    }
  }, []);

  useEffect(
    () => setStatusMessage(''),
    [displayName, email, bio, uploadedImage, coverPhoto]
  );

  // Handler for form submission
  const handleSubmit = async (event: React.FormEvent) => {
    setImageUploadStatusMessage('');
    setStatusMessage('Updating profile...');

    event.preventDefault();

    console.log('Update user');
    //  here we need to grab all the credentials from the form below
    setLoading(true);
    // and submit them as an axios request to update the user within our mongo db

    const formData = {
      name: displayName,
      email: email,
      description: bio,
    };

    try {
      const response = await axios.patch(
        `${backendURL}/user/details/${userId}`,
        formData,
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );
      console.log(response);

      setStatusMessage('Profile successfully updated');
    } catch (err: any) {
      //console.log(err);
      const errorMessage =
        err?.response?.data?.message ??
        err?.response?.data?.error?.message ??
        err?.response?.data?.error;
      setStatusMessage(
        errorMessage ? `Error: ${errorMessage}` : 'Failed to update profile'
      );
      setError(
        `Error updating user data please try again in a few minutes ${err}`
      );
      console.log('There was an error updating the info ', err);
    }

    setLoading(false);
  };

  /*

  There is a better version of this that uses the PayPalSection component

  const { createOrder, onApprove } = usePayPal(); // Use the custom hook

  const AttemptPaypalPurchase = async () => {
    try {
      const response = await createOrder([
        {
          id: 'YOUR_PRODUCT_ID',
          quantity: 'YOUR_PRODUCT_QUANTITY',
        },
      ]);

      // Call PayPal.Buttons with the createOrder and onApprove functions
      window.paypal.Buttons({
        createOrder: () => response.orderID,
        onApprove,
      }).render('#paypal-button-container');
    } catch (error) {
      console.error(error);
    }
  };
  */
  const profileImageInputRef = useRef<HTMLInputElement>(null);
  const coverPhotoInputRef = useRef<HTMLInputElement>(null);

  const handleResetPasswordClick = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      const response = await axios.post(`${backendURL}/user/forgot-password`, {
        code: '',
        userId,
      });
      if (response.status === 200) {
        navigate(`/reset-password/${userId}`, {
          state: {
            instructions:
              'You have been sent an email with a 12 digit code. Please enter the code here, or alternatively follow the instructions in the email.',
          },
        });
      }
    } catch (error) {
      console.error('Error sending password reset link', error);
      setStatusMessage('There was a problem sending your reset code');
    }
  };

  console.log('fetchingUserDetails', fetchingUserDetails);

  if (fetchingUserDetails)
    return (
      <div className='border-2 text-center h-48 flex items-center justify-center'>
        Loading details...
      </div>
    );
  else
    return (
      <form
        onSubmit={handleSubmit}
        className={`nc-AccountPage ${className}`}
        data-nc-id='AccountPage'
      >
        <Helmet>
          <title>Profile Settings- Skillfusion</title>
        </Helmet>

        <div className='container'>
          <div className='my-12 sm:lg:my-16 lg:my-24 max-w-4xl mx-auto space-y-8 sm:space-y-10'>
            {/* HEADING */}
            <div className='max-w-2xl'>
              <h2 className='text-3xl sm:text-4xl font-semibold'>
                Profile settings
              </h2>

              <span className='block mt-3 text-neutral-500 dark:text-neutral-400'>
                {/* You can set preferred display name, create your profile URL and
              manage other personal settings. */}
              </span>
            </div>

            <div className='w-full border-b-2 border-neutral-100 dark:border-neutral-700'></div>

            <div className='flex flex-col md:flex-row'>
              <div className='w-1/2 flex-shrink-0 flex items-start flex-row flex-col items-center justify-between'>
                <div className='gap-4 flex flex-col items-start justify-between'>
                  <p className='text-xl'>{imageUploadStatusMessage}</p>

                  <div className='flex gap-2'>
                    <Label>Profile picture:</Label>
                    <NcImage
                      src={uploadedImage || nftsImgs[3]}
                      containerClassName='hover:cursor-pointer w-full h-full object-cover transition-all duration-300 transform hover:scale-105'
                      className='w-full h-full object-cover'
                      onClick={() => profileImageInputRef.current?.click()}
                    />
                    <div className='relative rounded-full overflow-hidden flex w-10 h-10'>
                      <input
                        type='file'
                        className='absolute inset-0 opacity-0 cursor-pointer'
                        name='profileImage'
                        onChange={handleImageChange('profileImage')}
                        ref={profileImageInputRef}
                      />
                    </div>
                  </div>
                  <div className='flex gap-2'>
                    <Label>Cover picture:</Label>

                    <NcImage
                      src={coverPhoto || nftsImgs[3]}
                      containerClassName='hover:cursor-pointer w-full h-full object-cover transition-all duration-300 transform hover:scale-105'
                      className='w-full h-full object-cover'
                      onClick={() => coverPhotoInputRef.current?.click()}
                    />
                    <div className='relative rounded-full overflow-hidden flex w-10 h-10'>
                      <input
                        type='file'
                        name='coverPhoto'
                        className='absolute inset-0 opacity-0 cursor-pointer'
                        onChange={handleImageChange('coverPhoto')}
                        ref={coverPhotoInputRef}
                      />
                    </div>
                  </div>
                </div>
                {/*
                <div>
                  <Label>Profile Image Generator</Label>
                  <div className='mt-1.5 relative text-neutral-700 dark:text-neutral-300'>
                    <Input
                      className='!pr-10 '
                      onChange={e => {
                        setProfileImagePrompt(e.target.value);
                      }}
                      value={profileImagePrompt}
                      placeholder='Prompt...'
                    />

                    <span className='absolute right-2.5 cursor-pointer top-1/2 -translate-y-1/2 '>
                      <AiOutlineSend className='w-6 h-6 hover:text-blue-400 ' />
                    </span>
                  </div>


                  <ButtonPrimary
                    onClick={handleButtonClick}
                    className='w-full '
                  >
                    {buttonText}
                  </ButtonPrimary>
                  <ButtonPrimary className=" mt-2 ">Update password</ButtonPrimary>
                </div>
                */}
              </div>

              <div className='flex-grow mt-10 md:mt-0 md:pl-16 max-w-3xl space-y-5 sm:space-y-6 md:sm:space-y-7'>
                {/* ---- */}
                <div>
                  <div className='flex gap-2 items-center justify-start'>
                    <Label>Display Name</Label>
                    <span
                      onClick={handleResetPasswordClick}
                      className='hover:text-underline hover:text-red-300 cursor-pointer text-xs'
                    >
                      Reset Your Password?
                    </span>
                  </div>

                  <Input
                    className='mt-1.5'
                    value={displayName}
                    onChange={e => setDisplayName(e.target.value)}
                  />
                </div>

                {/* ---- */}
                <div>
                  <Label>Email</Label>
                  <div className='mt-1.5 flex'>
                    <span className='inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm'>
                      <i className='text-2xl las la-envelope'></i>
                    </span>
                    <Input
                      onChange={e => setEmail(e.target.value)}
                      className='!rounded-l-none'
                      placeholder='example@email.com'
                      value={email}
                    />
                  </div>
                </div>

                {/* ---- */}
                <div>
                  <Label className='px-2'>Bio</Label>
                  {/* <div className='border w-full flex items-center justify-between rounded-t-lg'>
                  <div className='w-3/4 px-1 h-full rounded-right-lg bg-neutral-100 flex items-center justify-end  dark:bg-neutral-800 rounded-lg'>
                    {boilerplates.map((boilerplate, index) => {
                      return (
                        <div
                          className='hover:bg-neutral-200 w-10 cursor-pointer'
                          key={index}
                        >
                          <img
                            src={boilerplate.icon}
                            className='w-8 h-8'
                          />
                        </div>
                      );
                    })}
                  </div>
                </div> */}

                  <Textarea
                    rows={5}
                    className='rounded-lg'
                    placeholder='Something about yourself in a few words'
                    value={bio}
                    onChange={e => setBio(e.target.value)}
                  />
                </div>

                {/* ---- */}
                <div className='pt-2 flex flex-col gap-4 items-center'>
                  {/* <ButtonPrimary className="w-full ">Update password</ButtonPrimary> */}
                  <ButtonPrimary
                    type='submit'
                    className='w-full mt-2'
                  >
                    Update profile
                  </ButtonPrimary>
                  <p className='text-right'>{statusMessage}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
};

export default AccountPage;
