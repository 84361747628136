import React, { FC, useContext, useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import FuserContext from "../../context/FuserContext";
import axios from "axios";
import { useAuthHeader } from "react-auth-kit";
import { backendURL } from "../../constants/environmental";
import { testButtonStyles } from "../../constants/styles";
import { ensureNotArray } from "../../utils/array";

const ShareModal: FC<any> = ({ isOpen, onClose }) => {
  const [copyLinkButtonText, setCopyLinkButtonText] = useState("Copy Link");
  const [addToProfile, setAddToProfile] = useState(true);
  const [customTitle, setCustomTitle] = useState("");
  const authHeader = useAuthHeader()();

  const {
    blockToShare,
    setShareModalOpen,
    shareMessage,
    setShareMessage,
    toolId,
    blocks,
    replacePlaceholders,
  } = useContext(FuserContext);

  const [shareLink, setShareLink] = useState<string | undefined>();

  const sharedBlockId = shareLink?.split("/")?.at(-1);

  const addTextToClipboard = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => setCopyLinkButtonText("Copied!"))
      .catch(() => setCopyLinkButtonText("Copy failed :'("));
  };

  const saveSharedBlockOptions = async () => {
    try {
      const saveResponse = await axios.patch(
        `${backendURL}/blocks/shared/${sharedBlockId}`,
        { linkToProfile: addToProfile, customTitle },
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );
    } catch (error) {
      console.log("Error saving shared block options");
    }
  };

  useEffect(() => {
    if (!shareLink) return;
    saveSharedBlockOptions();
  }, [addToProfile, customTitle]);

  useEffect(() => {
    const timeoutId = setTimeout(
      () => setCopyLinkButtonText("Copy Link"),
      1500
    );
    return () => clearTimeout(timeoutId);
  }, [copyLinkButtonText]);

  const closeModal = () => {
    setShareMessage("");
    setAddToProfile(true);
    setCustomTitle("");
    setShareLink(undefined);
    onClose();
  };

  const shareBlock = async () => {
    setShareBlockButtonText("Generating link...");
    try {
      // console.log(block);
      if (!toolId) {
        return alert("Please save the tool first.");
      }

      const blockDataPayload: any = {
        toolId,
        blockType:
          blockToShare.blocktype ?? blockToShare.type?.replace("blockdiv-", ""),
        index: blocks.findIndex((block: any) => block === blockToShare),
        inputToProcess: blockToShare.data.inputToProcess,
        response: blockToShare.data.response,
        linkToProfile: addToProfile,
        //temporaryImageURLs: imageURLsForBlockWithId[block.id],
      };
      if (blockToShare.data.message) {
        blockDataPayload.blockHeading = ensureNotArray(
          replacePlaceholders(blockToShare.data.message, blocks)
        );
      }
      if (blockToShare.data.imageMessage) {
        blockDataPayload.blockHeading = blockToShare.data.imageMessage;
      }

      const shareResponse = await axios.post(
        `${backendURL}/blocks/shared`,
        blockDataPayload,
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );
      setShareLink(
        `https://skillfusion.ai/sharedBlock/${shareResponse.data.sharedBlockId}`
      );
      if (shareResponse.data.message) {
        setShareMessage(shareResponse.data.message);
      }
      if (shareResponse.data.linkToProfile) {
        setAddToProfile(shareResponse.data.linkToProfile);
      }
      if (shareResponse.data.customTitle) {
        setCustomTitle(shareResponse.data.customTitle);
      }
      setShareModalOpen(true);
    } catch (error) {
      console.log("Error sharing block:", error);
      if ([403, 404, 428].includes(error.response.status)) {
        setShareMessage(error.response.data.error);
        setShareModalOpen(true);
        setShareLink("");
      }
    } finally {
      setShareBlockButtonText("Share block now");
    }
  };

  const [shareBlockButtonText, setShareBlockButtonText] =
    useState("Share block now");

  return (
    isOpen && (
      <div className="share-modal fixed inset-0 flex items-center justify-center">
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="bg-white p-4 rounded shadow-md w-5/6 lg:w-2/3">
            <div className="flex flex-col gap-4">
              <div className="flex justify-between gap-4">
                <h1 className="text-lg font-bold">Share this block</h1>
                <button
                  //className='border border-1 rounded-lg px-1'
                  onClick={closeModal}
                >
                  <MdClose />
                </button>
              </div>
              {shareMessage && <p>{shareMessage}</p>}
              <div className="flex gap-2 items-center">
                <input
                  type="checkbox"
                  checked={addToProfile}
                  onChange={() =>
                    setAddToProfile((currentState) => !currentState)
                  }
                />{" "}
                <label>Add a link to your profile</label>
              </div>
              <div className="flex gap-2 items-center">
                <label>Custom Title (optional):</label>{" "}
                <input
                  className="flex-grow overflow-x-auto whitespace-nowrap border border-black border-1 rounded-lg p-1"
                  value={customTitle}
                  onChange={({ target: { value } }) => setCustomTitle(value)}
                />
              </div>
              {!shareLink && (
                <div className="flex gap-2 justify-center">
                  <button
                    className={
                      "border border-black border-1 rounded-lg p-1 px-2 w-max hover:bg-neutral-200"
                    }
                    onClick={shareBlock}
                  >
                    {shareBlockButtonText}
                  </button>
                </div>
              )}
              {shareLink && (
                <>
                  <div>Here is the link to your shared block:</div>
                  <div className="flex gap-2 items-center">
                    <span className="flex-grow overflow-x-auto whitespace-nowrap border border-black border-1 rounded-lg p-1">
                      {shareLink}
                    </span>

                    <button
                      className={
                        testButtonStyles +
                        " shrink-0 justify-self-center border border-black border-1 rounded-lg p-1 px-2"
                      }
                      onClick={() => addTextToClipboard(shareLink)}
                    >
                      {copyLinkButtonText}
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default ShareModal;
