import React, {
  FC,
  useState,
  ChangeEvent,
  useEffect,
  useRef,
  useMemo,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Helmet } from 'react-helmet';
//import { Tooltip }  from "react-tooltip";
//import 'react-tooltip/dist/react-tooltip.css'
import 'firebase/auth';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuthUser, useAuthHeader } from 'react-auth-kit';
import Block from '../../models/Block';
import Theme from '../../models/Theme';
import FuserSidebar from './FuserSidebar';
import FuserMain from './FuserMain';
import FuserContext from '../../context/FuserContext';
import ReactGA from 'react-ga4';
import ShareModal from './ShareModal';
import { blockTypesExpandedByDefault } from '../../constants/blocks';
import { useCookies } from 'react-cookie';
import { backendURL } from '../../constants/environmental';
import { updateProperty } from '../../utils/object';
import {
  deleteAtIndex,
  ensureNotArray,
  excelRangeToSubarray,
  getArrayDimensions,
  updateAtIndex,
} from '../../utils/array';
import { hotjar } from 'react-hotjar';
import { excelColumnToNumber, numberToExcelColumn } from '../../utils/math';
hotjar.initialize({ id: 5031132, sv: 6 });
// hotjar.identify('USER_ID', { userProperty: 'value' });

export interface FuserPageProps {
  className?: string;
}

const themes: Theme[] = [
  { name: 'Red Theme', blockColor: 'bg-red-500', menuColor: 'bg-darkred' },
  { name: 'Blue Theme', blockColor: 'bg-blue-500', menuColor: 'bg-darkblue' },
  {
    name: 'Green Theme',
    blockColor: 'bg-green-500',
    menuColor: 'bg-darkgreen',
  },
  // Add more predefined themes
];

interface ProcessItem {
  matchedPlaceholder: string | null;
  block?: number;
  type?: string;
  placeholder: any;
  dimensions?: number;
  squareBracketsContent?: string;
}

const FuserPage: FC<FuserPageProps> = () => {
  const user = useAuthUser()();
  const authHeader = useAuthHeader()();

  const userId = user?.id ?? '';
  const [hasAccess, setAccess] = useState(false);
  const [hasEditorAccess, setHasEditorAccess] = useState(false);
  const [blocksHaveLoaded, setBlocksHaveLoaded] = useState(false);
  const location = useLocation();
  // check for a url ID
  let toolId = '';
  const path = location.pathname;
  const pathParts = path.split('/');
  const lastSegment = pathParts.pop() || pathParts.pop(); // handle potential trailing slash
  if (lastSegment !== '0' && lastSegment !== undefined) toolId = lastSegment;

  const navigate = useNavigate();
  const [blocks, setBlocks] = useState<Block[]>([]);
  const [isLoading, setIsLoading] = useState<boolean[]>([]); // New state for loading
  const [activityLog, setActivityLog] = useState<string[]>([]);

  // console.log(safeStringify(blocks));

  const [notification, setNotification] = useState<string | null>(null);
  const historyRef = useRef<Block[][]>([]);
  const historyIndexRef = useRef(-1);

  useEffect(() => {
    if (!user) {
      navigate(`/fusion/${toolId}`);
      // axios.get(`${backendURL}/redirectToToolDetailsPage/${toolId}`);
    }
  }, []);

  // RUNNER MODE

  /*
  useEffect(() => { // try runner mode each time blocks are updated
    //console.log("detected blocks updated:",blocks?.length," and ", runnerMode, ", ", waitingForUserInput);
    if (runnerMode && !waitingForUserInput) {
      runBlock(); 
    }
  }, [blocks, runnerMode, waitingForUserInput, isLoading]); 
  */
  // run blocks

  // const runBlock =
  //   (blockIndex: number) => async (runFunction: () => Promise<void>) => {
  //     console.log(`running block ${blockIndex}`);
  //     let runnerIndexTransformation: any = await runFunction();
  //     console.log(`block ${blockIndex} finished running`);
  //     if (runnerIndexTransformation === 'CANCEL')
  //       return setRunnerIndex(-Infinity);
  //     //console.log('runnerIndexTransformation', runnerIndexTransformation);
  //     if (runnerIndexTransformation === undefined)
  //       runnerIndexTransformation = (previous: number) => blockIndex + 1;

  //     //console.log('next index:', runnerIndexTransformation(runnerIndex));
  //     //setRunnerIndex(runnerIndexTransformation);
  //   };

  /*
  const runBlock = (blockIndex: number) => async (runFunction: () => Promise<void>) => {
    
    console.log("stillrunning in runBlock function",stillRunning);
    if(stillRunning) {
      
      let nextRunnerIndices: void | {current: number, previous: number} = await runFunction();

      console.log('runBlock: nextRunnerIndices = ', nextRunnerIndices);

      if (nextRunnerIndices === undefined) nextRunnerIndices = {
        current: blockIndex + 1,
        previous: blockIndex
      }
      
      setRunnerIndices(nextRunnerIndices); 
    }
    else {
      console.log("in runBlock && stillRunning == false");
    }
    
    //setRunnerMode(true);
  }
  */
  const [collapsedBlocks, setCollapsedBlocks] = useState<string[]>([]);

  const [runnerIndex, setRunnerIndex] = useState<number | undefined>(undefined);
  const [runnerIndexHistory, setRunnerIndexHistory] = useState<number[]>([]);

  useEffect(() => {
    if (runnerIndex === undefined) return;
    if (runnerIndex >= blocks.length) {
      // const indexOfFirstChatBlock = blocks.findIndex(
      //   ({ type }: any) => type === "blockdiv-chat"
      // );
      // if (indexOfFirstChatBlock !== -1) {
      // }
    } else {
      setRunnerIndexHistory(history => [...history, runnerIndex]);
    }
  }, [runnerIndex]);

  const [runnerMode, setRunnerMode] = useState(true);
  const [stillRunning, setStillRunning] = useState(true);

  // console.log('collapsed:', collapsedBlocks);

  const [blockCancellationState, setBlockCancellationState] = useState({
    cancellingBlocks: false,
    idsOfBlocksToCancel: [],
    idOfBlockToRunAfterwards: undefined,
  });

  const cancellingBlocksMemo = useMemo(
    () => blockCancellationState.cancellingBlocks,
    [blockCancellationState]
  );

  // const updatedBlocks = useMemo(
  //   () => blocks.map((block: Block) => block.updatedBlock),
  //   [blocks]
  // );
  useEffect(() => {
    for (let i = 0; i < blocks.length; i++) {
      if (blocks[i].updatedBlock) {
        // update blocks
        // console.log('block', i, 'has updated:', blocks);
        setBlocks(blocks =>
          blocks.map((block: any, blockIndex: number) => {
            if (blockIndex === i) {
              return { ...block, updatedBlock: false };
            } else return block;
          })
        );

        const indexOfFirstChatBlock = blocks.findIndex(
          ({ type }: any) => type === 'blockdiv-chat'
        );
        if (
          blocks[i].type === 'blockdiv-if-else' ||
          blocks[i].type === 'blockdiv-jump'
        ) {
          setRunnerIndex(+blocks[i].data.response[0]);
          break;
        }
        // once the end is reached go back to chat block if it exists
        if (indexOfFirstChatBlock !== -1) {
          /* if the only block is a chatblock, set the runner index to 1 since
             the block runner will only trigger after the runner index changes 
             to 0 from a different number, then the next effect below runs the 
             block again */
          if (blocks.length === 1) {
            if (runnerIndex === 0) {
              setRunnerIndex(1);
              setBlocks(blocks => {
                blocks[0].updatedBlock = true;
                return blocks;
              });
            }
            break;
          } else if (i + 1 >= blocks.length) {
            setRunnerIndex(indexOfFirstChatBlock);
            break;
          }
        }
        // if (runnerIndex != undefined && runnerIndex >= i) setRunnerIndex(i+1); // can change past blocks to where you are now but not future blocks
        setRunnerIndex(i + 1);
        // console.log('runner index updated ', i + 1);
        break;
      }
    }
  }, [blocks]);

  // this runs the chat block again after sending a message if it is the only block
  useEffect(() => {
    if (
      runnerIndex === 1 &&
      blocks.length === 1 &&
      blocks[0].type === 'blockdiv-chat'
    ) {
      setRunnerIndex(0);
    }
  }, [runnerIndex]);

  const saveRunnerModeBlocksData = async () => {
    try {
      // console.log('saving runnermode responses', blocks);
      // save the ids along with the responses in case the creator changes the tool structure
      const responses = Object.fromEntries(
        blocks.map(
          ({
            id,
            blocktype,
            data: {
              inputToProcess,
              response,
              checkedRadioValue,
              model,
              voice,
              speed,
              responseFormat,
              googleDocIds,
              temporaryExtraInfo,
              permanentExtraInfo,
              dontSendToOpenAI,
              nextMessageStartIndex,
              nextMessageEndIndex,
            },
          }: any) => [
            id,
            {
              response,
              checkedRadioValue,
              ...(blocktype === 'textToSpeech'
                ? { model, voice, speed, responseFormat }
                : {}),
              ...(blocktype === 'download' ? { googleDocIds } : {}),
              ...(blocktype === 'chat'
                ? {
                    inputToProcess,
                    temporaryExtraInfo,
                    permanentExtraInfo,
                    dontSendToOpenAI,
                    nextMessageStartIndex,
                    nextMessageEndIndex,
                  }
                : {}),
            },
          ]
        )
      );

      let lastRunnerIndex =
        runnerIndex !== undefined &&
        Number.isInteger(runnerIndex) &&
        0 <= runnerIndex &&
        runnerIndex < blocks.length
          ? runnerIndex
          : null;

      if (lastRunnerIndex === blocks.length - 1 && !stillRunning)
        lastRunnerIndex = null;

      // console.log('Saving lastRunnerIndex:', lastRunnerIndex);
      await axios.put(
        `${backendURL}/user/${userId}/${toolId}/runnerModeResponses`,
        { responses, lastRunnerIndex },
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );
      setRunnerModeBlocksData(responses);
    } catch (e) {
      console.log(e);
    }
  };

  const [autosaveMode, setAutosaveMode] = useState<boolean>(true);

  useEffect(() => {
    if (blocksHaveLoaded && runnerMode && autosaveMode) {
      saveRunnerModeBlocksData();
    }
  }, [runnerIndex, blocksHaveLoaded, runnerMode, autosaveMode]);

  useEffect(() => {
    // console.log('runner mode:', runnerMode);
  }, [runnerMode]);

  useEffect(() => {
    // console.log('still running:', stillRunning);
  }, [stillRunning]);

  const toggleRunnerMode = () => {
    // restart runner mode if creator finished last time
    // if (runnerMode && runnerIndex == blocks.length - 1)
    //   setWaitingForClearUnsavedResponses(true);
    setRunnerMode(runnerMode => !runnerMode);
  };

  const [waitingForClearUnsavedResponses, setWaitingForClearUnsavedResponses] =
    useState<boolean>(false);

  const onRunnerModeClick = () => {
    // updates the url, not sure if this is needed as only want runner mode when the file loads in runner mode i.e. its a user
    const params = new URLSearchParams(location.search);
    navigate({ search: params.toString() });

    if (runnerMode) {
      params.delete('runner');
      toggleRunnerMode();
      setBlocks(buildModeBlocks);
      setCollapsedBlocks([]);
      setLastSavedRunnerIndex(runnerIndex);
    } else {
      //console.log('onrunnermodeclick called');
      params.set('runner', 'true');

      if (typeof lastSavedRunnerIndex === 'number') {
        setCollapsedBlocks(
          blocks
            .filter((block, index) => {
              return (
                index > lastSavedRunnerIndex ||
                (block.collapseAfterRunning !== undefined
                  ? block.collapseAfterRunning
                  : blockTypesExpandedByDefault.includes(block.type)
                    ? false
                    : true)
              );
            })
            .map(({ id }) => id)
        );
      } else {
        // collapse all blocks except the first
        setCollapsedBlocks(blocks.slice(1).map(({ id }) => id));
      }

      // remove any blocks that have an unselected type
      const filterNonNullBlocks = (blocks: any) =>
        blocks.filter((block: any) => block.type !== '');
      setBlocks(filterNonNullBlocks);
      setBuildModeBlocks(filterNonNullBlocks(blocks));

      clearUnsavedResponses();
      setWaitingForClearUnsavedResponses(true);
      // runner mode is toggled in the next effect after all responses are cleared
    }
  };

  const clearUnsavedResponses = () => {
    const blockIdsOfRunnerModeBlocksData = Object.keys(runnerModeBlocksData);
    setBlocks((blocks: any) => {
      const newBlocks = blocks.map((block: any) => {
        const newData = blockIdsOfRunnerModeBlocksData.includes(block.id)
          ? runnerModeBlocksData[block.id]
          : { response: [] };
        return {
          ...block,
          responseCleared: true,
          data: {
            ...block.data,
            ...newData,
          },
        };
      });
      //console.log('new blocks:', newBlocks);
      return newBlocks;
    });
  };

  const [lastSavedRunnerIndex, setLastSavedRunnerIndex] =
    useState<any>(undefined);

  // starts runner mode once all responses are cleared
  useEffect(() => {
    if (
      waitingForClearUnsavedResponses &&
      blocks.every((block: any) => block.responseCleared)
    ) {
      //console.log('in waitingforclearresponse effect');
      setWaitingForClearUnsavedResponses(false);

      // delete responseCleared properties
      setBlocks((blocks: any) =>
        blocks.map((block: any) => {
          const { responseCleared, ...restOfBlock } = block;
          return restOfBlock;
        })
      );
      setRunnerMode(true);
      // console.log('last saved runner index', lastSavedRunnerIndex);
      setRunnerIndex(lastSavedRunnerIndex ?? 0);
      setStillRunning(true);
    }
  }, [waitingForClearUnsavedResponses, blocks]);

  const [restartQueued, setRestartQueued] = useState(false);

  const restartRunnerMode = () => {
    if (restartQueued) {
      return;
    }
    setRestartQueued(true);
    setStillRunning(false);
  };

  const anyBlocksLoading = isLoading.some(loading => loading);

  // console.log({ restartQueued });

  // restart when no blocks are loading and a restart has been requested
  useEffect(() => {
    if (!restartQueued || anyBlocksLoading) {
      return;
    }
    // may need to clear updatedblock properties too
    setStillRunning(true);
    setRunnerIndex(0);
    setRestartQueued(false);
  }, [anyBlocksLoading, restartQueued]);

  const pauseRunnerMode = (index?: number): void => {
    setStillRunning(false);
    //console.log('pause runner mode ran');

    //setRunnerIndices({current: 9999,previous: runnerIndices.previous});
    //console.log("runnerIndices in pauseRunnerMode function",runnerIndices);
  };

  const resumeRunnerMode = (index?: number): void => {
    //setRunnerIndices({current: (runnerIndices.previous || 0)+1,previous: runnerIndices.previous});
    setStillRunning(true);
    //const continueRunning = runBlock((runnerIndices.previous || 0)+1);
    //console.log("resumeRunnerMode finished, should be running run block now???");
  };

  // UNDO AND REDO FUNCTIONALITIES
  useEffect(() => {
    // Add to history only if we're at the "latest" state.
    if (historyIndexRef.current === historyRef.current.length - 1) {
      historyRef.current.push([...blocks]);
      historyIndexRef.current++;
    }
  }, [blocks]);

  function undoChanges() {
    if (historyIndexRef.current > 0) {
      historyIndexRef.current--;
      setBlocks(historyRef.current[historyIndexRef.current]);
      setNotification('Undoing change');
      setTimeout(() => {
        setNotification(null);
      }, 1000);
    }
  }

  function redoChanges() {
    if (historyIndexRef.current < historyRef.current.length - 1) {
      historyIndexRef.current++;
      setBlocks(historyRef.current[historyIndexRef.current]);
      setNotification('Redoing change');
      setTimeout(() => {
        setNotification(null);
      }, 1000);
    }
  }

  // useEffect(() => {
  //   const keyDownHandler = (event: KeyboardEvent) => {
  //     if (event.ctrlKey && event.key === 'z') {
  //       event.preventDefault();
  //       undoChanges();
  //     } else if (event.ctrlKey && event.key === 'r') {
  //       event.preventDefault();
  //       redoChanges();
  //     }
  //   };

  //   window.addEventListener('keydown', keyDownHandler);

  //   // cleanup function
  //   return () => {
  //     window.removeEventListener('keydown', keyDownHandler);
  //   };
  // }, [undoChanges, redoChanges]);

  //TITLE / DESCRIPTION / DETAILS TO SEND TO SANITY
  const [title, setTitle] = useState('');
  const [coverPhoto, setCoverPhoto] = useState<string | null>(``);
  const [price, setPrice] = useState<number>(0);
  const [priceFrequency, setPriceFrequency] = useState<string>('one-time');
  const [description, setDescription] = useState('');
  const [furtherInfo, setFurtherInfo] = useState('');

  const [categories, setCategories] = useState([]);

  const [chosenCategories, setChosenCategories] = useState([]);

  const handleTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const handleDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    //console.log(event.target.value);
    setDescription(event.target.value);
  };

  const handleFurtherInfoChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    //console.log(event.target.value);
    setFurtherInfo(event.target.value);
  };

  function tidyUpResponse(response: any) {
    if (response === undefined || response == null || !Array.isArray(response))
      return '';
    // describe the object
    let totalObjects = response.length;
    let tempResponseDescription = 'Response is ' + response.length + ' items ';
    if (Array.isArray(response[0])) {
      tempResponseDescription += 'x ' + response[0].length + ' items ';
      totalObjects = totalObjects * response[0].length;
      if (Array.isArray(response[0][0])) {
        tempResponseDescription += 'x ' + response[0][0].length + ' items ';
        totalObjects = totalObjects * response[0][0].length;
        if (Array.isArray(response[0][0][0])) {
          tempResponseDescription +=
            'x ' + response[0][0][0].length + ' items ';
          totalObjects = totalObjects * response[0][0][0].length;
          if (Array.isArray(response[0][0][0][0])) {
            tempResponseDescription +=
              'x ' + response[0][0][0][0].length + ' items ';
            totalObjects = totalObjects * response[0][0][0][0].length;
          }
        }
      }
      tempResponseDescription += ' = ' + totalObjects.toString() + ' items';
    }

    return tempResponseDescription;
  }

  // replaces all @blockName's with <indexOfBlockCalledblockName:output>
  // (if a block has that name, otherwise leaves it as is)
  const processAtSigns = (input: string) => {
    // console.log('process at signs input', input);

    // only allowing \w characters in block names
    return input
      ?.replace(
        /@Block(\d+)(?=\W|$)/g,
        (match, blockIndex) => `<${blockIndex}:output>`
      )
      ?.replace(/@(\w+)/g, (match, captureGroup1) => {
        const blockIndex = blocks
          .map(({ data: { name } }) => name)
          .indexOf(captureGroup1);
        if (blockIndex !== -1) return `<${blockIndex}:output>`;
        else return match;
      });
  };

  function evaluatePlaceholder(promptText: string, blocks: Block[]): any {
    if (!Array.isArray(blocks)) {
      console.log('Blocks argument missing in evaluatePlaceholder');
    }
    promptText = processAtSigns(promptText);
    const toProcess: ProcessItem[] = [];
    const regex = /<(\d+):(output|input)>/g;
    let match: RegExpExecArray | null;

    while ((match = regex.exec(promptText)) !== null) {
      const temp_toPush: ProcessItem = {
        matchedPlaceholder: match[0],
        block: parseInt(match[1], 10),
        type: match[2],
        placeholder: null,
      };

      if (temp_toPush.type === 'output') {
        temp_toPush.placeholder = blocks[temp_toPush.block!].data.response;
        // console.log(
        //   'temp_topush, response currently: ',
        //   blocks[temp_toPush.block!].data.response,
        //   blocks[temp_toPush.block!].data.response.length
        // );
      }
      if (temp_toPush.type === 'input') {
        temp_toPush.placeholder =
          blocks[temp_toPush.block!].data?.processedInput ??
          blocks[temp_toPush.block!].data?.inputToProcess;
      }

      /*
      console.log("response should be in here:" , blocks[temp_toPush.block!])
      console.log("response should be in here:" , blocks)
      console.log("response should be in here:" , temp_toPush.block!)
      */

      temp_toPush.dimensions = getArrayDimensions(temp_toPush.placeholder);

      // console.log(temp_toPush)

      toProcess.push(temp_toPush);
    }

    if (toProcess.length == 0) {
      return promptText;
    }
    return toProcess[0].placeholder;

    /*
    if (toProcess.length == 0) return promptText;
    else {
      let result = promptText;
      for (const processItem of toProcess) {
        const { matchedPlaceholder, placeholder } = processItem;
        if (matchedPlaceholder !== null) {
          result.replace(matchedPlaceholder, placeholder);
        }
      }
      return result;
    }
    */
  }

  function replacePlaceholders(promptText: string, blocks: Block[]): any {
    if (!Array.isArray(blocks)) {
      console.log('Blocks argument missing in replacePlaceholders');
    }

    promptText = processAtSigns(promptText);
    const toProcess: ProcessItem[] = [];
    const regex = /<(\d+):(output|input)>(?:\[(.*?)\])?/g;
    let match: RegExpExecArray | null;

    //console.log('in replace placeholders');

    while ((match = regex.exec(promptText)) !== null) {
      const temp_toPush: ProcessItem = {
        matchedPlaceholder: match[0],
        block: parseInt(match[1], 10),
        type: match[2],
        placeholder: null,
      };

      const blockData = blocks?.[temp_toPush.block!]?.data;
      if (temp_toPush.type === 'output') {
        temp_toPush.placeholder = blockData?.response ?? '';
        if (blockData.response == undefined || blockData.response.length == 0) {
          temp_toPush.placeholder = '';
          //console.log('temp_to-push empty', temp_toPush.placeholder);
        }
      }
      if (temp_toPush.type === 'input') {
        temp_toPush.placeholder =
          blockData?.processedInput ?? blockData?.inputToProcess ?? '';
        // console.log({
        //   data: blocks?.[temp_toPush.block!]?.data,
        // });
      }

      // console.log(
      //   'temp_toPush',
      //   temp_toPush.placeholder,
      //   blocks[temp_toPush.block!].data.response.length == 0
      // );

      /*
      console.log("response should be in here:" , blocks[temp_toPush.block!])
      console.log("response should be in here:" , blocks)
      console.log("response should be in here:" , temp_toPush.block!)
      */

      let squareBracketsContent = match[3];
      if (squareBracketsContent) {
        let matchHeadings = true;

        if (blockData.useColumnHeadingReferences) {
          matchHeadings = false;

          const columnHeadings = temp_toPush.placeholder[0].sort(
            (heading1: string, heading2: string) =>
              heading2.length - heading1.length
          );

          const firstMatchedHeadingIndex = columnHeadings.findIndex(
            (heading: string) => squareBracketsContent.includes(heading)
          );
          const firstMatchedHeading = columnHeadings[firstMatchedHeadingIndex];

          if (firstMatchedHeadingIndex !== -1) {
            squareBracketsContent = squareBracketsContent.replace(
              firstMatchedHeading,
              numberToExcelColumn(firstMatchedHeadingIndex + 1)
            );
            console.log(numberToExcelColumn(firstMatchedHeadingIndex + 1), {
              squareBracketsContent,
            });
          }

          const secondMatchedHeadingIndex = columnHeadings.findIndex(
            (heading: string) => squareBracketsContent.includes(heading)
          );
          const secondMatchedHeading =
            columnHeadings[secondMatchedHeadingIndex];

          if (secondMatchedHeadingIndex !== -1) {
            squareBracketsContent = squareBracketsContent.replace(
              secondMatchedHeading,
              numberToExcelColumn(secondMatchedHeadingIndex + 1)
            );
          }
        }

        temp_toPush.placeholder = excelRangeToSubarray({
          range: squareBracketsContent,
          array: temp_toPush.placeholder,
          matchHeadings,
        });
      }

      temp_toPush.dimensions = getArrayDimensions(temp_toPush.placeholder);

      //console.log(temp_toPush)

      toProcess.push(temp_toPush);
    }

    // The rest of the function remains the same...
    // ...

    if (toProcess.length == 0) return promptText;

    // console.log(toProcess);

    let currentOutput: ProcessItem = {
      matchedPlaceholder: 'prompt',
      placeholder: promptText,
    };
    let highest_dimension = 0;

    function match_placeholders(a: ProcessItem, b: ProcessItem): any {
      if (b.dimensions! > highest_dimension) highest_dimension = b.dimensions!;

      if (!Array.isArray(a.placeholder)) {
        if (!Array.isArray(b.placeholder)) {
          //if (!b.matchedPlaceholder || !a.placeholder.includes(b.matchedPlaceholder)) return a;
          return a.placeholder.replace(b.matchedPlaceholder!, b.placeholder);
        } else {
          if (Array.isArray(b.placeholder[0])) {
            const reduced_dimensions: any[] = [];
            for (let i_b = 0; i_b < b.placeholder.length; i_b++) {
              reduced_dimensions.push(
                match_placeholders(a, {
                  matchedPlaceholder: b.matchedPlaceholder,
                  placeholder: b.placeholder[i_b],
                })
              );
            }
            return reduced_dimensions;
          } else {
            const b_return: any[] = [];
            for (let i_b = 0; i_b < b.placeholder.length; i_b++) {
              b_return.push(
                a.placeholder.replace(b.matchedPlaceholder!, b.placeholder[i_b])
              );
            }
            return b_return;
          }
        }
      } else {
        let reduced_dimensions: any[] = [];
        for (let i_a = 0; i_a < a.placeholder.length; i_a++) {
          if (a.placeholder.length == b.placeholder.length) {
            reduced_dimensions.push(
              match_placeholders(
                {
                  matchedPlaceholder: a.matchedPlaceholder,
                  placeholder: a.placeholder[i_a],
                },
                {
                  matchedPlaceholder: b.matchedPlaceholder,
                  placeholder: b.placeholder[i_a],
                }
              )
            );
          } else {
            reduced_dimensions.push(
              match_placeholders(
                {
                  matchedPlaceholder: a.matchedPlaceholder,
                  placeholder: a.placeholder[i_a],
                },
                b
              )
            );
          }
        }

        if (getArrayDimensions(reduced_dimensions) > highest_dimension) {
          reduced_dimensions = reduced_dimensions.flat();
        }

        return reduced_dimensions;
      }
    }

    for (let i_toProcess = 0; i_toProcess < toProcess.length; i_toProcess++) {
      // need to catch placeholders in current output and then send it back again

      // this one works
      // console.log(match_placeholders(currentOutput,toProcess[i_toProcess]));

      currentOutput = {
        matchedPlaceholder: null,
        placeholder: match_placeholders(currentOutput, toProcess[i_toProcess]),
      };

      //console.log("currentOutput up to :",currentOutput);
    }

    //console.log("final currentOutput:",currentOutput);

    return currentOutput.placeholder; // j
  }

  //const [inputParagraph, setInputParagraph] = useState("");
  //const [resultParagraph, setResultParagraph] = useState("");

  // const [processedPromptsState, setProcessedPromptsState] = useState<string[]>(
  //   []
  // );
  const [resultHtml, setResultHtml] = useState('');

  const [imageURLsForBlockWithId, setImageURLsForBlockWithId] = useState<
    Record<string, string[]>
  >({});

  // const [downloadLink, setDownloadLink] = useState<string>('');

  const onDownloadResponseClick = (index: number) => {
    const block = blocks[index];
    const responseText = block.data.response;
    const blob = new Blob([responseText], { type: 'text/plain;charset=utf-8' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `Block_${index}_response.txt`;
    document.body.appendChild(link); // Required for this to work in FireFox
    link.click();
    document.body.removeChild(link);
  };
  const [id, setId] = useState<string>('');
  const [blockIds, setBlockIds] = useState<string[]>([]);

  const [isOwner, setIsOwner] = useState<boolean>(false);
  const [isAuthor, setIsAuthor] = useState<boolean | undefined>(undefined);
  const [buildModeBlocks, setBuildModeBlocks] = useState<any>(undefined);
  const [isPublished, setIsPublished] = useState(false);
  const [isRequestPublished, setIsRequestPublished] = useState(false);
  const [authorId, setAuthorId] = useState<string>('');
  const [authorName, setAuthorName] = useState<string>('');
  const [authorCoverPhoto, setAuthorCoverPhoto] = useState<any>('');

  // const initializePageForNonOwners = () => {
  //   // code will only run if blocks have been saved, ie authorId exists
  //   if (authorId && authorId !== userId) {
  //     let params = new URLSearchParams(location.search);
  //     params.set("runner", "true");
  //     setRunnerIndices(defaultRunnerIndices);
  //     setWaitingForClearUnsavedResponses(true);
  //     setStillRunning(true);
  //   }
  // };

  // useEffect(initializePageForNonOwners, [authorId]);

  const [runnerModeBlocksData, setRunnerModeBlocksData] = useState<any>(false);

  useEffect(() => {
    // find which cats are selected and setChosenCategories
  }, [categories]);

  useEffect(() => {
    console.log('autosavemode:', autosaveMode);
  }, [autosaveMode]);

  const [errorLoadingBlocks, setErrorLoadingBlocks] = useState<any>('');
  const [subscriptionInfo, setSubscriptionInfo] = useState<any>();

  const LoadBlock = async () => {
    console.log('Loading tool');
    // check last part of path
    const path = location.pathname;
    const pathParts = path.split('/');
    const lastSegment = pathParts.pop() || pathParts.pop(); // handle potential trailing slash
    if (lastSegment !== '0') {
      try {
        console.log('lastsegment:', lastSegment);
        const res = await axios.get(`${backendURL}/blocks/${lastSegment}`, {
          headers: {
            Authorization: authHeader,
          },
        });

        // console.log('loaded blocks:', res);
        const canEdit =
          user?.loggedin == 'false' ||
          user?.id == res.data.authorId._id ||
          res.data.editorAccess;

        if (canEdit) {
          setAccess(true);
          setRunnerMode(false);
        }

        console.log('loaded autosavemode:', res.data?.autosaveMode);

        if (res.data.chargeFrequency === 'monthly') {
          const {
            reviewDate,
            subscriptionCharge,
            subscriptionDueCancellation,
          } = res.data;
          setSubscriptionInfo({
            reviewDate,
            subscriptionCharge,
            subscriptionDueCancellation,
          });
        } else setSubscriptionInfo(undefined);

        if (res.data?.autosaveMode !== undefined) {
          setAutosaveMode(res.data.autosaveMode);
        }
        if (res.data?.runnerModeResponses) {
          setRunnerModeBlocksData(res.data?.runnerModeResponses);
        }
        if (res.data?.lastRunnerIndex) {
          setLastSavedRunnerIndex(res.data.lastRunnerIndex);
        }
        if (res.data?.tags) setTags(res.data.tags);

        const loadedBlocks = res.data.fields;

        setAuthorId(res.data.authorId._id);
        setAuthorName(res.data.authorId.name);
        setCoverPhoto(res.data.authorId.coverPhoto ?? '');

        // console.log('loaded blocks: ', loadedBlocks);
        // console.log('response data: ', res.data);

        setIsAuthor(res.data.authorId._id === user?.id);

        // Set the loaded blocks in your component state
        setBlocks(
          canEdit
            ? loadedBlocks
            : loadedBlocks.map((block: any) => ({
                ...block,
                data: {
                  ...block.data,
                  response: [],
                },
              }))
        );
        setAuthorCoverPhoto(res.data.authorId.profileImage);
        setId(res.data._id);
        setIsPublished(res.data.isPublished);
        setFurtherInfo(res.data.furtherInfo);
        setIsRequestPublished(res.data.isRequestPublished);
        setCoverPhoto(res.data.coverPhoto);
        setPrice(res.data.price);
        setPriceFrequency(res.data.priceFrequency);
        setIsLoading(Array(loadedBlocks.length).fill(false));
        //alert('loaded successfully!');
        setTitle(res.data.name);
        setChosenCategories(res.data.categories);
        //console.log('res.data.categories', res.data.categories);
        setDescription(res.data.description);
        setBlocksHaveLoaded(true);
        setHasEditorAccess(res.data.editorAccess);
        // next step if the effect below
      } catch (e) {
        const error = e as any;
        console.error('Error loading blocks', error);
        let errorMessage = 'Error loading blocks';
        const errorStatus = error?.response?.status;
        if (errorStatus) {
          errorMessage += ': ' + errorStatus;
          const statusMessage = error.response?.statusText;
          if (statusMessage) {
            errorMessage += ' - ' + statusMessage;
          }
        }
        setErrorLoadingBlocks(errorMessage);
        // Handle error loading blocks
        // You can show an error message or perform any other necessary action
      }
    } else {
      // on a blank tool
      setRunnerMode(false);
      setAccess(true);
    }
  };

  useEffect(() => {
    if (blocksHaveLoaded && isAuthor !== undefined) {
      const userOwnsBlock =
        isAuthor || user?.loggedin === 'false' || hasEditorAccess;
      if (userOwnsBlock) {
        setIsOwner(true);
        //console.log("you are the owner")
      } else {
        setIsOwner(false);
        const params = new URLSearchParams(location.search);
        params.set('runner', 'true');
        setCollapsedBlocks(blocks.slice(1).map(({ id }) => id));
        setWaitingForClearUnsavedResponses(true);
        clearUnsavedResponses();
        //console.log("Not the owner, just a user")
      }
    }
  }, [blocksHaveLoaded, isAuthor]);

  useEffect(() => {
    LoadBlock();
  }, []);

  const handleToggle = () => {
    //setIsPublished(!isPublished);
    setIsRequestPublished(!isRequestPublished);
  };

  const getBlockData = (blockData: {
    id: string;
    type: string;
    data: object;
    collapseAfterRunning?: boolean;
  }) => {
    //console.log('data: ', blockData.data);
    return {
      ...blockData,
      blocktype: blockData.type.split('-')[1],
      _key: uuidv4(),
    };
  };

  const [saveBlockStatusMessage, setSaveBlockStatusMessage] =
    useState<string>('');

  const savingBlocksMessage = 'Saving blocks...';
  const successfulSaveStatusMessage = 'Saved successfully!';

  useEffect(() => {
    if (saveBlockStatusMessage === successfulSaveStatusMessage) {
      setTimeout(() => setSaveBlockStatusMessage(''), 4000);
    }
  }, [saveBlockStatusMessage]);

  const [tags, setTags] = useState<string[]>([]);

  const saveBlock = async () => {
    setSaveBlockStatusMessage(savingBlocksMessage);
    ReactGA.event('saved_tool');

    // console.log('We Should Be Saving');
    // console.log(user);
    // console.log('These are the blocks we should be saving ', blocks);

    const nonNullBlocks = blocks.filter(
      block => block && Object.keys(block).length > 0
    );

    // console.log(
    //   'These are the nonNullBlocks we should be saving ',
    //   nonNullBlocks
    // );

    setBlocks(
      blocks.filter((block: any) => block?.type?.startsWith('blockdiv'))
    );

    const formattedBlocks = nonNullBlocks
      .map(block => {
        //console.log(block.type);
        if (!block?.type?.startsWith('blockdiv')) {
          return null;
        } else {
          return getBlockData(block);
        }
      })
      .filter(block => block !== null); // filter out any null blocks

    // unpublish if the tool contains a html preview
    const newIsPublished =
      user?.loggedin !== 'false' &&
      formattedBlocks
        .map((block: any) => block.blocktype)
        .includes('htmlpreview')
        ? false
        : undefined;

    if (newIsPublished === false) setIsPublished(false);

    if (!title) {
      setSaveBlockStatusMessage('Please enter a title before saving.');
      return; // exit the function without saving
    }
    if (blocks.length === 0) {
      setSaveBlockStatusMessage('Please add at least one block before saving.');
      return; // exit the function without saving
    }
    // Use axios to make a POST request to your Express backend server
    try {
      // new code
      /*
      const formattedBlocksSave = formattedBlocks.map((item) => {
        if ('data' in item) {
          let newItem = { ...item }; // Create a shallow copy of the item
          if ('processedInput' in newItem.data && Array.isArray((item.data as any).processedInput)) {
            (item.data as any).processedInput = JSON.stringify((item.data as any).processedInput);
          }
          //if ('response' in newItem.data && Array.isArray((item.data as any).response)) {
          //  (item.data as any).response = JSON.stringify((item.data as any).response);
         // }
          return newItem;
        } else {
          return item; // If there's no 'data', return the item unchanged
        }
      });
      */

      // end of new code

      const payloadString = JSON.stringify(formattedBlocks as any);
      const sizeInBytes = new TextEncoder().encode(payloadString).length;
      const sizeInKilobytes = sizeInBytes / 1024;
      const sizeInMegabytes = sizeInKilobytes / 1024;

      console.log(`Size in Bytes: ${sizeInBytes}`);
      console.log(`Size in KB: ${sizeInKilobytes}`);
      console.log(`Size in MB: ${sizeInMegabytes}`);

      const res = await axios.post(
        `${backendURL}/blocks/save`,
        {
          name: title || `${user?.username} Untitled Blocks`,
          description,
          furtherInfo,
          _id: toolId,
          fields: formattedBlocks, //formattedBlocksSave,
          authorId: authorId || user?.id, // authorId first otherwise it saves as admin id
          price,
          priceFrequency,
          isRequestPublished,
          isPublished: newIsPublished,
          categories: chosenCategories.map(({ _id }) => ({ _id })),
          tags,
        },
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );

      const savedBlock = res.data.result;
      // console.log(res.data); // this should be the created block from server
      // console.log(savedBlock._id);

      if (isPublished && newIsPublished === false)
        setSaveBlockStatusMessage(
          `${successfulSaveStatusMessage} As your block contains a HTML preview block, for safety reasons your tool will need reviewing by an admin before it can be published.`
        );
      else setSaveBlockStatusMessage(successfulSaveStatusMessage);
      // console.log('07.06.23:', formattedBlocks, toolId);
      if (lastSegment === '0') {
        window.location.href = `/fuser/${res.data._id}`;
      }
      return res.data.result; // Return the result from the backend (created or updated)
    } catch (error) {
      console.error('Error saving blocks', error);

      alert(`error: ${error}`);
      // Handle error saving blocks
      // You can show an error message or perform any other necessary action
    }
  };

  const blockDataKeysHoldingReferences = [
    'inputToProcess',
    'secondInputToProcess',
    'customSeparator',
    'appendSecondOperand',
    'message',
    'startResultIndex',
  ];

  const blockDataKeysHoldingBlockNumbers = [
    'ifInput',
    'elseInput',
    'indexOfBlockToJumpTo',
  ];

  // ADD NEW BLOCK
  const addBlock = (index?: number) => {
    const newBlock = {
      id: uuidv4(),
      type: '',
      data: {},
      ref: React.createRef<HTMLTextAreaElement>(),
    };

    if (index !== undefined) {
      setBlocks((blocks: Block[]) => {
        const newBlocks = [...blocks];
        newBlocks.splice(index + 1, 0, newBlock);

        const blockIndicesToChange = blocks
          .map((_, blockIndex) => blockIndex)
          .filter(blockIndex => blockIndex >= index + 1);

        const blockNumberReplacement = (blockNumberString: string) => {
          return blockIndicesToChange.includes(+blockNumberString)
            ? +blockNumberString + 1
            : blockNumberString;
        };

        const atReferencesNeedingUpdate = new RegExp(
          `@Block(${blockIndicesToChange.join('|')})(?=\\W|$)`,
          'g'
        );

        const atReferenceReplacement = (
          atReference: string,
          blockNumberString: string
        ) => {
          return `@Block${+blockNumberString + 1}`;
        };

        //console.log(atReferencesNeedingUpdate);

        const blockReferenceNeedingUpdate = new RegExp(
          `<(${blockIndicesToChange.join('|')}):(input|output)>`,
          'g'
        );

        //console.log("blockIndicesToChange",blockIndicesToChange);

        const blockReferenceReplacement = (blockReference: string) => {
          const regex = /<(\d+):(output|input)>/g;
          let match: RegExpExecArray | null;
          while ((match = regex.exec(blockReference)) !== null) {
            const newRef = parseInt(match[1]) + 1;
            // console.log(
            //   'match',
            //   match,
            //   '<' + newRef.toString() + ':' + match[2] + '>'
            // );
            return '<' + newRef.toString() + ':' + match[2] + '>';
          }
        };

        return newBlocks.map(block => {
          const newDataKeyValuePairs = [
            ...blockDataKeysHoldingReferences
              .filter(key => block.data?.[key]?.toString() !== undefined)
              .map(key => [
                key,
                block.data[key]
                  .toString()
                  ?.replace(
                    blockReferenceNeedingUpdate,
                    blockReferenceReplacement
                  )
                  ?.replace(atReferencesNeedingUpdate, atReferenceReplacement),
              ]),
            ...blockDataKeysHoldingBlockNumbers
              .filter(key => block.data?.[key] !== undefined)
              .map(key => [key, blockNumberReplacement(block.data[key])]),
          ];
          return {
            ...block,
            data: {
              ...block.data,
              ...Object.fromEntries(newDataKeyValuePairs),
            },
          };
        });
      });

      setIsLoading(oldIsLoading => {
        const newIsLoading = [...oldIsLoading];
        newIsLoading.splice(index + 1, 0, false);
        return newIsLoading;
      });

      setActivityLog(prevLog => [
        ...prevLog,
        `Added new block at index: ${index + 1}`,
      ]);
    } else {
      setBlocks(prevBlocks => [...prevBlocks, newBlock]);
      setIsLoading(oldIsLoading => [...oldIsLoading, false]);
      setActivityLog(prevLog => [...prevLog, 'Added new block at the end']);
    }
  };

  // DUPLICATE A BLOCK AND ALL OF ITS CONTENT, GIVE IT A NEW UID
  const duplicateBlock = (index: number) => {
    setBlocks((blocks: Block[]) => {
      const newBlocks = [...blocks];
      const blockToDuplicate = blocks[index];
      const duplicateBlock = {
        ...blockToDuplicate,
        id: uuidv4(),
        data: { ...blockToDuplicate.data, name: undefined },
      };
      newBlocks.splice(index + 1, 0, duplicateBlock);
      const blockIndicesToChange = blocks
        .map((_, blockIndex) => blockIndex)
        .filter(blockIndex => blockIndex >= index + 1);

      const atReferencesNeedingUpdate = new RegExp(
        `@Block(${blockIndicesToChange.join('|')})(?=\\W|$)`,
        'g'
      );

      const atReferenceReplacement = (
        atReference: string,
        blockNumberString: string
      ) => {
        return `@Block${+blockNumberString + 1}`;
      };

      const blockReferenceNeedingUpdate = new RegExp(
        `<(${blockIndicesToChange.join('|')}):(input|output)>`,
        'g'
      );

      //console.log("blockIndicesToChange",blockIndicesToChange);

      const blockReferenceReplacement = (blockReference: string) => {
        const regex = /<(\d+):(output|input)>/g;
        let match: RegExpExecArray | null;
        while ((match = regex.exec(blockReference)) !== null) {
          const newRef = parseInt(match[1]) + 1;
          // console.log(
          //   'match',
          //   match,
          //   '<' + newRef.toString() + ':' + match[2] + '>'
          // );
          return '<' + newRef.toString() + ':' + match[2] + '>';
        }
      };

      const blockNumberReplacement = (blockNumberString: string) => {
        return blockIndicesToChange.includes(+blockNumberString)
          ? +blockNumberString + 1
          : blockNumberString;
      };

      return newBlocks.map(block => {
        const newDataKeyValuePairs = [
          ...blockDataKeysHoldingReferences
            .filter(key => block.data?.[key]?.toString() !== undefined)
            .map(key => [
              key,
              block.data[key]
                .toString()
                ?.replace(
                  blockReferenceNeedingUpdate,
                  blockReferenceReplacement
                )
                ?.replace(atReferencesNeedingUpdate, atReferenceReplacement),
            ]),
          ...blockDataKeysHoldingBlockNumbers
            .filter(key => block.data?.[key] !== undefined)
            .map(key => [key, blockNumberReplacement(block.data[key])]),
        ];
        return {
          ...block,
          data: {
            ...block.data,
            ...Object.fromEntries(newDataKeyValuePairs),
          },
        };
      });
    });

    const newIsLoading = [...isLoading];
    newIsLoading.splice(index + 1, 0, false);
    setIsLoading(newIsLoading);

    setActivityLog(prevLog => [
      ...prevLog,
      `Duplicated block at index: ${index}`,
    ]);
  };

  // CHANGE BLOCK TYPE
  const changeBlockType = (index: number, newType: string) => {
    setBlocks((blocks: Block[]) => {
      const newBlocks = [...blocks];
      newBlocks[index] = { ...newBlocks[index], type: newType };
      return newBlocks;
    });
  };

  // WHEN WE ADD A NEW BLOCK OUR BLOCKS ARRAY NEEDS TO HANDLE IT
  // Any html element with onChange as handleChange will add its value to its blocks data object with its name as the key
  const handleChange = (
    index: number,
    event: ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    let { name, value } = event.target;
    // console.log(name, value);
    if (event.target.type === 'radio') name = 'checkedRadioValue';

    setBlocks((blocks: Block[]) => {
      const newBlocks = [...blocks];
      newBlocks[index] = {
        ...newBlocks[index],
        data: {
          ...newBlocks[index].data,
          [name]: value,
        },
      };
      return newBlocks;
    });
  };

  const updateBlockData =
    (index: number) => (property: string, newValue: any) => {
      const oldBlock = blocks[index];
      const newBlock = updateProperty(
        ['data', property],
        newValue,
        oldBlock
      ) as Block;
      if (typeof newBlock === 'object') {
        setBlocks((blocks: Block[]) => updateAtIndex(index, newBlock, blocks));
      }
    };

  // DELETE BLOCK
  const deleteBlock = (idToDelete: string) => {
    const indexOfBlockToDelete = blocks.findIndex(
      block => block.id === idToDelete
    );

    setIsLoading(deleteAtIndex(indexOfBlockToDelete, isLoading));

    const blockIndicesToChange = blocks
      .map((_, blockIndex) => blockIndex)
      .filter(blockIndex => blockIndex > indexOfBlockToDelete);

    const atReferencesNeedingUpdate = new RegExp(
      `@Block(${blockIndicesToChange.join('|')})(?=\\W|$)`,
      'g'
    );

    const atReferenceReplacement = (
      atReference: string,
      blockNumberString: string
    ) => {
      return `@Block${+blockNumberString - 1}`;
    };

    const blockReferenceNeedingUpdate = new RegExp(
      `<(${blockIndicesToChange.join('|')}):(input|output)>`,
      'g'
    );

    const nameOfBlockToDelete = blocks[indexOfBlockToDelete].data.name;
    // delete any references to the deleted block along with any trailing space
    const patternsToDelete = [
      `<${indexOfBlockToDelete}:(input|output)> ?`,
      `@Block${indexOfBlockToDelete} `,
      `@Block${indexOfBlockToDelete}(?=\\W|$)`,
      ...(nameOfBlockToDelete
        ? [`@${nameOfBlockToDelete} `, `@${nameOfBlockToDelete}(?=\\W|$)`]
        : []),
    ];

    const blockReferencesToDelete = new RegExp(patternsToDelete.join('|'), 'g');

    // console.log(blockReferencesToDelete);

    const blockReferenceReplacement = (blockReference: string) => {
      const regex = /<(\d+):(output|input)>/g;
      let match: RegExpExecArray | null;
      while ((match = regex.exec(blockReference)) !== null) {
        const newRef = parseInt(match[1]) - 1;
        // console.log(
        //   'match',
        //   match,
        //   '<' + newRef.toString() + ':' + match[2] + '>'
        // );
        return '<' + newRef.toString() + ':' + match[2] + '>';
      }
    };

    const blockNumberReplacement = (blockNumberString: string) => {
      return blockIndicesToChange.includes(+blockNumberString)
        ? +blockNumberString - 1
        : blockNumberString;
    };

    setBlocks((blocks: Block[]) => {
      const newBlocks = blocks.filter(block => block.id !== idToDelete);

      return newBlocks.map(block => {
        const newDataKeyValuePairs = [
          ...blockDataKeysHoldingReferences
            .filter(key => block.data?.[key]?.toString() !== undefined)
            .map(key => [
              key,
              block.data[key]
                .toString()
                ?.replace(blockReferencesToDelete, '')
                ?.replace(
                  blockReferenceNeedingUpdate,
                  blockReferenceReplacement
                )
                ?.replace(atReferencesNeedingUpdate, atReferenceReplacement),
            ]),
          ...blockDataKeysHoldingBlockNumbers
            .filter(key => block.data?.[key] !== undefined)
            .map(key => [key, blockNumberReplacement(block.data[key])]),
        ];
        return {
          ...block,
          data: {
            ...block.data,
            ...Object.fromEntries(newDataKeyValuePairs),
          },
        };
      });
    });

    setActivityLog(prevLog => [
      ...prevLog,
      `Deleted block with ID: ${idToDelete}`,
    ]);
    setSelectedBlockId(null);
  };

  const [selectedTheme, setSelectedTheme] = useState<Theme>(themes[0]);

  // Function to handle theme selection
  const handleThemeChange = (themeIndex: number) => {
    setSelectedTheme(themes[themeIndex]);
  };

  const initialBlockStyles =
    //'border-2 my-4 p-4 rounded-xl gap-2 text-black shadow-xl bg-gradient-to-b from-blue-100 to-purple-200 transition-opacity duration-1000 ease-in-out opacity-0 animate-fade-in dark:bg-neutral-800';
    'border-2 my-4 p-4 flex flex-col rounded-xl gap-2 text-black shadow-xl bg-gradient-to-b from-blue-100 to-purple-200 transition-opacity duration-1000 ease-in-out opacity-0 animate-fade-in dark:bg-neutral-800';
  //`border-2 my-4 p-4 flex ${collapsedBlocks && runnerMode?'flex-row' : 'flex-col'} rounded-xl gap-2 text-black shadow-xl bg-gradient-to-b from-blue-100 to-purple-200 transition-opacity duration-1000 ease-in-out opacity-0 animate-fade-in dark:bg-neutral-800`;

  // ANIMATED BORDERS FOR BLOCKS IF WE ARE IN RUNNER MODE

  const blockStyles = runnerMode
    ? `${initialBlockStyles} ` // add any additional runner mode styles here
    : initialBlockStyles;

  //blockStyles = runnerMode && collapsedBlocks? `${blockStyles} flex-row` // add any additional runner mode styles here
  //  : initialBlockStyles + " flex-col";

  const textAreaRefs = useRef<Record<string, HTMLTextAreaElement | null>>({});
  const blockRefs = useRef<Record<string, HTMLDivElement | null>>({});

  const focusedTextarea = useRef<HTMLTextAreaElement | null>(null);
  const [textareaValue, setTextareaValue] = useState(''); // Replace '' with the initial value of your textarea
  const cursorPositionRef = useRef<number | null>(null);

  const [selectedBlockId, setSelectedBlockId] = useState<string | null>('');
  const [selectedTextareaId, setSelectedTextareaId] = useState<number | null>(
    0
  );

  const [selectedBlockReference, setSelectedBlockReference] = useState('');

  useEffect(() => {
    if (blocks.length <= 1) setSelectedBlockReference('');
    else if (selectedBlockReference === '')
      setSelectedBlockReference('0:input');
  }, [blocks]);

  // const handleBlockIdChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  //   setSelectedBlockReference(event.target.value);
  // };

  const setFocusedTextArea = (
    blockId: string | null,
    textareaId: number | null
  ) => {
    if (blockId !== null && textareaId !== null) {
      focusedTextarea.current =
        textAreaRefs.current[`${blockId}:${textareaId}`];
      focusedTextarea.current?.focus();
    }
  };

  useEffect(() => {
    if (blockIds.length > 0) {
      setSelectedBlockId(blockIds[blockIds.length - 1]);
    }
  }, [blockIds]);

  // const [focusedBlockId, setFocusedBlockId] = useState<number | null>(null);

  // This function can be called when a textarea gets focused
  const handleTextareaFocus = (
    event: React.FocusEvent<HTMLTextAreaElement>,
    blockId: string,
    textareaId: number
  ) => {
    focusedTextarea.current = textAreaRefs.current[`${blockId}:${textareaId}`];
    // const blockIndex = parseInt(blockId.split(':')[0]); // Extract the block index from the block id
    //console.log(textAreaRefs);
    setSelectedBlockId(blockId);
    setSelectedTextareaId(textareaId);
    // console.log('Focused on block id:', blockId);
    // console.log('Focused textarea:', focusedTextarea.current);
  };

  const handleTextareaBlur = () => {
    // setSelectedBlockId(null);
    // console.log('Textarea has lost focus');
  };

  // And make sure to pass it to your textarea's onFocus event:
  // <textarea onFocus={handleTextareaFocus} />

  const insertReference = () => {
    // console.log('insertReference was called');
    // console.log(textAreaRefs.current);
    // console.log(selectedBlockId);

    // console.log(blocks);
    const currentBlockIndex = blocks.findIndex(
      block => block.id === selectedBlockId
    );

    if (currentBlockIndex === -1) {
      // The block with the selected ID was not found
      console.error(`Block with ID ${selectedBlockId} not found`);
      return;
    }

    //console.log(currentBlockIndex, focusedTextarea.current);
    if (currentBlockIndex === null || !focusedTextarea.current) {
      alert('Please click on a textarea to edit before inserting a reference.');
      return;
    }

    const reference = `<${selectedBlockReference}>`; // Add pointy brackets around the selectedBlockId
    //console.log(reference);
    const cursorPosition = focusedTextarea.current.selectionStart || 0;

    const currentTextareaValue = focusedTextarea.current.value;

    // Create a new string with the reference inserted at the cursor position
    const updatedValue = `${currentTextareaValue.slice(
      0,
      cursorPosition
    )}${reference}${currentTextareaValue.slice(cursorPosition)}`;

    focusedTextarea.current.value = updatedValue;

    cursorPositionRef.current = cursorPosition + reference.length;

    setBlocks((blocks: Block[]) => {
      // Add the updated value to the blocks state
      const newBlocks = [...blocks];
      newBlocks[currentBlockIndex] = {
        ...newBlocks[currentBlockIndex],
        data: {
          ...newBlocks[currentBlockIndex].data,
          [focusedTextarea!.current!.name]: updatedValue,
        },
      };
      return newBlocks;
    });

    // console.log('\nselectedBlockId:', selectedBlockId);
    // console.log('\ncursorPosition:', cursorPosition);
    // console.log('\ntextareaValue:', currentTextareaValue);
  };

  const [isFullScreen, setIsFullScreen] = useState(false);
  const contentRef = useRef<HTMLDivElement | null>(null);

  const toggleFullScreen = () => {
    if (!document.fullscreenElement && contentRef.current) {
      contentRef.current.requestFullscreen();
      setIsFullScreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        setIsFullScreen(false);
      }
    }
  };

  // Then a useEffect hook to apply the cursor position
  useEffect(() => {
    //console.log(focusedTextarea.current);
    if (cursorPositionRef.current !== null && focusedTextarea.current) {
      focusedTextarea.current.selectionStart = cursorPositionRef.current;
      focusedTextarea.current.selectionEnd = cursorPositionRef.current;
      cursorPositionRef.current = null;
    }
  }, [textareaValue, focusedTextarea]);

  const [responseVisibility, setResponseVisibility] = useState<
    Record<number, boolean>
  >(() => {
    const initialState: Record<number, boolean> = {};
    blocks.forEach((block, index) => {
      initialState[index] = true;
    });
    return initialState;
  });

  // Toggle the visibility of the response area
  const toggleResponseVisibility = (index: number) => {
    setResponseVisibility(prevVisibility => ({
      ...prevVisibility,
      [index]: !prevVisibility[index],
    }));
  };

  const moveBlockUp = (index: number) => {
    if (index === 0) {
      return; // Block is already at the top
    }

    // update block references that have been entered in any inputs

    const atReferencesNeedingUpdate = new RegExp(
      `@Block(${index}|${index - 1})(?=\\W|$)`,
      'g'
    );

    const atReferenceReplacement = (
      atReference: string,
      blockIndexString: string
    ) => {
      const newBlockIndex = +blockIndexString === index ? index - 1 : index;
      return `@Block${newBlockIndex}`;
    };

    const blockReferenceNeedingUpdate = new RegExp(
      `<(${index}|${index - 1}):(input|output)>`,
      'g'
    );

    const blockReferenceReplacement = (blockReference: string) => {
      /*
      let replacementChars = blockReference.split("");
      let updatedIndex = +replacementChars[1] === index ? index - 1 : index;
      replacementChars[1] = updatedIndex.toString();
      return replacementChars.join("");
      */

      const regex = /<(\d+):(output|input)>/g;
      let match: RegExpExecArray | null;
      while ((match = regex.exec(blockReference)) !== null) {
        const newRef = +match[1] === index ? index - 1 : index;
        // console.log(
        //   'match',
        //   match,
        //   '<' + newRef.toString() + ':' + match[2] + '>'
        // );
        return '<' + newRef.toString() + ':' + match[2] + '>';
      }
    };

    const blockNumberReplacement = (blockNumberString: string) => {
      const oldBlockNumber = +blockNumberString;
      return oldBlockNumber === index - 1
        ? index
        : oldBlockNumber === index
          ? index - 1
          : blockNumberString;
    };

    setBlocks((blocks: Block[]) => {
      const newBlocks = [...blocks];
      const [previousBlock, currentBlock] = [
        newBlocks[index - 1],
        newBlocks[index],
      ];
      newBlocks[index] = previousBlock;
      newBlocks[index - 1] = currentBlock;
      // const textAreaRefKeysToReplace = Object.keys(textAreaRefs.current).filter(
      //   (key: string) =>
      //     key.startsWith(previousBlock.id) || key.startsWith(currentBlock.id)
      // );
      // const textAreasToReplace: any = textAreaRefKeysToReplace.map(
      //   (key: string) => textAreaRefs.current[key]
      // );

      // for (const textarea of textAreasToReplace) {
      //   textarea.value = textarea.value.replace(
      //     blockReferenceNeedingUpdate,
      //     blockReferenceReplacement
      //   );
      //   textarea.dispatchEvent(new Event('input')); // trigger onchange handler
      // }
      // return newBlocks;
      return newBlocks.map(block => {
        const newDataKeyValuePairs = [
          ...blockDataKeysHoldingReferences
            .filter(key => block.data?.[key]?.toString() !== undefined)
            .map(key => [
              key,
              block.data[key]
                .toString()
                ?.replace(
                  blockReferenceNeedingUpdate,
                  blockReferenceReplacement
                )
                ?.replace(atReferencesNeedingUpdate, atReferenceReplacement),
            ]),
          ...blockDataKeysHoldingBlockNumbers
            .filter(key => block.data?.[key] !== undefined)
            .map(key => [key, blockNumberReplacement(block.data[key])]),
        ];
        return {
          ...block,
          data: {
            ...block.data,
            ...Object.fromEntries(newDataKeyValuePairs),
          },
        };
      });
    });

    setActivityLog(prevLog => [
      ...prevLog,
      `Moved block from index ${index} to index ${index - 1}`,
    ]);
  };

  const moveBlockDown = (index: number) => {
    if (index === blocks.length - 1) {
      return; // Block is already at the bottom
    }

    const atReferencesNeedingUpdate = new RegExp(
      `@Block(${index}|${index + 1})(?=\\W|$)`,
      'g'
    );

    const atReferenceReplacement = (
      atReference: string,
      blockIndexString: string
    ) => {
      const newBlockIndex = +blockIndexString === index ? index + 1 : index;
      return `@Block${newBlockIndex}`;
    };

    const blockReferenceNeedingUpdate = new RegExp(
      `<(${index}|${index + 1}):(input|output)>`,
      'g'
    );
    const blockReferenceReplacement = (blockReference: string) => {
      /*
      let replacementChars = blockReference.split("");
      let updatedIndex = +replacementChars[1] === index ? index + 1 : index;
      replacementChars[1] = updatedIndex.toString();
      return replacementChars.join("");
      */

      const regex = /<(\d+):(output|input)>/g;
      let match: RegExpExecArray | null;
      while ((match = regex.exec(blockReference)) !== null) {
        const newRef = +match[1] === index ? index + 1 : index;
        // console.log(
        //   'match',
        //   match,
        //   '<' + newRef.toString() + ':' + match[2] + '>'
        // );
        return '<' + newRef.toString() + ':' + match[2] + '>';
      }
    };

    const blockNumberReplacement = (blockNumberString: string) => {
      const oldBlockNumber = +blockNumberString;
      return oldBlockNumber === index + 1
        ? index
        : oldBlockNumber === index
          ? index + 1
          : blockNumberString;
    };

    setBlocks((blocks: Block[]) => {
      const newBlocks = [...blocks];
      const tempBlock = newBlocks[index];
      newBlocks[index] = newBlocks[index + 1];
      newBlocks[index + 1] = tempBlock;
      return newBlocks.map(block => {
        const newDataKeyValuePairs = [
          ...blockDataKeysHoldingReferences
            .filter(key => block.data?.[key]?.toString() !== undefined)
            .map(key => [
              key,
              block.data[key]
                .toString()
                ?.replace(
                  blockReferenceNeedingUpdate,
                  blockReferenceReplacement
                )
                ?.replace(atReferencesNeedingUpdate, atReferenceReplacement),
            ]),
          ...blockDataKeysHoldingBlockNumbers
            .filter(key => block.data?.[key] !== undefined)
            .map(key => [key, blockNumberReplacement(block.data[key])]),
        ];
        return {
          ...block,
          data: {
            ...block.data,
            ...Object.fromEntries(newDataKeyValuePairs),
          },
        };
      });
    });

    setActivityLog(prevLog => [
      ...prevLog,
      `Moved block from index ${index} to index ${index + 1}`,
    ]);
  };

  const [isJsonVisible, setIsJsonVisible] = useState(false);

  const toggleJsonVisibility = () => {
    setIsJsonVisible(!isJsonVisible);
  };

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleBlockCollapse = (blockId: string) => {
    if (collapsedBlocks.includes(blockId)) {
      setCollapsedBlocks(collapsedBlocks.filter(id => id !== blockId));
    } else {
      setCollapsedBlocks([...collapsedBlocks, blockId]);
    }
  };

  /*
  useEffect(() => {
    if (stillRunning){
      //runBlock((runnerIndices.previous || 0)+1);
      runBlock((runnerIndices.previous || 0)+1);
      //setRunnerMode(false);
      setRunnerMode(false);
      console.log("in useEffect");
    }
  },[stillRunning])
  */
  /*
  const MyToolTips: FC<{ content: string; tipID: number }> = ({ content, tipID }) => {
    return  <span> <Tooltip id="{tipID}"/>
    <p 
    data-tooltip-html = {`<span>${content}</span>`} 
    data-tooltip-place="top" data-tooltip-id="my-tooltip" className="rounded-full border-2 border-slate-500 w-fit p-1">?</p>
    </span>;
  }
  */

  const [blockToShare, setBlockToShare] = useState<string>('');
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [shareMessage, setShareMessage] = useState<string>('');

  const [cookies] = useCookies(['_auth_state']);
  const [minimalMode, setMinimalMode] = useState<boolean>(
    cookies._auth_state?.minimalMode === 'true'
  );

  const [blockScrollingIntoView, setBlockScrollingIntoView] =
    useState<boolean>(false);

  const fuserContextValues = {
    setBlockScrollingIntoView,
    blockScrollingIntoView,
    anyBlocksLoading,
    restartQueued,
    restartRunnerMode,
    minimalMode,
    setMinimalMode,
    toolId,
    blocks,
    addBlock,
    toggleBlockCollapse,
    deleteBlock,
    moveBlockDown,
    moveBlockUp,
    duplicateBlock,
    changeBlockType,
    collapsedBlocks,
    blockStyles,
    runnerIndex,
    runnerMode,
    toggleRunnerMode,
    themes,
    selectedTheme,
    handleThemeChange,
    undoChanges,
    redoChanges,
    toggleSidebar,
    isFullScreen,
    toggleFullScreen,
    isJsonVisible,
    toggleJsonVisibility,
    clearUnsavedResponses,
    hasAccess,
    pauseRunnerMode,
    resumeRunnerMode,
    stillRunning,
    setStillRunning,
    saveBlock,
    selectedTextareaId,
    selectedBlockId,
    selectedBlockReference,
    setSelectedBlockReference,
    isLoading,
    setIsLoading,
    title,
    description,
    handleTitleChange,
    handleDescriptionChange,
    insertReference,
    isPublished,
    isRequestPublished,
    handleToggle,
    activityLog,
    setFocusedTextArea,
    isOwner,
    isAuthor,
    setIsPublished,
    setIsRequestPublished,
    coverPhoto,
    price,
    priceFrequency,
    setPrice,
    setPriceFrequency,
    userId,
    authorId,
    authorName,
    authorCoverPhoto,
    textAreaRefs,
    blockRefs,
    responseVisibility,
    imageURLsForBlockWithId,
    setBlocks,
    setActivityLog,
    setImageURLsForBlockWithId,
    replacePlaceholders,
    evaluatePlaceholder,
    toggleResponseVisibility,
    handleChange,
    handleTextareaFocus,
    handleTextareaBlur,
    onDownloadResponseClick,
    updateBlockData,
    resultHtml,
    waitingForClearUnsavedResponses,
    setWaitingForClearUnsavedResponses,
    onRunnerModeClick,
    blocksHaveLoaded,
    saveBlockStatusMessage,
    setSaveBlockStatusMessage,
    tidyUpResponse,
    setRunnerIndex,
    setRunnerModeBlocksData,
    errorLoadingBlocks,
    categories,
    setCategories,
    chosenCategories,
    setChosenCategories,
    autosaveMode,
    setAutosaveMode,
    blockCancellationState,
    setBlockCancellationState,
    cancellingBlocksMemo,
    setCollapsedBlocks,
    setCoverPhoto,
    subscriptionInfo,
    setSubscriptionInfo,
    processAtSigns,
    blockDataKeysHoldingReferences,
    tags,
    setTags,
    blockToShare,
    setBlockToShare,
    shareModalOpen,
    setShareModalOpen,
    shareMessage,
    setShareMessage,
    furtherInfo,
    handleFurtherInfoChange,
    isSidebarOpen,
    saveRunnerModeBlocksData,
    runnerIndexHistory,
  };

  return (
    <FuserContext.Provider value={fuserContextValues}>
      <div
        ref={contentRef}
        className=' bg-white dark:bg-neutral-900 mb-96'
        data-nc-id='BuilderPage'
      >
        <Helmet>
          <title>{`${toolId ? title : 'Tool Maker'} - Skillfusion`}</title>
          {/* <link
            rel='canonical'
            href={`https://skillfusion.ai/fusion/${lastSegment}`}
          /> */}
        </Helmet>
        <div
          className='flex flex-col sm:flex-row runner-height w-full'
          id='main-content'
        >
          <FuserMain />

          {isSidebarOpen && (
            <FuserSidebar
              title={title}
              description={description}
              isPublished={isPublished}
              authorCoverPhoto={authorCoverPhoto}
              authorId={authorId}
              authorName={authorName}
              isForSharedBlock={false}
              toolId={toolId}
              toggleSidebar={toggleSidebar}
            />
          )}
        </div>
      </div>
      <ShareModal
        isOpen={shareModalOpen}
        onClose={() => setShareModalOpen(false)}
      />
    </FuserContext.Provider>
  );
};

export default FuserPage;
