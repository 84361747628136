import { FC } from 'react';
import PromptBlock from '../components/Blocks/PromptBlock';
import QuestionBlock from '../components/Blocks/QuestionBlock';
import InfoBlock from '../components/Blocks/InfoBlock';
import ProcessingBlock from '../components/Blocks/ProcessingBlock';
import ReducingBlock from '../components/Blocks/ReducingBlock';
import ImageBlock from '../components/Blocks/ImageBlock';
import BlockProps from '../models/BlockProps';
import DownloadBlock from '../components/Blocks/DownloadBlock';
import ZapierBlock from '../components/Blocks/ZapierBlock';
import HTMLPreviewBlock from '../components/Blocks/HTMLPreviewBlock';
import EmbeddingsBlock from '../components/Blocks/EmbeddingsBlock';
import IfElseBlock from '../components/Blocks/IfElseBlock';
import ScraperBlock from '../components/Blocks/ScraperBlock';
import GoogleSearchBlock from '../components/Blocks/GoogleSearchBlock';
import TextToSpeechBlock from '../components/Blocks/TextToSpeechBlock';
import ChatBlock from '../components/Blocks/ChatBlock';
import JumpBlock from '../components/Blocks/JumpBlock';
import ApiBlock from '../components/Blocks/ApiBlock';
import CustomJavascriptBlock from '../components/Blocks/CustomJavascriptBlock';

export const MAX_PREVIEW_CHARS = 50;

export const blockTypesExpandedByDefault = ['blockdiv-chat'];

export const blockComponents: Record<
  string,
  { component: FC<BlockProps>; selectName: string }
> = {
  'blockdiv-prompt': {
    component: PromptBlock,
    selectName: 'Prompt',
  },
  'blockdiv-chat': {
    component: ChatBlock,
    selectName: 'Chat',
  },
  'blockdiv-question': {
    component: QuestionBlock,
    selectName: 'User Question',
  },
  'blockdiv-info': {
    component: InfoBlock,
    selectName: 'Info Block',
  },
  'blockdiv-processing': {
    component: ProcessingBlock,
    selectName: 'Processing Block',
  },
  'blockdiv-reducing': {
    component: ReducingBlock,
    selectName: 'Reducing Block',
  },
  'blockdiv-image': {
    component: ImageBlock,
    selectName: 'Image Block',
  },
  'blockdiv-download': {
    component: DownloadBlock,
    selectName: 'Download Block',
  },
  'blockdiv-api': {
    component: ApiBlock,
    selectName: 'API Block',
  },
  'blockdiv-zapier': {
    component: ZapierBlock,
    selectName: 'Zapier Block',
  },
  'blockdiv-htmlpreview': {
    component: HTMLPreviewBlock,
    selectName: 'HTML Preview Block',
  },
  'blockdiv-embeddings': {
    component: EmbeddingsBlock,
    selectName: 'Embeddings Block',
  },
  'blockdiv-if-else': {
    component: IfElseBlock,
    selectName: 'If-else block',
  },
  'blockdiv-jump': {
    component: JumpBlock,
    selectName: 'Jump block',
  },
  'blockdiv-scraper': {
    component: ScraperBlock,
    selectName: 'Scraper block',
  },
  'blockdiv-google': {
    component: GoogleSearchBlock,
    selectName: 'Google Search',
  },
  'blockdiv-textToSpeech': {
    component: TextToSpeechBlock,
    selectName: 'Text to Speech',
  },
  'blockdiv-customJavascript': {
    component: CustomJavascriptBlock,
    selectName: 'Custom Javascript',
  },
};

export const blockReferenceRegex = /(<\d+:(in|out)put>)|(@\w+)/g;
