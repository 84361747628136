import React, { FC, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import missingpic from '../images/missing.jpg';
import { Fusion, Skill } from '../../TypeScript/Types';

export interface CardNFT2Props {
  className?: string;
  isLiked?: boolean;
  fusion?: Fusion;
}

const trimDescriptionAndRemoveSkillfusionLinks = (
  desc: string,
  maxLength: number
) => {
  if (typeof desc !== 'string') {
    // Return a default string or keep it empty if desc is not valid
    return '';
  }

  if (desc.length > maxLength) {
    // Return the trimmed description with an ellipsis
    return `${desc.substring(0, maxLength)} <u class="underline-offset-2 text-blue-600">...read more</u>`;
  }

  // Return the original description with skillfusion links removed if it doesn't need trimming
  return desc.replace(
    /(https?:\/\/)?(www\.)?(blog\.)?skillfusion\.ai[A-Za-z0-9\-._~!$&'()*+,;=:@/?#[\]%]* ?/gi,
    ''
  );
};

const FusionCard: FC<any> = ({
  link,
  handleLinkClick,
  price,
  priceFrequency,
  coverPhoto,
  name,
  username,
  views,
  downloads,
  description,
}) => {
  const [imageFailedToLoad, setImageFailedToLoad] = useState(false);

  return (
    <div>
      <Link
        //className={`w-full rounded-lg text-black bg-white flex-col border-2 rounded-md shadow-lg w-full flex h-full rounded-3xl hover:shadow-blue pb-6 ${(result.price != null && result.price > 0) ? 'border-yellow-600 border-2' : ''}`}
        className={`w-full text-black bg-white flex-col md:flex-row border-2 shadow-lg w-full flex h-full md:h-40 hover:shadow-blue overflow-hidden`}
        to={link}
        onClick={handleLinkClick}
        style={{
          /*
          WebkitBoxShadow:
            price != null && price > 0
              ? 'rgb(186, 10, 251) 0px 0px 18px 0px'
              : undefined,
          MozBoxShadow:
            price != null && price > 0
              ? 'rgb(186, 10, 251) 0px 0px 18px 0px'
              : undefined,
          boxShadow:
            price != null && price > 0
              ? 'rgb(186, 10, 251) 0px 0px 18px 0px'
              : undefined,*/
          //background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url("/static/media/6.e6e3ef1523ab0f01e85f.png");
          //background: `#ae95c1`,
          //background: 'white',
          //backgroundImage: `url(${result.coverPhoto ? result.coverPhoto : nftsImgs[5]})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className='relative flex-shrink-0 md:max-h-40'>
          <img
            src={imageFailedToLoad ? missingpic : coverPhoto || missingpic}
            onError={() => setImageFailedToLoad(true)}
            className='group-hover:scale-[1.03] transition-transform duration-300 ease-in-out will-change-transform object-cover w-full h-auto md:h-40 md:w-40'
            alt='nc-imgs'
          />
        </div>
        <div className='grow overflow-y-scroll h-full'>
          <span className='flex items-center justify-between w-full gap-2'>
            <div className='flex flex-col p-3 w-full'>
              {price != null && price > 0
                ? '' //  <h5 style={{ color: 'blueviolet' }}>Paid tool</h5>
                : ''}
              <h2 className='text-lg mb-2 overflow-ellipsis max-w-full'> {/* text-blue-700 underline */}
                {`${name}` || username}
              </h2>
              <p>
                <b>{downloads.toLocaleString()}</b> Users / <b>{views.toLocaleString()}</b> views
              </p>
              <p>
                Price:&nbsp;
                <b
                  style={{
                    color:
                      typeof price === 'number' && price > 0
                        ? 'rebeccapurple'
                        : 'black',
                  }}
                >
                  {typeof price === 'number' && price !== 0.0
                    ? `${price * 10} credits ${
                        priceFrequency === 'monthly' ? ' per month' : ''
                      }`
                    : 'Free'}
                </b>
              </p>
              <p
                className='overflow-ellipsis max-w-full text-sm'
                dangerouslySetInnerHTML={{
                  __html: trimDescriptionAndRemoveSkillfusionLinks(
                    description,
                    250
                  ),
                }}
              >
                {/* {`${trimDescription(description, 250)}` || ''} */}
              </p>
              {/*
         {result.coverPhoto ? <img src={result.coverPhoto} alt="Cover Photo"/> : null}
    
         <div className="flex items-center justify-end gap-2 z-10 text-blue-400">
            <AiOutlineContacts onClick={() => { navigate(`/profile/${result.authorId}`) }} className="hover:scale-110 " />
            <AiOutlineEye onClick={() => { navigate(`/fusion/${result._id}`) }} className="hover:scale-110 dark:text-blue-400 " />
            <MdViewInAr onClick={() => { navigate(`/fuser/${result._id}`) }} className="hover:scale-110 " />
    </div> */}
            </div>
          </span>
          {/*
      <div className="flex items-center justify-between">
      <div className="bg-green-400 p-2 w-full rounded-lg text-xs">
    
    </div>
    
    </div>*/}
        </div>
      </Link>
    </div>
  );
};

export default FusionCard;
