import { backendURL } from "../constants/environmental";
import { convertHTMLtableToText } from "./string";
import axios, { AxiosResponse } from "axios";
import ReactGA from "react-ga4";

export function pause(milliseconds: number) {
  return new Promise((resolve) =>
    setTimeout(() => resolve(true), milliseconds)
  );
}

function handleAxiosError(error: any) {
  //console.log('Handling axios error');
  const axiosError = error as any;
  const response = axiosError?.response;
  const status = response?.status;
  const statusText = response?.statusText;
  const message = response?.data?.error?.message;

  //console.log('error object recieved:', errorObj);

  if (status === 400) {
    ReactGA.event("bad_openai_request");
    throw new Error(
      response.error ||
        "Error: Bad request to OpenAI API. Try reducing the prompt/input size."
    );
  } else if (status === 401) {
    ReactGA.event("unauthorized_openai_call");
    throw new Error(
      "Error: Unauthorized request. Please check that you are logged in."
    );
  } else if (status === 402) {
    throw new Error("Error: You have insufficient credit.");
  } else if (
    status === 429 &&
    statusText.startsWith("You exceeded your current quota")
  ) {
    ReactGA.event("monthly_openai_limit_reached");
    throw new Error("Error: Monthly limit reached");
  } else {
    ReactGA.event("express_error_" + status);

    //console.error('Error from Backend API:', axiosError, axiosError?.response?.data);
    throw new Error(
      `Error from Backend API: Status ${status} ${
        message ? ` - ${message}` : ""
      }`
    );
  }
}

interface adaParamsType {
  authHeader: string;
  userId: string;
  model: string;
  input: string | string[];
}

export async function ada_embeddings({
  authHeader,
  userId = "",
  input,
  model,
}: adaParamsType) {
  const inputArray = !Array.isArray(input) ? [input] : input;
  const cleanedInputs = inputArray.map((element) =>
    element.replace("[\n\r]+/gm", " | ").slice(0, 12000)
  );
  const totalInputTokens = cleanedInputs.join("").length / 4;
  if (totalInputTokens > 8191) {
    const error = `Payload too large for ada_embeddings: ${totalInputTokens} tokens`;
    console.error(error);
    return error;
  }
  try {
    const response = await axios.post(
      `${backendURL}/openai/adaEmbeddings`,
      { userId, inputs: cleanedInputs, model: model || undefined },
      {
        headers: {
          Authorization: authHeader,
        },
        withCredentials: true,
      }
    );
    //return response.data;
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
}

export async function generateImageFromDALLE({
  prompt,
  authHeader,
  userId,
  toolId,
  model = "dall-e-2",
}: {
  prompt: string;
  authHeader: string;
  userId: string;
  toolId: string;
  model?: string;
}) {
  const endpoint = `${backendURL}/openai/image`;
  const parameters = {
    prompts: [prompt],
    userId,
    toolId,
    model,
  };
  console.log("image toolID", toolId);
  try {
    const response: AxiosResponse = await axios.post(endpoint, parameters, {
      headers: {
        Authorization: authHeader,
      },
    });
    if (response.status === 204) return response;
    if (response.status === 200) {
      const imageUrl = response.data.imageUrls[0];
      // console.log('imageURL', imageUrl);
      // console.log(response.data.updatedCredit);
      return { imageURL: imageUrl, updatedCredit: response.data.updatedCredit };
    } else {
      console.error("Error from Backend API:", response.data);
      return `Error from Backend API: ${response.statusText}`;
    }
  } catch (error) {
    if (error?.response?.data?.code === "content_policy_violation")
      throw new Error(
        "Your request was rejected as a result of OpenAI's safety system. Your prompt may contain text that is not allowed by OpenAI's safety system"
      );
    handleAxiosError(error);
  }
}

export async function fetchImageBlobFromBackend(url: string) {
  const encodedURL = encodeURIComponent(url);
  try {
    const response = await fetch(`${backendURL}/fetch-image/${encodedURL}`);
    const blob = await response.blob();
    if (response.ok) {
      return blob;
    } else {
      throw new Error("Error fetching image");
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function scrape(url: string) {
  // for web scraper

  const encodedURL = encodeURIComponent(url);

  let output = "";
  let html;

  try {
    const response = await fetch(`${backendURL}/fetch-data/${encodedURL}`);
    console.log(response);
    const jsonResponse = await response.json();
    if (response.ok) {
      html = jsonResponse;
    } else {
      const error = jsonResponse?.error;
      console.error("Error fetching URL. jsonRepsonse: ", jsonResponse);
      return `Error fetching URL: ${error ?? jsonResponse}`;
    }
  } catch (error) {
    console.log("Error in fetch request:", error);
    return error; //'An error occurred. Please try again.';
  }

  const match = html?.match(/<body[^>]*>([\s\S]*)<\/body>/);

  let content = match?.[1];

  if (typeof content !== "string") return "Invalid HTML content";

  content = content
    .replace(/<!--.*?-->/gs, "") // remove html comments
    .replace(
      /<(script|style|header|head|iframe|button|footer|svg|picture|nav|figure|aside|form|figcaption|blockquote|video|audio|canvas|comment|noscript|textarea|gu)( [^>]*?)?>.*?<\/\1>/gs,
      " "
    ) // remove irrelevant tags
    .replace(/<(img|meta|link|br|hr|input|base|embed|param)( [^>]*?)?>/g, "") // remove self closing tags
    .replace(/(<table[^>]*?>.*?<\/table>)/gms, convertHTMLtableToText);
  const noInnerHTMLRegex = /<([^\s>]*?)[^>]*?>\s*<\/\1>/g;
  while (noInnerHTMLRegex.test(content))
    content = content.replace(noInnerHTMLRegex, ""); // remove tags with no inner html

  const inlineTextRegex =
    /<p.*?<(a|b|em|span|strong|i|code|sup|sub|cite|q|abbr|time|var|del|ins|mark|small|u|s|bdi)( [^>]*?)?>(.*?)<\/\1>/gs;
  while (inlineTextRegex.test(content))
    content = content.replace(inlineTextRegex, "$3"); // turn inline text part of paragraphs into plain text

  content = content
    .replace(/<(a|span)( [^>]*?)?>.*?<\/\1>/gs, "") // remove all remaining a/span tags
    .replace(/(<(?:h[12345]|p|br|div|li)( [^>]*?)?>)/g, "\n\n") // add new line characters for readability
    .replace(/<[^>]+?>/g, " ") // remove any remaining html tags
    .replace(/\n\s*\n/g, "\n") // combine multiple new lines in to single new line
    .replace(/:/g, "-");

  output = content.trim();

  await pause(1000);

  return output;
}
