import React, { FC, useState, useEffect, useContext } from 'react';
import ReactGA from 'react-ga4';
import FuserLoader from '../../containers/FuserPage/FuserLoader';
import BlockProps from '../../models/BlockProps';
import { MAX_PREVIEW_CHARS } from '../../constants/blocks';
import '../../index.css';
import { useCredit } from '../../context/CreditContext';
import MyToolTips from '../../components/MyTooltip';
import FuserContext from '../../context/FuserContext';
import Block from '../../models/Block';
import GPTParameterSection from '../../containers/FuserPage/GPTParameterSection';
import RestartPromptButton from '../../containers/FuserPage/RestartPromptButton';
import useBlockRunner from '../../hooks/useBlockRunner';
import useCancellablePause from '../../hooks/useCancellablePause';
import axios from 'axios';
import TextareaAutosize from 'react-textarea-autosize';
import AutocompleteTextarea from '../../containers/FuserPage/AutocompleteTextarea';
import { useAuthHeader } from 'react-auth-kit';
import { getBrowser, getOperatingSystem } from '../../utils/userDeviceInfo';
import { testButtonStyles } from '../../constants/styles';
import { backendURL } from '../../constants/environmental';
import {
  updateAtIndex,
  updateAtIndexRun,
  flatten,
  unflatten,
  ensureArray,
  batchArray,
} from '../../utils/array';
import { truncateAfter } from '../../utils/string';

const randomNumberBetween = (min: number, max: number) => {
  return min + Math.random() * (max - min);
};

interface gptParamsType {
  userId?: string;
  toolId?: string;
  prompt?: string | string[];
  model?: string;
  temperature?: number;
  topP?: number;
  frequencyPenalty?: number;
  presencePenalty?: number;
  maximumWordsInResponse?: number;
  responseSchema?: string | string[];
}

const MAX_WORDS_FOR_MODEL: any = {
  turbo: 1500,
  gpt4o: 1500,
  gpt4: 1000,
  llama2: 750,
};

const defaultInputValues: any = {
  presencePenalty: 0.6,
  frequencyPenalty: 0.15,
  topP: 1,
  temperature: 0.9,
  maximumWordsInResponse: {
    turbo: 800,
    gpt4o: 800,
    gpt4: 600,
    llama2: 400,
  },
};

const PromptBlock: FC<BlockProps> = ({
  toolId,
  isLoading,
  setIsLoading,
  index,
  block,
  updateBlockData,
  handleChange,
  collapsed,
  isShared,
  // resultHtml,
}) => {
  const {
    userId,
    blockStyles,
    replacePlaceholders,
    blocks,
    setBlocks,
    setActivityLog,
    runnerMode,
    runnerIndex,
    tidyUpResponse,
    setRunnerIndex,
    setStillRunning,
    blockCancellationState,
    setBlockCancellationState,
    cancellingBlocksMemo,
    stillRunning,
    // handleTextareaFocus,
    // onDownloadResponseClick,
    // toggleResponseVisibility,
    // textAreaRefs,
  } = useContext(FuserContext);

  const authHeader = useAuthHeader()();

  const { pause, cancelPause } = useCancellablePause();

  useEffect(() => {
    if (index === 2) console.log('clearing error message on runnermode change');
    setErrorMessage('');
  }, [runnerMode]);

  async function handleAxiosError(axiosError: any) {
    //console.log('Handling axios error');
    const response = axiosError?.response;
    const status = response?.status;
    const statusText = response?.statusText;
    const message = response?.data?.error?.message;

    //console.log('error object recieved:', errorObj);

    if (status === 400) {
      ReactGA.event('bad_openai_request');
      throw new Error(
        response?.error ||
          'Error: Bad request to OpenAI API. Try reducing the prompt/input size.'
      );
    } else if (status === 401) {
      ReactGA.event('unauthorized_openai_call');
      throw new Error(
        'Error: Unauthorized request. Please check that you are logged in.'
      );
    } else if (status === 402) {
      throw new Error('Error: You have insufficient credit.');
    } else if (
      status === 429 &&
      statusText?.startsWith('You exceeded your current quota')
    ) {
      ReactGA.event('monthly_openai_limit_reached');
      throw new Error('Error: Monthly limit reached');
    } else {
      //      ReactGA.event('express_error_' + status);
      if (status && message)
        throw new Error(
          `Error: Status ${status} ${message ? ` - ${message}` : ''}`
        );
      else {
        // unknown error, log to database
        try {
          const errorDetails = {
            error: axiosError,
            toolId,
            runnerIndex,
            blocks,
            browser: getBrowser(),
            operatingSystem: getOperatingSystem(),
          };
          const postResponse = await axios.post(`${backendURL}/errorDetails`, {
            errorDetails,
          });
        } catch (e) {
          console.log('Unknown error:', e);
        }
        throw new Error(
          'An unknown error occurred, please contact support if the error persists.'
        );
      }
      //console.error('Error from Backend API:', axiosError, axiosError?.response?.data);
    }
  }

  async function gpt({
    userId = '',
    toolId,
    prompt = ['tell me that I forgot the prompt'],
    model = 'turbo',
    temperature = defaultInputValues.temperature,
    topP = defaultInputValues.topP,
    frequencyPenalty = defaultInputValues.frequencyPenalty,
    presencePenalty = defaultInputValues.presencePenalty,
    maximumWordsInResponse = defaultInputValues.maximumWordsInResponse['turbo'],
    responseSchema,
  }: gptParamsType) {
    let updatedCredit = null;

    prompt = ensureArray(prompt);
    const flatPrompt = flatten(prompt);

    const MAX_PROMPT_BATCH_SIZE = 2;
    const promptBatches: any[] = batchArray(MAX_PROMPT_BATCH_SIZE, flatPrompt);

    let responseSchemaBatches;
    if (responseSchema) {
      responseSchema = ensureArray(responseSchema);
      const flatResponseSchema = flatten(responseSchema);
      if (flatResponseSchema.length === flatPrompt.length) {
        responseSchemaBatches = batchArray(
          MAX_PROMPT_BATCH_SIZE,
          flatResponseSchema
        );
      } else if (flatResponseSchema.length === 1) {
        // use the same response schema for each prompt if there is only one schema
        responseSchemaBatches = promptBatches.map((batch: string[]) =>
          batch.map((prompt: string) => flatResponseSchema[0])
        );
      } else {
        console.log('Check dimensions:', { prompt, responseSchema });
        throw new Error('Response schema and prompt length mismatch');
      }
    }

    let response: any[] = [];
    const MAX_RETRIES = 5;
    const MAX_RESPONSE_WAIT_TIME_IN_SECONDS = 120;
    const INITIAL_PAUSE_DURATION_IN_MS = randomNumberBetween(5000, 10000);

    let retriesRemaining = MAX_RETRIES;

    for (
      let currentBatchIndex = 0, pauseDuration = INITIAL_PAUSE_DURATION_IN_MS;
      currentBatchIndex < promptBatches.length; // currentBatchIndex is updated in the block of code below

    ) {
      if (retriesRemaining === 0) {
        ReactGA.event('max_retries');
        throw new Error(
          `${MAX_RETRIES} consecutive failed requests from OpenAI, please try again later.`
        );
      }
      setPromptBatchData({
        promptBatchNumber: currentBatchIndex + 1,
        numberOfPromptBatches: promptBatches.length,
      });
      console.log(
        'prompt batch',
        currentBatchIndex + 1,
        'of',
        promptBatches.length,
        '. Retries remaining:',
        retriesRemaining
      );
      const promptBatch = promptBatches[currentBatchIndex];
      const responseSchemaBatch = responseSchemaBatches?.[currentBatchIndex];
      console.log('promptBatch', promptBatch);

      try {
        // fake response for testing
        // throw { response: { status: 400, statusText: 'AAAAAAA' } };

        const responseBatch: any = await axios.post(
          `${backendURL}/openai/prompt`,
          {
            userId,
            toolId,
            prompts: promptBatch,
            model,
            temperature,
            topP,
            frequencyPenalty,
            presencePenalty,
            maximumWordsInResponse,
            responseSchemas: responseSchema ? responseSchemaBatch : undefined,
          },
          {
            headers: {
              Authorization: authHeader,
            },
          }
        );

        console.log('prompt response received:', responseBatch);

        // handle prompt cancel
        if (responseBatch?.data?.message === 'cancelled') return responseBatch;

        if (responseBatch?.data?.message === 'timed-out') {
          console.log('response timed out, retrying');
          retriesRemaining--;
          continue;
        }
        // Destructure results and updatedCredit from response data
        const { results } = responseBatch.data;
        updatedCredit = responseBatch.data.updatedCredit;

        // Handle the new results and updated credit balance as needed
        response = [...response, ...results];
        currentBatchIndex++;
        pauseDuration = INITIAL_PAUSE_DURATION_IN_MS;
        retriesRemaining = MAX_RETRIES;
      } catch (error) {
        // console.log(error);
        const code = error?.code;
        const status = error?.response?.status;
        const statusText =
          error?.response?.statusText || error?.response?.statusMessage;
        // console.log('gpt error:', error.response);
        if (
          [502, 503].includes(status) ||
          (status === 429 && statusText?.startsWith('Rate limit reached')) ||
          statusText?.startsWith('Too Many Requests') ||
          (status === 500 &&
            statusText?.startsWith('The server had an error')) ||
          code === 'ERR_NETWORK'
        ) {
          if (status === 429) {
            ReactGA.event('token_limit_retry');
          }
          if (status === 500) ReactGA.event('openai_server_error_retry');
          if (status === 502) ReactGA.event('502_error_retry');
          if (status === 503) ReactGA.event('openai_server_overloaded_retry');
          if (code === 'ERR_NETWORK') ReactGA.event('network_error');

          console.log('pausing for', pauseDuration, 'ms');
          await pause(pauseDuration);
          pauseDuration = Math.min(
            MAX_RESPONSE_WAIT_TIME_IN_SECONDS * 1000,
            pauseDuration * randomNumberBetween(1.5, 1.7)
          );
          console.log(
            'pauseDuration set to',
            pauseDuration,
            'ms on prompt',
            currentBatchIndex
          );
          retriesRemaining--;
          continue;
        } else {
          if (error?.response?.data?.code === 'content_policy_violation') {
            const modelProvider = gptModel === 'llama2' ? 'Meta' : 'OpenAI';
            throw new Error(
              `Your request was rejected as a result of ${modelProvider}'s safety system. Your prompt may contain text that is not allowed by ${modelProvider}'s safety system`
            );
          }
          await handleAxiosError(error);
        }
      }
    }
    const unflattenedResults = unflatten(response, prompt);
    return { unflattenedResults, updatedCredit };
  }

  // const blocktypesWhichCancelPromptsOnEdit = ['info', 'question', 'prompt'];
  // const promptCancellationDependencies = useDeepCompareMemo(
  //   blocks
  //     .filter(
  //       (block: any, blockIndex: number) =>
  //         blockIndex !== index &&
  //         blocktypesWhichCancelPromptsOnEdit.includes(block.blocktype)
  //     )
  //     .map((block: any) => {
  //       if (block.blocktype === 'question') return block.data;
  //       if (block.blocktype === 'prompt') return block.data.response;
  //       const { response, ...dataExceptResponse } = block.data;
  //       return dataExceptResponse;
  //     })
  // );

  const [updatedOutput, setUpdatedOutput] = useState('');
  const [updateButtonClass, setUpdateButtonClass] = useState(
    'bg-slate-200 text-slate-500 hover:bg-slate-200'
  );
  const [updateButtonText, setUpdateButtonText] = useState('update');
  const [tidyResponse, setTidyResponse] = useState('');
  const [responseDescription, setResponseDescription] = useState('');

  const updateBlocks = () =>
    setBlocks((blocks: Block[]) => updateAtIndex(index, block, blocks));

  const { credit, updateCredits } = useCredit();

  useEffect(() => {
    if (block.data.gptModel === undefined) {
      block.data.gptModel = 'turbo';
    }
    if (block.data.showOutputToUser === undefined) {
      block.data.showOutputToUser = true;
    }
    if (block.data.temperature === undefined) {
      block.data.temperature = defaultInputValues.temperature;
    }
    if (block.data.topP === undefined) {
      block.data.topP = defaultInputValues.topP;
    }
    if (block.data.frequencyPenalty === undefined) {
      block.data.frequencyPenalty = defaultInputValues.frequencyPenalty;
    }
    if (block.data.presencePenalty === undefined) {
      block.data.presencePenalty = defaultInputValues.presencePenalty;
    }
    if (
      block.data.gptModel &&
      typeof block.data.gptModel === 'string' &&
      block.data.maximumWordsInResponse === undefined
    ) {
      block.data.maximumWordsInResponse =
        defaultInputValues.maximumWordsInResponse[block.data.gptModel];
    }
    if (block.data.editableOutput === undefined) {
      block.data.editableOutput = false;
    }
    updateBlocks();
  }, []);

  const onPromptSaveClick = async () => {
    // console.log(`running block ${index}`);
    setIsLoading(true); // start loading
    setErrorMessage('');
    const currentBlock = blocks[index];

    const promptText = currentBlock.data.inputToProcess;
    const responseSchema = currentBlock.data.responseSchema;
    const messageText = currentBlock.data.message;
    const processedInput = promptText
      ? replacePlaceholders(promptText, blocks)
      : '';

    const processedResponseSchema = responseSchema
      ? replacePlaceholders(responseSchema, blocks)
      : '';

    try {
      //For testing error handling
      //throw new Error(`Error: ${Math.random()}`);
      console.log('awaiting gpt');
      const gptResponse: any = await gpt({
        userId: userId ?? '',
        toolId: toolId ?? '',
        prompt: processedInput,
        model: block.data.gptModel,
        temperature: +block.data.temperature,
        topP: +block.data.topP,
        frequencyPenalty: +block.data.frequencyPenalty,
        presencePenalty: +block.data.presencePenalty,
        maximumWordsInResponse: +block.data.maximumWordsInResponse,
        responseSchema: processedResponseSchema,
      });

      // console.log(
      //   `Response from block ${index}:`,
      //   gptResponse,
      //   'sent: ',
      //   processedInput
      // );

      // update credits here
      const updatedCredit =
        gptResponse?.data?.updatedCredit ?? gptResponse?.updatedCredit;

      if (updatedCredit != null) updateCredits(updatedCredit * 10);

      if (gptResponse?.data?.message === 'cancelled') {
        console.log('Cancelled prompt at block', index);
        return;
        //return 'CANCEL';
      }

      const promptResponses = gptResponse.unflattenedResults;

      console.log('Prompt response saving for block', index);

      setBlocks((blocks: Block[]) => {
        // Update the blocks state
        const newBlocks = [...blocks];
        newBlocks[index] = {
          ...newBlocks[index],
          updatedBlock: true,
          data: {
            ...newBlocks[index].data,
            inputToProcess: promptText,
            processedInput,
            message: messageText,
            response: [], // reset the response
          },
        };

        for (let i = 0; i < promptResponses.length; i++) {
          newBlocks[index].data.response[i] = promptResponses[i]; //.trim(); // Add response directly to the response array
        }

        //console.log('Setting blocks to:', fnewBlocks); // writes this comment every time the blocks are rendered
        return newBlocks;
      }); // update the blocks
    } catch (error) {
      // console.log('Error generating prompt', error);
      const errorObj = error as any;
      setErrorMessage(
        (errorObj.message ?? 'Error with GPT response') +
          (runnerMode
            ? ' Click the restart button to try running this block again.'
            : '')
      );
      setStillRunning(false);
      return;
      //return 'CANCEL';
    } finally {
      console.log('setting isloading false for block', index);
      setIsLoading(false);
    }
    setActivityLog((prevLog: string[]) => [
      ...prevLog,
      `Saved prompt block at index: ${index}`,
    ]);
  };

  const cancelPrompt = async () => {
    if (isLoading) {
      console.log('cancelling prompt for block', index);
      // cancel the pause if the block is waiting for the next retry
      cancelPause();
      const cancelResponse = await axios.get(
        `${backendURL}/openai/cancelPrompt`,
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );
      console.log('cancelPrompt response:', cancelResponse);
      console.log('setting isloading false for block', index);
      setIsLoading(false);
    }
  };

  const onCancelClick = async () => {
    cancelPrompt();
    setStillRunning(false);
  };

  const onOtherBlockCancelRequest = async () => {
    const { idOfBlockToRunAfterwards } = blockCancellationState;

    if (block.id !== idOfBlockToRunAfterwards) {
      await cancelPrompt();
      console.log('removing block', index, 'from ids to cancel');
      setBlockCancellationState((previousState: any) => {
        return {
          ...previousState,
          idsOfBlocksToCancel: previousState.idsOfBlocksToCancel.filter(
            (id: string) => id !== block.id
          ),
        };
      });
    }
  };

  useEffect(() => {
    if (index === 1) {
      console.log('isloading at index', index, isLoading);
    }
  }, [isLoading]);

  useEffect(() => {
    if (cancellingBlocksMemo) onOtherBlockCancelRequest();
  }, [cancellingBlocksMemo]);

  useBlockRunner(onPromptSaveClick, index);

  useEffect(() => {
    if (isShared) return;
    const { cancellingBlocks, idsOfBlocksToCancel, idOfBlockToRunAfterwards } =
      blockCancellationState;

    if (cancellingBlocks) {
      const indexOfBlockToRunNext = blocks.findIndex(
        ({ id }: any) => id === idOfBlockToRunAfterwards
      );
      if (idsOfBlocksToCancel.length === 0 && index === indexOfBlockToRunNext) {
        // runs after all other blocks have cancelled
        console.log('restarting block', indexOfBlockToRunNext);
        setRunnerIndex(indexOfBlockToRunNext);

        if (!stillRunning) {
          onPromptSaveClick();
        }

        setBlockCancellationState({
          cancellingBlocks: false,
          idsOfBlocksToCancel: [],
          idOfBlockToRunAfterwards: undefined,
        });
      }
    }
  }, [blockCancellationState]);

  const onRestartPrompt = async () => {
    const idsOfBlocksToCancel = blocks
      .filter((block: any, blockIndex: number) => {
        return block.blocktype === 'prompt' && blockIndex !== index;
      })
      .map((block: any) => block.id);

    setBlockCancellationState({
      cancellingBlocks: true,
      idsOfBlocksToCancel,
      idOfBlockToRunAfterwards: block.id,
    });
  };

  // const onTextareaFocus =
  //   (textareaId: number) =>
  //   (e: React.FocusEvent<HTMLTextAreaElement, Element>) => {
  //     handleTextareaFocus(e, block.id, textareaId);
  //   };

  // const handleDownloadClick = () => onDownloadResponseClick(index);

  // const handleHamburgerClick = () => toggleResponseVisibility(index);

  const handleCheckboxChange = () => {
    const { showOutputToUser } = block.data;

    if (showOutputToUser) {
      updateBlockData('showOutputToUser', false);
    } else {
      updateBlockData('showOutputToUser', true);
    }
  };

  const handleEditableOutput = () => {
    const { editableOutput } = block.data;
    if (editableOutput) {
      updateBlockData('editableOutput', false);
    } else {
      updateBlockData('editableOutput', true);
    }
  };

  const editsMade = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    if (value != response) {
      //setRunnerIndex(-Infinity);
      console.log('editsMade executing');
      //const [updateButtonClass,setUpdateButtonClass] = useState('bg-slate-200 text-slate-500 hover:bg-slate-200');
      setUpdateButtonClass(''); // make button usable again
      setUpdateButtonText('update');
      setUpdatedOutput(value);
      block.data.response = value;
      updateBlocks();
    }

    // set the button back to update
  };

  const handleOutputUpdate = () => {
    //console.log('running? before', stillRunning, runnerMode, runnerIndices);
    if (updatedOutput) block.data.response = updatedOutput;
    setUpdateButtonClass('bg-slate-200 text-slate-500 hover:bg-slate-200');
    setUpdateButtonText('updated!');
    //setStillRunning(true);
    setBlocks((blocks: Block[]) => updateAtIndexRun(index, block, blocks)); // restarts runner
    console.log('updated block at index', index);
    //updateBlocks();
    //setRunnerIndex(index + 1);
    //console.log('running?',stillRunning,runnerMode,runnerIndices,index,blocks[runnerIndices.previous].data.response?.length);
  };

  const [errorMessage, setErrorMessage] = useState<string>('');

  const {
    response,
    blockHeading,
    inputToProcess,
    responseSchema,
    message,
    gptModel,
    showOutputToUser,
    editableOutput,
    temperature,
    topP,
    frequencyPenalty,
    presencePenalty,
    maximumWordsInResponse,
  } = block.data;

  useEffect(() => {
    let newTidyResponse: any = '';
    if (Array.isArray(response)) {
      newTidyResponse = response.join('\n----\n');
      setTidyResponse(newTidyResponse);
    } else setTidyResponse(response);

    //console.log(`block ${index} response:`, response);
    setResponseDescription(tidyUpResponse(response));
    //setResponseDescription(response);
    //console.log(response, tidyUpResponse(response));
  }, [response]);

  const handleModelChange = (e: any) => {
    const { value: newModel } = e.target;
    block.data.maximumWordsInResponse =
      defaultInputValues.maximumWordsInResponse[newModel];
    updateBlocks();
    handleChange(e);
  };

  const [promptBatchData, setPromptBatchData] = useState({
    promptBatchNumber: 0,
    numberOfPromptBatches: 0,
  });

  const { promptBatchNumber, numberOfPromptBatches } = promptBatchData;

  const processedMessage = replacePlaceholders(message, blocks);

  if (isShared) {
    return (
      <>
        <div>
          {truncateAfter(MAX_PREVIEW_CHARS, blockHeading || 'GPT response')}
        </div>
        <TextareaAutosize
          className='resize-y grow min-h-[20vh] max-h-[50vh] shared-textarea bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner overflow-y-scroll w-full'
          value={response}
          readOnly={true}
        />
      </>
    );
  }

  if (collapsed) {
    if (errorMessage)
      return (
        <>
          <div>GPT Error</div>
          <div>{truncateAfter(MAX_PREVIEW_CHARS, errorMessage.toString())}</div>
        </>
      );
    if (response?.length > 0 && showOutputToUser)
      return (
        <>
          <div>
            {truncateAfter(
              MAX_PREVIEW_CHARS,
              message ? processedMessage : 'GPT response'
            )}
          </div>
          <div>{truncateAfter(MAX_PREVIEW_CHARS, response.toString())}</div>
        </>
      );
    else return <div>Prompt block</div>;
  }

  // console.log({ model: block.data.gptModel });

  console.log({ response });
  return (
    <FuserLoader
      name='Prompt Block'
      loading={isLoading}
      message={`${
        message ? processedMessage + ' ' : ''
      }(Prompt ${promptBatchNumber} of ${numberOfPromptBatches})`}
      onCancelClick={onCancelClick}
    >
      {runnerMode ? (
        response?.length > 0 || response === '' ? (
          <>
            <p>{processedMessage}</p>

            {showOutputToUser ? (
              <>
                <TextareaAutosize
                  className='resize-y max-h-96 bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner overflow-y-scroll w-full'
                  {...(editableOutput
                    ? {
                        value: response,
                        onChange: editsMade,
                      }
                    : {
                        value: response,
                        readOnly: true,
                      })}
                />
                <span className='flex items-center'>
                  <RestartPromptButton
                    onRestartPrompt={onRestartPrompt}
                    className={errorMessage ? 'avatar-glow' : ''}
                  />{' '}
                  &nbsp; &nbsp;
                  {editableOutput ? (
                    <button
                      className={testButtonStyles + ' ' + updateButtonClass}
                      onClick={handleOutputUpdate}
                    >
                      {updateButtonText}
                    </button>
                  ) : null}
                </span>
              </>
            ) : (
              <div>Prompt block</div>
            )}

            {errorMessage && <p>{errorMessage}</p>}
            {/*
              <button>
                EDIT
              </button>
            */}
          </>
        ) : errorMessage ? (
          <>
            <p>{errorMessage}</p>
            <RestartPromptButton
              onRestartPrompt={onRestartPrompt}
              className='animate-toms-glow'
            />
          </>
        ) : (
          <>
            <div>Prompt block</div>
            {runnerIndex === index && (
              <span className='flex items-center'>
                <RestartPromptButton onRestartPrompt={onRestartPrompt} /> &nbsp;
              </span>
            )}
          </>
        )
      ) : (
        <div
          className={blockStyles}
          key={index}
        >
          <>
            <label className='text-xs'>
              Prompt:{' '}
              <MyToolTips
                content="
              <p>This is where you write prompts and send them to whichever GPT model you choose.</p> 

              <p>We recommend adding <q>Optional Message for user</q> to improve the user experience. They won't be able to see your prompt so they will be waiting for it to process.</p>

              <p><q>Show response</q> lets the user see what is returned from GPT, in most cases it will be okay to let the user see this.
You may want to let the user edit it too.</p>

<p>GPT Models:
<br />
- Turbo 3.5 is the same model as the current free version of chatGPT, it is very cheap and it can process up to 12,000 words.
<br />
- GPT-4 is about 40x more expensive than Turbo. It is a text completion model so you can start the answer you want and it will complete it, as opposed to replying. It can be helpful if turbo is not producing the answer you wish.
<br />
 </p>

<p>Increasing <q>Temperature</q> will increase the randomness of your output. Information on the other settings are available in the openAI docs.</p>"
                tipID={index.toString()}
                datatooltipplace='below'
              />
            </label>

            <AutocompleteTextarea
              autosize={true}
              block={block}
              index={index}
              onChange={handleChange}
              textAreaIndex={0}
              className='w-full resize-none bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner h-36 overflow-y-scroll'
              name='inputToProcess'
              value={inputToProcess || ''}
              placeholder='Enter your prompt here'
            />

            <AutocompleteTextarea
              autosize={true}
              block={block}
              index={index}
              onChange={handleChange}
              textAreaIndex={1}
              className='w-full resize-none bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner h-36 overflow-y-scroll'
              name='responseSchema'
              value={responseSchema || ''}
              placeholder='(Optional): Enter the JSON response schema here'
            />

            <div className='flex flex-col gap-2'>
              <label className='text-xs w-max-content'>
                GPT Model:
                <select
                  className='text-xs bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner '
                  onChange={handleModelChange}
                  value={gptModel}
                  name='gptModel'
                >
                  <option value='turbo'>Turbo 3.5</option>
                  <option value='gpt4'>GPT-4</option>
                  <option value='gpt4o'>GPT-4o</option>
                  <option value='llama2'>LLaMA-2</option>
                </select>
              </label>

              <div className='grid lg:grid-cols-2 md:grid-cols-1 gap-3'>
                <GPTParameterSection
                  labelText='Temperature:'
                  name='temperature'
                  min='0'
                  max='2'
                  value={temperature ?? defaultInputValues.temperature}
                  onChange={handleChange}
                />

                <GPTParameterSection
                  labelText='Top P:'
                  name='topP'
                  min='0'
                  max='1'
                  value={topP ?? defaultInputValues.topP}
                  onChange={handleChange}
                />

                <GPTParameterSection
                  labelText='Frequency penalty:'
                  name='frequencyPenalty'
                  min='0'
                  max='2'
                  value={
                    frequencyPenalty ?? defaultInputValues.frequencyPenalty
                  }
                  onChange={handleChange}
                />

                <GPTParameterSection
                  labelText='Presence penalty:'
                  name='presencePenalty'
                  min='0'
                  max='2'
                  value={presencePenalty ?? defaultInputValues.presencePenalty}
                  onChange={handleChange}
                />

                <GPTParameterSection
                  labelText='Maximum words in response:'
                  name='maximumWordsInResponse'
                  min='1'
                  max={MAX_WORDS_FOR_MODEL[gptModel]}
                  step='1'
                  value={
                    maximumWordsInResponse ??
                    defaultInputValues.maximumWordsInResponse[gptModel]
                  }
                  onChange={handleChange}
                  tooltipContent='Setting a smaller response length increases the chances of your prompt going through quickly, and reduces the risk of capacity issues.'
                />
              </div>
            </div>

            <label className='text-xs'>Optional heading for user:</label>

            {/* old optional message input */}
            {/* <input
              className='resize-none bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner'
              type='text'
              name='message'
              value={message ?? ''}
              onChange={handleChange}
            /> */}

            <AutocompleteTextarea
              autosize={true}
              block={block}
              index={index}
              onChange={handleChange}
              textAreaIndex={2}
              className='w-full resize-none bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner'
              name='message'
              value={message ?? ''}
              placeholder='Heading for user'
            />

            <div className='flex gap-4 items-center'>
              <label className='flex gap-2 items-center'>
                <input
                  type='checkbox'
                  name='showOutputToUser'
                  checked={showOutputToUser ?? false}
                  onChange={handleCheckboxChange}
                />

                <p className='select-none'>Show response to user</p>
              </label>

              {showOutputToUser && (
                <label className='flex gap-2 items-center'>
                  <input
                    type='checkbox'
                    name='editableOutput'
                    checked={editableOutput ?? false}
                    onChange={handleEditableOutput}
                  />

                  <p className='select-none'>Let user edit the output</p>
                </label>
              )}
            </div>

            <span className='flex items-center justify-between w-full'>
              <button
                className={testButtonStyles}
                onClick={onPromptSaveClick}
              >
                TEST
              </button>

              {/* <button 
                className={testButtonStyles} 
                onClick={handleDownloadClick}
                >
                  Download
              </button>*/}
            </span>

            <div className='w-full'>
              {
                <>
                  <TextareaAutosize
                    className='resize-none max-h-96 bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner h-36 overflow-y-scroll w-full'
                    {...(editableOutput
                      ? {
                          value: tidyResponse,
                          onChange: editsMade,
                        }
                      : {
                          value: tidyResponse,
                          readOnly: true,
                        })}
                  />
                  <p> &nbsp; {responseDescription}</p>
                </>
              }
            </div>

            <p>{errorMessage}</p>
          </>
        </div>
      )}
    </FuserLoader>
  );
};

export default PromptBlock;
