import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AddOrSaveBlockSection from './AddOrSaveBlockSection';
import BlockReferenceInputSection from './BlockReferenceInputSection';
import IsPublishedSection, {
  IsAdminPublishedSection,
} from './IsPublishedSection';
//import Clipboard from './Clipboard';
//import ActivityLogSection from './ActivityLogSection';
import MyToolTips from '../../components/MyTooltip';
import Avatar from '../../shared/Avatar/Avatar';
import { Link } from 'react-router-dom';
import ImageGenerator from './ImageGenerator';
import PublishForm from './PublishForm';
//import CreatorDetails from './CreatorDetails';
import { useAuthHeader, useAuthUser } from 'react-auth-kit';
import ReviewForm from './ReviewForm';
import FuserContext from '../../context/FuserContext';
import axios from 'axios';
import Multiselect from 'react-widgets/Multiselect';
import 'react-widgets/styles.css';
import Toggle from '../../components/Toggle';
import { YoutubeAccordionInfo } from '../NftDetailPage/AccordionInfo';
import { MdClose } from 'react-icons/md';
import { nftsImgs } from '../../constants/fakeData';
import { TbLayoutSidebarLeftExpand } from 'react-icons/tb';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { useCookies } from 'react-cookie';
import ImageWithFallback from '../../components/ImageWithFallback';
import { backendURL } from '../../constants/environmental';
import { iconStyle, menuButtonStyles } from '../../constants/styles';
import { buildCategoryTree } from '../../utils/tree';
import ShareToolSection from '../../components/ShareToolSection';

let categoryTree: any[] = [];

const CategoryMultiselect: FC<any> = ({
  value,
  onChange,
  onSelect,
  data,
  selectedCategory,
  goToTopLevelCategories,
}) => {
  useEffect(() => {
    const multiselectInput = document.querySelector('.rw-widget-input');
    if (!multiselectInput) return;

    multiselectInput.addEventListener('click', goToTopLevelCategories);
    multiselectInput.addEventListener('blur', goToTopLevelCategories);

    return () => {
      multiselectInput.removeEventListener('click', goToTopLevelCategories);
      multiselectInput.removeEventListener('blur', goToTopLevelCategories);
    };
  }, []);

  return (
    <Multiselect
      style={{ width: '100%' }}
      dataKey='_id'
      textField='catName'
      value={value}
      onChange={onChange}
      onSelect={onSelect}
      // onSearch={handleCatSearch}
      data={data}
      filter='contains'
      // add a heading indicating when the list is of subcategories
      {...(selectedCategory
        ? { groupBy: (subcategory: any) => 'Subcategory (optional)' }
        : {})}
      // listComponent={CustomListComponent}
    />
  );
};

const FuserSidebar: FC<any> = ({
  isForSharedBlock,
  title,
  description,
  isPublished,
  authorCoverPhoto,
  authorId,
  authorName,
  toolId,
  toggleSidebar,
}) => {
  const {
    runnerMode,
    isAuthor,
    userId,
    autosaveMode,
    setAutosaveMode,
    categories,
    setCategories,
    chosenCategories,
    setChosenCategories,
    subscriptionInfo,
    setSubscriptionInfo,
    tags,
    setTags,
    furtherInfo,
  } = useContext(FuserContext);

  const chosenCategoryNames = chosenCategories?.map(
    ({ catName }: any) => catName
  );

  const authHeader = useAuthHeader()();
  const user = useAuthUser();

  // const location = useLocation();
  // Placeholder for the image generation handler
  // const handleImageGeneration = () => {
  //   console.log('Image generation logic goes here');
  //   // Replace with your actual logic...
  // };

  // Placeholder for the publishing handler
  // console.log("fuser sidebar acknowledgment of owner \n", isOwner)
  // const auth = useAuthUser();
  // Placeholder for creator details
  // const [creatorName, setCreatorName] = useState('');
  // const [profileImageUrl, setProfileImageUrl] = useState('');
  const [displayedCategories, setDisplayedCategories] = useState(categories);
  const [selectedCategory, setSelectedCategory] = useState<any>();

  useEffect(() => {
    if (isForSharedBlock) return;
    const fetchCategories = async () => {
      const res = await axios.get(`${backendURL}/categories/all`);
      const loadedCategories = res.data;
      categoryTree = buildCategoryTree(loadedCategories);

      setCategories(loadedCategories);
      setDisplayedCategories(categoryTree.map(({ catName }: any) => catName));
      // console.log(
      //   'loadedCategories',
      //   categories,
      //   loadedCategories,
      //   chosenCategories
      // );
    };

    fetchCategories();
    //console.log('blocks.categories;', chosenCategories);
  }, []);

  const navigate = useNavigate();

  const goToToolDetailsPage = () => {
    return navigate(`/fusion/${toolId}`);
  };

  // console.log('hi');
  // need to do this for blur or maybe typing etc
  const goToTopLevelCategories = () => {
    setDisplayedCategories(categoryTree.map(({ catName }: any) => catName));
    setSelectedCategory(undefined);
  };

  // const CustomListComponent = ({ children }: any) => (
  //   <div>
  //     {selectedCategory && (
  //       <div className='custom-heading'>Choose subcategory (optional)</div>
  //     )}
  //     <ul>
  //       {children.map(child => (
  //         <li>{child}</li>
  //       ))}
  //     </ul>
  //   </div>
  // );
  // console.log(chosenCategories);

  const handleCatChange = (newSelectedCategoryNames: string[]) => {
    // console.log(newSelectedCategoryNames);
    if (newSelectedCategoryNames.length >= chosenCategories.length) return;

    // run this code if a category has been removed
    const unselectedCategory = chosenCategories.filter(
      ({ catName }: any) => !newSelectedCategoryNames.includes(catName)
    )?.[0];
    setChosenCategories((previous: string[]) =>
      previous.filter((item: string) => item !== unselectedCategory)
    );
    setDisplayedCategories(categoryTree.map(({ catName }: any) => catName));
    setSelectedCategory(undefined);
  };

  const handleCatSelect = (selectedName: any) => {
    setChosenCategories((previous: string[]) => [
      ...(selectedCategory ? previous.slice(0, -1) : previous),
      categories.find(({ catName }: any) => selectedName === catName),
    ]);

    const categoryNode = (
      selectedCategory ? selectedCategory.children : categoryTree
    ).find(({ catName }: any) => catName === selectedName);

    const { children } = categoryNode;
    const childrenNotChosen = children.filter(
      ({ catName }: any) => !chosenCategoryNames.includes(catName)
    );
    if (childrenNotChosen.length > 0) {
      setDisplayedCategories(
        childrenNotChosen.map(({ catName }: any) => catName)
      );
      setSelectedCategory(categoryNode);
    } else {
      setDisplayedCategories(categoryTree.map(({ catName }: any) => catName));
      setSelectedCategory(undefined);
    }
  };

  // const handleCatSearch = (searchTerm: string) => {
  //   console.log(searchTerm);
  // };

  // console.log(
  //   'chosenCategories',
  //   chosenCategories,
  //   'selectedCategory',
  //   selectedCategory,
  //   'displayed categories',
  //   displayedCategories
  // );

  const [autosaveErrorMessage, setAutosaveErrorMessage] = useState('');

  const handleAutosaveToggle = async () => {
    const newAutosaveMode = !autosaveMode;
    try {
      const saveAutosaveModeResponse = await axios.put(
        `${backendURL}/user/${userId}/${toolId}/autosaveMode`,
        { autosaveMode: newAutosaveMode },
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );
      setAutosaveErrorMessage('');
      setAutosaveMode(newAutosaveMode);
    } catch (e) {
      console.log(e, e.response.status);
      if (e?.response?.status === 404) {
        setAutosaveErrorMessage(
          'Please save this block again to enable autosave mode for yourself (it is already enabled for users other than the tool creator).'
        );
      }
    }
  };

  // const htmlDescription = 'Description: ' + description?.replace(/\n/g, '<br>');

  const cancelSubscription = async () => {
    try {
      const response = await axios.patch(
        `${backendURL}/user/${userId}/${toolId}/subscription`,
        { subscriptionDueCancellation: true },
        {
          headers: {
            Authorization: authHeader,
            'Content-Type': 'application/json',
          },
        }
      );
      console.log(response);
      setSubscriptionInfo({
        ...subscriptionInfo,
        subscriptionDueCancellation: true,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const reactivateSubscription = async () => {
    try {
      const response = await axios.patch(
        `${backendURL}/user/${userId}/${toolId}/subscription`,
        { subscriptionDueCancellation: false },
        {
          headers: {
            Authorization: authHeader,
            'Content-Type': 'application/json',
          },
        }
      );
      console.log(response);
      setSubscriptionInfo({
        ...subscriptionInfo,
        subscriptionDueCancellation: false,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const [tagInputValue, setTagInputValue] = useState('');

  const handleAddTagClick = () => {
    const trimmedTag = tagInputValue.trim();
    if (trimmedTag === '') return alert('Tag is empty');
    if (tags.includes(trimmedTag)) return alert('Tag already exists');

    setTags([...tags, tagInputValue]);
    setTagInputValue('');
  };
  // console.log(isPublished);

  const [recommendedTools, setRecommendedTools] = useState<any[] | undefined>();

  const [recommendedToolsViewedStatus, setRecommendedToolsViewedStatus] =
    useState<any>();

  const [recommendedToolsClickedStatus, setRecommendedToolsClickedStatus] =
    useState<any>();

  const [currentRecommendedToolId, setCurrentRecommendedToolId] = useState();

  const loadRecommendedTools = async () => {
    try {
      const recommendedToolsResponse = await axios.get(
        `${backendURL}/blocks/${toolId}/recommendedTools`,
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );

      setRecommendedTools(recommendedToolsResponse.data);
      setRecommendedToolsViewedStatus(
        Object.fromEntries(
          recommendedToolsResponse.data.map(({ _id }: any) => [_id, false])
        )
      );
      setRecommendedToolsClickedStatus(
        Object.fromEntries(
          recommendedToolsResponse.data.map(({ _id }: any) => [_id, false])
        )
      );
      if (recommendedToolsResponse.data.length === 0) {
        return;
      }
      setCurrentRecommendedToolId(recommendedToolsResponse.data[0]._id);
    } catch (error) {
      console.log('Error loading recommended tools');
    }
  };

  useEffect(() => {
    loadRecommendedTools();
  }, []);

  const [carouselInViewport, setCarouselInViewport] = useState<boolean>(false);
  const [incrementingViews, setIncrementingViews] = useState<boolean>(false);
  useEffect(() => {
    // increment views at most once for each tool per page load
    if (
      !carouselInViewport ||
      currentRecommendedToolId === undefined ||
      recommendedToolsViewedStatus?.[currentRecommendedToolId] ||
      incrementingViews
    ) {
      return;
    }
    setIncrementingViews(true);
    axios
      .get(
        `${backendURL}/recommendation/${toolId}/${currentRecommendedToolId}/incrementViews`,
        {
          headers: {
            Authorization: authHeader,
          },
        }
      )
      .then(() => {
        // console.log('incremented views');
        recommendedToolsViewedStatus[currentRecommendedToolId] = true;
      })
      .catch(() => console.log('error incrementing views'))
      .finally(() => setIncrementingViews(false));
  }, [carouselInViewport, currentRecommendedToolId]);

  const carouselRef = useCallback((element: any) => {
    if (!element) return;

    const observer = new IntersectionObserver(([observedElement]) => {
      setCarouselInViewport(observedElement.isIntersecting);
    });

    observer.observe(element);

    // return () => observer.disconnect();
  }, []);

  const [_, setCookie] = useCookies(['recommendationSourceToolId']);

  return (
    <aside className='shrink-0 flex flex-col bg-neutral-200 dark:bg-neutral-900 p-2 rounded-lg gap-8 overflow-y-scroll sm:h-screen sticky top-0 sm:w-1/4 sm:flex-shrink-0 sm:static items-center pb-80'>
      {/* <div className='flex gap-2 justify-center items-center'>
        <p>Hide sidebar</p>
        <button
          className={menuButtonStyles}
          onClick={toggleSidebar}
        >
          <ArrowRightIcon className={iconStyle} />
        </button>
      </div> */}

      {runnerMode || isForSharedBlock || <AddOrSaveBlockSection />}

      {runnerMode || isForSharedBlock ? (
        <>
          {title && (
            <div className='w-full bg-blue-200 dark:bg-neutral-800 p-4 flex flex-col gap-2 items-center justify-between rounded-lg shadow-xl'>
              <button
                className={menuButtonStyles + ' hidden sm:block mr-auto'}
                onClick={toggleSidebar}
              >
                <TbLayoutSidebarLeftExpand className={iconStyle} />
              </button>

              {recommendedTools?.length ? (
                <>
                  <h2>Users Also Liked:</h2>

                  <div ref={carouselRef}>
                    <Carousel
                      autoPlay
                      infiniteLoop
                      interval={5000}
                      onChange={recommendedToolIndex => {
                        const recommendedToolId =
                          recommendedTools[recommendedToolIndex]._id;

                        setCurrentRecommendedToolId(recommendedToolId);
                      }}
                      useKeyboardArrows
                      showThumbs={false}
                      showIndicators={false}
                      showStatus={false}
                    >
                      {recommendedTools.map(tool => (
                        <div key={tool._id}>
                          <ImageWithFallback
                            src={tool.coverPhoto}
                            inContainer={false}
                          />
                          <div className='legend'>
                            <p className='leading-normal'>{tool.name}</p>
                            <Link
                              className='cursor-pointer underline text-blue-300'
                              to={`/fusion/${tool._id}`}
                              target='_blank'
                              rel='noopener'
                              onClick={() => {
                                // increment clicks at most once for each tool per page load
                                if (
                                  !carouselInViewport ||
                                  !currentRecommendedToolId ||
                                  recommendedToolsClickedStatus?.[
                                    currentRecommendedToolId
                                  ]
                                ) {
                                  return;
                                }
                                setCookie(
                                  'recommendationSourceToolId',
                                  toolId,
                                  {
                                    // expires in 1 hour
                                    expires: new Date(
                                      new Date().getTime() + 60 * 60 * 1000
                                    ),
                                    path: '/', // makes the cookie accessible in all tabs
                                    domain: 'skillfusion.ai',
                                  }
                                );
                                axios
                                  .get(
                                    `${backendURL}/recommendation/${toolId}/${currentRecommendedToolId}/incrementClicks`,
                                    {
                                      headers: {
                                        Authorization: authHeader,
                                      },
                                    }
                                  )
                                  .then(() => {
                                    // console.log('incremented clicks');
                                    recommendedToolsClickedStatus[
                                      currentRecommendedToolId
                                    ] = true;
                                  })
                                  .catch(() =>
                                    console.log('error incrementing clicks')
                                  );
                              }}
                            >
                              Open in new tab
                            </Link>
                          </div>
                        </div>
                      ))}
                    </Carousel>
                  </div>
                </>
              ) : null}
              <hr />
              <br />

              <div className='w-full flex justify-between items-center'>
                <h1 className='text-xl'>{title}</h1>
              </div>
              {description && (
                <>
                  <YoutubeAccordionInfo description={description} />
                  <br />
                </>
              )}

              {furtherInfo && (
                <>
                  <YoutubeAccordionInfo
                    description={furtherInfo}
                    customTitle='Further info'
                    defaultOpen={false}
                    scroll
                  />
                  <br />
                </>
              )}

              {isForSharedBlock || (
                <>
                  {subscriptionInfo &&
                    (subscriptionInfo.subscriptionDueCancellation ? (
                      <>
                        <p>
                          Your access to this tool will end on{' '}
                          {new Date(
                            subscriptionInfo.reviewDate
                          ).toLocaleDateString()}
                          .
                        </p>
                        <button onClick={reactivateSubscription}>
                          Reactivate subscription
                        </button>
                        <p className='text-center text-sm'>
                          (If you reactivate, you will not be charged until your
                          renewal date)
                        </p>
                        <br />
                      </>
                    ) : (
                      <>
                        <p>
                          Your monthly subscription of{' '}
                          {subscriptionInfo.subscriptionCharge * 10} credits for
                          this tool will renew on{' '}
                          {new Date(
                            subscriptionInfo.reviewDate
                          ).toLocaleDateString()}
                          .
                        </p>
                        <button onClick={cancelSubscription}>
                          Cancel subscription
                        </button>
                        <br />
                      </>
                    ))}
                  <label className='flex gap-2 items-center'>
                    {`Autosave responses ${autosaveMode ? '(on)' : '(off)'}`}
                    <Toggle
                      className='cursor-pointer'
                      toggled={autosaveMode}
                      onChange={handleAutosaveToggle}
                    />
                  </label>
                  {autosaveErrorMessage && (
                    <p className='text-center'>{autosaveErrorMessage}</p>
                  )}
                  <br />
                  {isPublished && (
                    <a
                      className={
                        'border border-gray-300 border-2 p-1 px-2 rounded-lg bg-white hover:bg-blue-100'
                      }
                      href='https://skillfusion.ai/tools-help'
                      target='_blank'
                      rel='noreferrer'
                    >
                      Help Using Tools
                    </a>
                  )}
                  <br /> {isAuthor || <ReviewForm />}
                  <br />
                </>
              )}

              {isPublished && (
                <button
                  className={
                    'border border-gray-300 border-2 p-1 px-2 rounded-lg bg-white hover:bg-blue-100'
                  }
                  onClick={goToToolDetailsPage}
                >
                  {isForSharedBlock
                    ? 'Check out this tool'
                    : 'Return to tool details page'}
                </button>
              )}
              <br />

              <div className='flex items-center '>
                <Avatar
                  imageUrl={`${authorCoverPhoto || nftsImgs[3]}`}
                  sizeClass='h-9 w-9'
                  radius='rounded-full'
                />
                {authorId && (
                  <span className='ml-2.5 text-neutral-500 dark:text-neutral-400 flex flex-col'>
                    <span className='text-sm'>Creator</span>
                    <span className='text-neutral-900 dark:text-neutral-200 font-medium flex items-center'>
                      <span>
                        <Link to={`/profile/${authorId}`}>
                          <p>{authorName ?? 'username'} </p>
                          <p className='underline'>Visit Profile</p>
                        </Link>
                      </span>

                      {/*
                    // could have an admin icon here:
                    <VerifyIcon iconClass="w-4 h-4" />
                    */}
                    </span>
                  </span>
                )}
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          <BlockReferenceInputSection />

          <ShareToolSection toolId={toolId} />

          <div className='w-full gap-2 bg-blue-200 dark:bg-neutral-800 p-2 flex flex-col items-center justify-between rounded-lg shadow-xl'>
            <h1>
              Publish Details{' '}
              <MyToolTips
                content='
              <p>Publish Settings:</p>
              <p>You get 100% on all tool sales</p>
              <p>Please test your tools thrououghly by clicking the "running man" icon to run it in user mode</p>
              <p>Include terms in your title and description that users may search for.
              <br/>(e.g. <q>translation</q>, <q>summarise</q>, <q>fitness plan</q>)</p>

              <p>Include relevant industries, locations and other qualifying criteria in the title if applicable.
              <br />(e.g. <q>HR Documents <u>for US Businesses</u></q>, <q>Parking Ticket Advice <u>- UK Law</u></q>...)</p>

              <p>Make your description detailed, this is the marketing copy for your tool! It should explain what it can do and entice users to want to try it.</p>

              <p>For your tool image you could grab a screenshot of the final output if you want to.</p>

              <p>If you make a video of your tool and add the Youtube link in your description, then our system will automatically embed the video. </p>

              <p>Price can be changed at any time but monthly users that have already subscribed will be locked in to the lower price if the price goes up</p>

              <p>To receive your commission please make sure you have added your paypal email to the<br/><q>Discover</q> > <q>My Creations</q> page.</p>
              <p>You are welcome to promote yourself at the end of your description or a message at the end of your tool. E.g. <q>for more advice on ... call us on ...</q></p>
              '
                tipID='block-types'
                datatooltipplace='left'
              />
            </h1>

            {/*<CreatorDetails name={creatorName} profileImageUrl={profileImageUrl} />*/}

            <PublishForm />

            <p style={{ alignSelf: 'flex-start' }}>
              Categories{' '}
              <i style={{ fontSize: '8pt', color: 'darkred' }}>
                (3 max, choose most relevant first)
              </i>{' '}
              :
            </p>

            {/* {selectedCategory && (
              <p style={{ alignSelf: 'flex-start' }}>
                Choose subcategory (optional)
              </p>
            )} */}

            <CategoryMultiselect
              value={chosenCategoryNames}
              onChange={handleCatChange}
              onSelect={handleCatSelect}
              data={displayedCategories}
              selectedCategory={selectedCategory}
              goToTopLevelCategories={goToTopLevelCategories}
            />
            {/* Tags section */}
            {user()?.loggedin === 'false' && (
              <>
                <p style={{ alignSelf: 'flex-start' }}>Tags:</p>
                <div
                  style={{ alignSelf: 'flex-start' }}
                  className='flex flex-wrap gap-2'
                >
                  {tags.map((tag: string) => (
                    <span
                      key={tag}
                      className='flex items-center gap-1'
                    >
                      <span>{tag}</span>
                      <button
                        className='border border-1 border-black rounded-md'
                        onClick={() =>
                          setTags(
                            tags.filter((otherTag: string) => tag !== otherTag)
                          )
                        }
                      >
                        <MdClose />
                      </button>
                    </span>
                  ))}
                </div>
                <div
                  className='flex gap-2'
                  style={{ alignSelf: 'flex-start' }}
                >
                  <input
                    className='p-1'
                    value={tagInputValue}
                    onChange={({ target: { value } }) =>
                      setTagInputValue(value)
                    }
                  />
                  <button onClick={handleAddTagClick}>Add tag</button>
                </div>
              </>
            )}

            <ImageGenerator />

            <IsPublishedSection />

            <IsAdminPublishedSection />
          </div>

          {/*<Clipboard blocks={blocks}/>*/}

          {/* 
            <div className="mt-4 w-full p-2 bg-blue-200 dark:bg-neutral-700 rounded-md overflow-y-scroll h-full">
                  <h2 className="text-lg font-bold mb-2">Blocks:</h2>
                  <pre className="text-xs">{JSON.stringify(blocks, null, 2)}</pre>
              </div> */}

          {/*<ActivityLogSection activityLog={activityLog}/>*/}

          {/* Rest of your code here */}

          {/* <div className={` m-4 p-4 rounded bg-green-500 text-white opacity-0 ${notification ? 'animate-fade-in-out' : ''}`}>
              <div className="flex items-center">
                <FaCheckCircle />
                <span className="ml-2">{notification}</span>
              </div>
            </div> */}
        </>
      )}
    </aside>
  );
};

export default FuserSidebar;
