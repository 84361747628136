import { ensureNotArray } from "./array";

export function keyPath(path:string|string[], object:any) {
  if (!Array.isArray(path)) {
    // path should be a single object key
    return object?.[path];
  }

  return path.reduce(
    (currentValue, nextKey) => currentValue?.[nextKey],
    object
  );
};

export function setProperty(path: string | string[], newValue: any, object: any) {
  if (path.length === 0) {
    throw new Error('Empty path given to setProperty');
  }

  if (typeof path === 'string' || path.length === 1) {
    object[ensureNotArray(path)] = newValue;
    return;
  }

  const objectToSetPropertyOn = path.slice(0,-1).reduce(
    (currentValue, nextKey) => currentValue?.[nextKey],
    object
  );

  if (objectToSetPropertyOn === undefined) {
    throw new Error('Invalid path given to setProperty');
  }

  objectToSetPropertyOn[path.at(-1)!] = newValue;
};

export function updateProperty(
  keyPath: string | string[],
  newValue: any,
  object: { [key: string]: any }
) {
  if (typeof keyPath === "string") keyPath = [keyPath];

  let objectToUpdate = object;
  const lastKeyIndex = keyPath.length - 1;

  for (let keyIndex = 0; keyIndex < lastKeyIndex; keyIndex++) {
    const nextKey = keyPath[keyIndex];
    const nextObject = objectToUpdate[nextKey];

    if (nextObject === undefined) {
      console.error(
        "updateProperty failed; the path ",
        keyPath,
        " does not exist on ",
        object
      );
      return false;
    }

    objectToUpdate = nextObject;
  }

  if (typeof objectToUpdate === "object") {
    objectToUpdate[keyPath[lastKeyIndex]] = newValue;
    return object;
  }

  console.error(
    "updateProperty failed; the path ",
    keyPath.slice(0, -1),
    " does not lead to an object on ",
    object
  );
}

// function hasProperty(
//   keyPath: string | string[],
//   object: { [key: string]: any }
// ): boolean {
//   if (typeof keyPath === "string") keyPath = [keyPath];

//   for (const key of keyPath) {
//     const nextObject = object[key];
//     if (nextObject === undefined) {
//       console.log(`Property ${key} does not exist on `, object);
//       return false;
//     }
//     object = nextObject;
//   }

//   return true;
// }